import React from 'react';
import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const Search = ({ title, placeholder, search, setSearch, onSubmit }) => {
  return (
    <Paper
      elevation={0}
      style={{ borderRadius: 0, padding: 24, marginBottom: '1rem' }}
    >
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography fontSize={20} color="primary">
            {title}
          </Typography>
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs>
            <TextField
              size="small"
              color="primary"
              value={search}
              onKeyDown={(e) => e.code === 'Enter' && onSubmit()}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={placeholder}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#006873',
                  },
                  '&:hover fieldset': {
                    borderColor: '#006873',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={onSubmit}
              fullWidth
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Search;
