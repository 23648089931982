import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { db } from '../../firebaseApp'; // Ajusta la ruta según la estructura de tu proyecto
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';

const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({ id: null, name: '' });
  const [alert, setAlert] = useState({ open: false, severity: '', message: '' });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      const querySnapshot = await getDocs(collection(db, 'categoriasDecoHome'));
      const categoriesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesData);
    };

    fetchCategories();
  }, []);

  const handleOpen = () => {
    setCurrentCategory({ id: null, name: '' });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveCategory = async () => {
    try {
      if (currentCategory.id) {
        // Update existing category
        const categoryRef = doc(db, 'categoriasDecoHome', currentCategory.id);
        await updateDoc(categoryRef, { name: currentCategory.name });
        setAlert({ open: true, severity: 'success', message: 'Categoría actualizada con éxito' });
      } else {
        // Add new category
        await addDoc(collection(db, 'categoriasDecoHome'), { name: currentCategory.name });
        setAlert({ open: true, severity: 'success', message: 'Categoría agregada con éxito' });
      }
      setOpen(false);
      window.location.reload(); // Refresh page to show updated categories
    } catch (error) {
      setAlert({ open: true, severity: 'error', message: 'Error al guardar la categoría' });
    }
  };

  const handleEditCategory = (category) => {
    setCurrentCategory(category);
    setOpen(true);
  };

  const handleDeleteCategory = (categoryId) => {
    setCategoryToDelete(categoryId);
    setConfirmDialogOpen(true);
  };

  const confirmDeleteCategory = async () => {
    try {
      const categoryRef = doc(db, 'categoriasDecoHome', categoryToDelete);
      await deleteDoc(categoryRef);
      setAlert({ open: true, severity: 'success', message: 'Categoría eliminada con éxito' });
      setConfirmDialogOpen(false);
      window.location.reload(); // Refresh page to show updated categories
    } catch (error) {
      setAlert({ open: true, severity: 'error', message: 'Error al eliminar la categoría' });
    }
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Categorías Deco Home
      </Typography>


      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nombre de la Categoría</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category) => (
              <TableRow key={category.id}>
                <TableCell>{category.id}</TableCell>
                <TableCell>{category.name}</TableCell>
                <TableCell align="right">
                  <IconButton color="primary" onClick={() => handleEditCategory(category)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDeleteCategory(category.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpen} sx={{marginTop:'1em'}} >
        Agregar Categoría
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentCategory.id ? 'Editar Categoría' : 'Agregar Categoría'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre de la Categoría"
            type="text"
            fullWidth
            variant="outlined"
            value={currentCategory.name}
            onChange={(e) => setCurrentCategory({ ...currentCategory, name: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSaveCategory} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Eliminar Categoría</DialogTitle>
        <DialogContent>
          <Typography>¿Estás seguro de que deseas eliminar esta categoría? Esta acción no se puede deshacer.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={confirmDeleteCategory} color="secondary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CategoriesPage;
