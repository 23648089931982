// @ts-ignore
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Grid, TextField, Button } from '@mui/material';
import { signInWithEmail } from 'services/auth';
import { useAuthState } from 'context/AuthContext';

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  // @ts-ignore
  const { user } = useAuthState();

  // useEffect(() => {
  //   if (user) navigate('/');
  // }, []);

  const handleLogin = async () => {
    signInWithEmail(username, password).then((res) => {
      console.log(username, password);
      console.log("res", res);
      navigate('/customCurtains');
    });
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid
          item
          container
          spacing={3}
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Grid item xs={12}>
            <TextField
              label="Username"
              value={username}
              // @ts-ignore
              onChange={e => setUsername(e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              type={'password'}
              value={password}
              // @ts-ignore
              onChange={e => setPassword(e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" fullWidth onClick={handleLogin}>
              Login
            </Button>
          </Grid>
          No estas registrado? <Link to="/register">Registrate!</Link>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
