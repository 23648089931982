export const columnsArray = [
  // {
  //   field: 'slug',
  //   headerName: 'Slug',
  //   width: 200,
  //   headerClassName: 'header',
  // },
  {
    field: 'name',
    headerName: 'Nombre',
    flex: 1,
    headerClassName: 'header',
  },
  // {
  //   field: 'price',
  //   headerName: 'Precio',
  //   width: 200,
  //   headerClassName: 'header',
  // },
];

export const initialValues = {
  name: '',
  slug: '',
  description: '',
  price: 0,
  quantity: 0,
  images: [],
  types: [
    {
      id: '1',
      description: '',
      colors: [],
    },
  ],
  rollDropTypes: [
    {
      id: Math.random().toString(),
      description: '',
      price: 0,
    },
  ],
  positions: [
    {
      id: Math.random().toString(),
      description: '',
      price: 0,
    },
  ],
  chainTypes: [
    {
      id: Math.random().toString(),
      description: '',
      price: 0,
    },
  ],
};
