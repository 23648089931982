import React from 'react';
import { Button, Grid, TextField, Typography, styled } from '@mui/material';

const Title = styled(Typography)(() => ({
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  fontSize: 50,
}));

const Subtitle = styled(Typography)(() => ({
  fontFamily: 'Inter, sans-serif',
  fontWeight: 600,
  fontSize: 20,
  textTransform: 'uppercase',
  marginRight: 8,
}));

const Description = styled(Typography)(() => ({
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  fontSize: 20,
  color: '#185D22',
}));

const Input = styled(TextField)({
  '& .MuiInputBase-root': {
    backgroundColor: '#E5E5E5',
  },
  '& label.Mui-focused': {
    color: 'black',
  },
});

const Contact = () => {
  return (
    <Grid container spacing={4} justifyContent="space-between" marginTop={10}>
      <Grid item xs container direction="column">
        <Grid item marginBottom={10}>
          <Title>Contacte</Title>
          <Title>con nosotros</Title>
        </Grid>
        <Grid item display="flex" marginBottom={2}>
          <Subtitle>Dirección:</Subtitle>
          <Description>
            Ricardo Pedroni 1779 esquina, Octavio Pinto, Córdoba.
          </Description>
        </Grid>
        <Grid item marginBottom={2}>
          <Subtitle>Horario de atención:</Subtitle>
          <Description>
            de Lunes a Viernes de 9:30 a 17:00 hs. Sábado y Dominio cerrados.
          </Description>
        </Grid>
        <Grid item display="flex" marginBottom={2}>
          <Subtitle>Teléfono:</Subtitle>
          <Description>0351 244-3260</Description>
        </Grid>
        <Grid item display="flex">
          <Subtitle>Email:</Subtitle>
          <Description>ventas@texalia.com.ar</Description>
        </Grid>
      </Grid>
      <Grid item xs container spacing={4} marginTop={2} direction="column">
        <Grid item>
          <Input
            InputProps={{ disableUnderline: true }}
            size="small"
            variant="filled"
            label="Nombre completo"
            fullWidth
          />
        </Grid>
        <Grid item>
          <Input
            InputProps={{ disableUnderline: true }}
            size="small"
            variant="filled"
            label="Empresa"
            fullWidth
          />
        </Grid>
        <Grid item>
          <Input
            InputProps={{ disableUnderline: true }}
            size="small"
            variant="filled"
            label="Teléfono"
            fullWidth
          />
        </Grid>
        <Grid item>
          <Input
            InputProps={{ disableUnderline: true }}
            size="small"
            variant="filled"
            label="Email"
            fullWidth
          />
        </Grid>
        <Grid item>
          <Input
            InputProps={{ disableUnderline: true }}
            size="small"
            variant="filled"
            label="Dejanos tu mensaje"
            multiline
            rows={5}
            fullWidth
          />
        </Grid>
        <Grid item display="flex" justifyContent="flex-end" marginTop={2}>
          <Button
            variant="contained"
            sx={{ width: 143, height: 48 }}
            color="success"
          >
            Enviar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Contact;
