import React, { useRef } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Chip,
  Divider,
  FormLabel,
  Grid,
} from '@mui/material';

import { UploadOutlined } from '@mui/icons-material';

const UploadImages = ({
  selectedImages,
  setSelectedImages,
  getValues,
  setValue,
}) => {
  const fileInputRef = useRef(null);

  const onImagesSelected = async ({ target }) => {
    if (!target.files || target.files.length === 0) return;
    const files = [];
    for (const file of target.files) {
      files.push(file);
    }
    setSelectedImages(files);
  };

  const onDeleteImage = (image) => {
    setValue(
      'images',
      getValues('images').filter((img) => img !== image),
      { shouldValidate: true },
    );
  };

  const deleteNewImage = (file) => {
    setSelectedImages(selectedImages.filter((image) => image !== file));
  };

  return (
    <Box display="flex" flexDirection="column">
      <Button
        color="primary"
        variant="outlined"
        fullWidth
        startIcon={<UploadOutlined />}
        sx={{ mb: 3, borderRadius: 5 }}
        onClick={() => fileInputRef.current?.click()}
      >
        Cargar imagen
      </Button>
      <input
        ref={fileInputRef}
        type="file"
        multiple
        accept="image/png, image/gif, image/jpeg"
        style={{ display: 'none' }}
        onChange={onImagesSelected}
      />
      <Chip
        label="Es necesario al 2 imagenes"
        color="error"
        variant="outlined"
        sx={{
          display:
            getValues('images').length + selectedImages.length < 2
              ? 'flex'
              : 'none',
          mb: 2,
        }}
      />
      {!!getValues('images').length && (
        <>
          <Grid item>
            <FormLabel sx={{ mb: 1 }}>Imágenes existentes</FormLabel>
            <Divider sx={{ mb: 1 }} />
          </Grid>
          <Grid container spacing={2}>
            {getValues('images').map((img) => (
              <Grid item xs={4} sm={3} key={img}>
                <Card>
                  <CardMedia
                    component="img"
                    className="fadeIn"
                    image={img}
                    alt={img}
                  />
                  <CardActions>
                    <Button
                      fullWidth
                      color="error"
                      onClick={() => onDeleteImage(img)}
                    >
                      Borrar
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
      {!!selectedImages.length && (
        <>
          <Grid item mt={2}>
            <FormLabel>Nuevas imágenes</FormLabel>
            <Divider sx={{ mb: 1 }} />
          </Grid>
          <Grid container spacing={2}>
            {selectedImages.map((img) => (
              <Grid item xs={4} sm={3} key={img.name} justifyContent="center">
                <Card>
                  <CardMedia
                    component={() => (
                      <img
                        style={{ width: '100%', padding: '5px 10px' }}
                        alt={img.name}
                        width={120}
                        height={120}
                        src={
                          typeof img === 'string'
                            ? img
                            : URL.createObjectURL(img)
                        }
                      />
                    )}
                    className="fadeIn"
                  />
                  <CardActions>
                    <Button
                      fullWidth
                      color="error"
                      onClick={() => deleteNewImage(img)}
                    >
                      Borrar
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default UploadImages;
