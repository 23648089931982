import React from 'react';
import { Paper, Modal, Typography, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const BasicModal = ({ open, onClose, children, title = '' }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        elevation={3}
        style={{
          width: '100%',
          height: '100%',
          padding: '20px',
          // maxHeight: '90vh',
          overflow: 'auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <Typography variant="h5" textAlign="center">
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <div>{children}</div>
      </Paper>
    </Modal>
  );
};

export default BasicModal;
