import React, { useState, useEffect } from 'react';
import { Grid, styled, Typography, Button } from '@mui/material';
//Imagenes
// import dh1 from './assets/dh1.jpg';
// import dh2 from './assets/dh2.jpg';
// import dh3 from './assets/dh3.jpg';
import logoTexalia from './assets/logoTexalia.jpg';

import puffBahamas from './assets/puffBahamas.png';
import puffAruba from './assets/puffAruba.png';
import puffJamaica from './assets/puffJamaica.png';
import colchonetaIbiza from './assets/colchonetaIbiza.png';

import { useNavigate } from 'react-router-dom';

const TextBtn = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Rubik',
  fontWeight: 500,
  textAlign: 'center',
  color: 'white',
  textTransform: 'none',
}));

// @ts-ignore
const TextTitle = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: 600,
  textAlign: 'center',
  color: 'black',
  textTransform: 'none',
}));

const Btn = styled(Button)(({ theme }) => ({
  marginTop: '25px',
  backgroundColor: '#185D22',
  borderRadius: '9px',
  color: 'white',
  fontSize: '12px',
  '&:hover': {
    backgroundColor: '#185D22',
    boxShadow: 'none',
  },
  [theme.breakpoints.down('md')]: {
    width: 100,
    height: 60,
  },
}));

const DecoHome = () => {
  const navigate = useNavigate();
  // const [viewport_width, setViewport_width] = useState([]);

  // window.addEventListener('resize', function () {
  //   setViewport_width(window.innerWidth);
  // });

  useEffect(() => {}, []);

  return (
    <Grid item container direction="column">
      <Grid>
        <TextTitle
          mt={5}
          marginLeft={'20%'}
          marginRight={'20%'}
          fontSize={'24px'}
        >
          DAMOS VIDAS A TUS IDEAS
        </TextTitle>
      </Grid>
      <Grid item container display={'flex'} justifyContent={'space-around'}>
        <Grid
          item
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <img width={'75%'} height={'100%'} src={logoTexalia} />
        </Grid>
      </Grid>
      <Grid item display={'flex'} flexDirection={'row'}>
        <Grid
          item
          container
          xs={12}
          display={'flex'}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              navigate('/decohome/productos', {
                state: {
                  filter: 'PUFF BAHAMAS',
                },
              })
            }
          >
            <TextTitle
              style={{ backgroundColor: '#D9D9D9', width: '75%' }}
              mt={5}
              marginLeft={'20%'}
              marginRight={'20%'}
            >
              PUFF BAHAMAS
            </TextTitle>
            <img width={'75%'} height={'100%'} src={puffBahamas} />
          </Grid>
          <Grid
            item
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              navigate('/decohome/productos', {
                state: {
                  filter: 'PUFF JAMAICA',
                },
              })
            }
          >
            <TextTitle
              mt={5}
              marginLeft={'20%'}
              marginRight={'20%'}
              style={{
                backgroundColor: '#D9D9D9',
                width: '75%',
              }}
            >
              PUFF JAMAICA
            </TextTitle>
            <img width={'75%'} height={'100%'} src={puffJamaica}></img>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container display={'flex'} flexDirection={'row'} mt={10}>
        <Grid
          item
          container
          xs={12}
          display={'flex'}
          justifyContent={'space-evenly'}
        >
          <Grid
            item
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              navigate('/decohome/productos', {
                state: {
                  filter: 'PUFF ARUBA',
                },
              })
            }
          >
            <TextTitle
              style={{ backgroundColor: '#D9D9D9', width: '75%' }}
              mt={5}
              marginLeft={'20%'}
              marginRight={'20%'}
            >
              PUFF ARUBA
            </TextTitle>
            <img width={'75%'} height={'100%'} src={puffAruba} />
          </Grid>
          <Grid
            item
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              navigate('/decohome/productos', {
                state: {
                  filter: 'COLCHONETA IBIZA',
                },
              })
            }
          >
            <TextTitle
              style={{
                backgroundColor: '#D9D9D9',
                width: '76%',
              }}
              mt={5}
              marginLeft={'20%'}
              marginRight={'20%'}
            >
              COLCHONETA IBIZA
            </TextTitle>
            <img width={'75%'} height={'100%'} src={colchonetaIbiza}></img>
          </Grid>
        </Grid>
      </Grid>
      <Grid item  container display={'flex'} justifyContent={'center'} mb={10}>
        <Btn onClick={() => navigate('/contact')}>
          <TextBtn>Contacto</TextBtn>
        </Btn>
      </Grid>
      {/* <Grid item marginBottom={'20px'}>
        <TextTitle
          mt={5}
          marginLeft={'20%'}
          marginRight={'20%'}
          fontSize={'24px'}
        >
          DAMOS VIDAS A TUS IDEAS
        </TextTitle>
      </Grid> */}
      {/* <Grid
        item
        container
        display={'flex'}
        justifyContent={'space-around'}
        mb={10}
      >
        <Grid
          item
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <img width={'75%'} height={'100%'} src={logoTexalia} />
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default DecoHome;
