import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  deleteDoc,
  addDoc,
} from 'firebase/firestore';
import { db } from 'firebaseApp';

const isRepeated = async (name, id) => {
  const colRef = collection(db, 'categories');
  const queryByName = query(colRef, where('name', '==', name.toLowerCase()));
  const categories = (await getDocs(queryByName)).docs;
  const repeatedElements = id
    ? categories.filter((category) => category.id !== id)
    : categories;
  return repeatedElements;
};

const getAll = async () => {
  const categories = await getDocs(collection(db, 'categories'));
  return categories.docs.map((category) => ({
    ...category.data(),
    id: category.id,
  }));
};

const getOne = async (id) => {
  const docuRef = doc(db, `categories/${id}`);
  const docu = await getDoc(docuRef);
  const category = { ...docu.data(), id: docu.id };
  return category;
};

const search = async (search) => {
  const colRef = collection(db, 'categories');
  const queryByName = query(
    colRef,
    where('name', '>=', search.toLowerCase()),
    where('name', '<=', search.toLowerCase() + '\uf8ff'),
  );

  const categoryArray = (await getDocs(queryByName)).docs;
  const categories = categoryArray.map((category) => ({
    ...category.data(),
    id: category.id,
  }));
  return categories;
};

const create = async (data) => {
  const repeatedElements = await isRepeated(data.name);
  if (repeatedElements.length)
    return { status: 400, message: 'La categoría ya existe' };
  addDoc(collection(db, 'categories'), {
    name: data.name.toLowerCase(),
    materials: data.materials,
  });
  return { status: 200, message: 'Categoría creada con éxito' };
};

const update = async (data) => {
  const repeatedElements = await isRepeated(data.name, data.id);
  if (repeatedElements.length)
    return { status: 400, message: 'La categoría ya existe' };
  const docuRef = doc(db, 'categories', data.id);
  await updateDoc(docuRef, {
    name: data.name.toLowerCase(),
    materials: data.materials,
  });
};

const remove = async (id) => await deleteDoc(doc(db, 'categories', id));

const CategoryService = {
  getAll,
  getOne,
  search,
  create,
  update,
  remove,
};
export default CategoryService;
