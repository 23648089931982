export const initialParameters = {
  name: '',
  description: '',
  images: [],
  types: [],
  rollDropTypes: [],
  positions: [],
  chainTypes: [],
};

export const initialForm = {
  quantity: 1,
  type: null,
  color: null,
  width: '',
  height: '',
  rollDrop: null,
  position: null,
  chainType: null,
  placement: '',
};
