import {
  doc,
  getDocs,
  collection,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from 'firebaseApp';

const getAll = async () => {
  const data = await getDocs(
    collection(db, 'variablesGananciaBandasVerticales'),
  );
  return data.docs.map((element) => ({ ...element.data(), id: element.id }));
};

const getCollectionProducts = async () => {
  let productsArray = [];

  try {
    const querySnapshot = await getDocs(
      collection(db, 'variablesGananciaBandasVerticales'),
    );
    querySnapshot.forEach((doc) => {
      let json = JSON.parse(JSON.stringify(doc.data()));
      json.id = doc.id;
      productsArray.push(json);
    });
  } catch (error) {
    return { status: error };
  }

  return productsArray;
};

export const updateVariable = async (bodyProduct) => {
  try {
    const docRef = doc(
      db,
      'variablesGananciaBandasVerticales/',
      bodyProduct.id,
    );
    await updateDoc(docRef, bodyProduct);
    return { status: 'Variable actualizada!' };
  } catch (error) {
    return { status: error };
  }
};

const remove = async (id) =>
  await deleteDoc(doc(db, 'variablesGananciaBandasVerticales', id));

const variablesGananciaBandasVerticalesService = {
  getAll,
  getCollectionProducts,
  updateVariable,
  remove,
};
export default variablesGananciaBandasVerticalesService;
