import React, { useEffect, useState } from 'react';
import { Grid, Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { DeleteForever, Edit } from '@mui/icons-material';
import {
  BasicModal,
  CategoryStandarProductsForm,
  DialogModal,
  Search,
  Table,
} from 'components';
import { CategoryStandarPoductsService } from 'services';
import { columnsArray, initialValues } from './constants';

const CategoriesStandarProducts = () => {
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [category, setCategory] = useState(initialValues);

  const getAllCategories = async () => {
    const categories = await CategoryStandarPoductsService.getAll();
    setData(categories);
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const handleSearch = () => {
    if (search) {
      setIsLoading(true);
      CategoryStandarPoductsService.search(search)
        .then((res) => setData(res))
        .finally(() => setIsLoading(false));
    } else {
      getAllCategories();
    }
  };

  const handleSubmit = (data, resetForm) => {
    if (data.id) {
      CategoryStandarPoductsService.update(data).then(() => {
        handleCloseModal();
        resetForm();
        handleSearch();
      });
    } else {
      CategoryStandarPoductsService.create(data).then(() => {
        handleCloseModal();
        resetForm();
        handleSearch();
      });
    }
  };

  const handleDelete = () => {
    CategoryStandarPoductsService.remove(category?.id).finally(() => {
      setCategory(initialValues);
      setShowAlertModal(false);
      getAllCategories();
    });
  };

  const handleCloseModal = () => {
    setShowFormModal(false);
    setCategory(initialValues);
  };

  const handleCloseAlertModal = () => {
    setCategory(initialValues);
    setShowAlertModal(false);
  };

  const columns = [
    ...columnsArray,
    {
      field: 'actions',
      headerName: 'Acciones',
      headerClassName: 'header',
      sortable: false,
      minWidth: 80,
      align: 'center',
      renderCell: (params) => (
        <>
          <GridActionsCellItem
            label="editar"
            onClick={() => {
              setCategory(params.row);
              setShowFormModal(true);
            }}
            icon={
              <Tooltip title="Editar categoría">
                <Edit color="primary" />
              </Tooltip>
            }
          />
          <GridActionsCellItem
            label="eliminar"
            onClick={() => {
              setCategory(params.row);
              setShowAlertModal(true);
            }}
            icon={
              <Tooltip title="Eliminar categoría">
                <DeleteForever color="primary" />
              </Tooltip>
            }
          />
        </>
      ),
    },
  ];

  return (
    <Grid container direction="column">
      <Grid item>
        {/* <Search
          title="Buscar categoría estandar"
          placeholder="Buscar por nombre"
          search={search}
          setSearch={setSearch}
          onSubmit={handleSearch}
        /> */}
        <Table
          title="Categorías Estandar"
          values={data}
          columns={columns}
          isLoading={isLoading}
          buttonTitle="Crear nueva categoría"
          buttonAction={() => setShowFormModal(true)}
        />
      </Grid>
      <BasicModal open={showFormModal} onClose={handleCloseModal}>
        <CategoryStandarProductsForm
          onSubmit={handleSubmit}
          defaultValues={category}
        />
      </BasicModal>
      <DialogModal
        open={showAlertModal}
        onClose={handleCloseAlertModal}
        onSubmit={handleDelete}
        title="Eliminar categoría"
        description="Esta acción es irreversible, ¿desea continuar?"
        colorButton="error"
      />
    </Grid>
  );
};

export default CategoriesStandarProducts;
