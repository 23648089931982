import { createUserWithEmailAndPassword } from 'firebase/auth';
import {
  doc,
  getDoc,
  setDoc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { auth, db } from 'firebaseApp';

export const getUser = async (uid) => {
  const docuRef = doc(db, `users/${uid}`);
  const docu = await getDoc(docuRef);
  const userData = docu.data();
  return userData;
};

export const getUsers = async () => {
  const res = (await getDocs(collection(db, 'users'))).docs;
  const users = res.map((user) => ({ ...user.data(), id: user.id }));
  return users;
};

export const searchUsers = async (search) => {
  const colRef = collection(db, 'users');
  const queryByName = await query(
    colRef,
    where('name', '>=', search),
    where('name', '<=', search + '\uf8ff'),
  );
  const queryByLastName = await query(
    colRef,
    where('lastname', '>=', search),
    where('lastname', '<=', search + '\uf8ff'),
  );
  const queryByRol = await query(
    colRef,
    where('rol', '>=', search),
    where('rol', '<=', search + '\uf8ff'),
  );

  const nameArray = (await getDocs(queryByName)).docs;
  const lastnameArray = (await getDocs(queryByLastName)).docs;
  const rolArray = (await getDocs(queryByRol)).docs;
  const users = [...nameArray, ...lastnameArray, ...rolArray].map((user) => ({
    ...user.data(),
    id: user.id,
  }));
  return users;
};

export const registerUser = async (data, password) => {
  const { user } = await createUserWithEmailAndPassword(
    auth,
    data.email,
    password || '123456',
  );
  const docuRef = doc(db, `users/${user.uid}`);
  setDoc(docuRef, {
    name: data.name,
    lastname: data.lastname,
    address: data.address,
    dni: data.dni,
    email: data.email,
    rol: data.rol,
  });
};

export const updateUser = async (data) => {
  const docuRef = doc(db, 'users', data.id);
  await updateDoc(docuRef, {
    name: data.name,
    lastname: data.lastname,
    address: data.address,
    dni: data.dni,
    email: data.email,
    rol: data.rol || 'user',
  });
};

export const deleteUser = async (id) => await deleteDoc(doc(db, 'users', id));
