// @ts-nocheck
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db, storage } from 'firebaseApp';
import { uploadImageProduct } from './images';
import ColorService from './colors';
import { deleteObject, ref } from 'firebase/storage';

const getOne = async (id) => {
  const docuRef = doc(db, `CustomProducts/${id}`);
  const docu = await getDoc(docuRef);
  const curtain = { ...docu.data(), id: docu.id };
  return curtain;
};

const getBySlug = async (slug) => {
  const colRef = collection(db, 'CustomProducts');
  const queryBySlug = query(colRef, where('slug', '==', slug));
  const customProductsArray = (await getDocs(queryBySlug)).docs;
  const customProducts = customProductsArray.map((product) => ({
    ...product.data(),
    id: product.id,
  }));
  return customProducts[0];
};

const getAll = async () => {
  const curtains = await getDocs(collection(db, 'CustomProducts'));
  return curtains.docs.map((curtain) => ({
    ...curtain.data(),
    id: curtain.id,
  }));
};

const search = async (search) => {
  const colRef = collection(db, 'CustomProducts');
  const queryByName = query(
    colRef,
    where('name', '>=', search.toLowerCase()),
    where('name', '<=', search.toLowerCase() + '\uf8ff'),
  );

  const customProductsArray = (await getDocs(queryByName)).docs;
  const customProducts = customProductsArray.map((product) => ({
    ...product.data(),
    id: product.id,
  }));
  return customProducts;
};

const create = async (data, images) => {
  const imagesUrl = await Promise.all(
    images.map(async (image) => {
      try {
        const { imageUrl } = await uploadImageProduct(
          image,
          `${data.slug}/${image.name}`,
        );
        return imageUrl;
      } catch (error) {
        return '';
      }
    }),
  );

  const types = await Promise.all(
    data.types.map(async (type) => {
      const colors = await Promise.all(
        type.colors.map(async (color) => {
          const colorUrl = await ColorService.uploadImage(
            color.file,
            data.slug,
          );
          return {
            name: color.name,
            url: colorUrl,
          };
        }),
      );
      return {
        description: type.description,
        colors,
      };
    }),
  );

  const bodyProduct = {
    ...data,
    images: imagesUrl,
    types,
  };

  try {
    const docRef = await addDoc(collection(db, 'CustomProducts'), bodyProduct);
    const docRefForGet = doc(db, 'CustomProducts', docRef.id);
    const docSnap = await getDoc(docRefForGet);
    let product = {};
    if (docSnap.exists()) {
      product = docSnap.data();
      product.id = docRef.id;
    } else {
      return { status: 'Producto no creado!' };
    }
    return { status: 'Producto creado!', product };
  } catch (error) {
    return { status: error };
  }
};

const update = async (data, images) => {
  const product = await getOne(data.id);

  const imagesUrl = await Promise.all(
    images.map(async (image) => {
      try {
        const { imageUrl } = await uploadImageProduct(
          image,
          `${data.slug}/${image.name}`,
        );
        return imageUrl;
      } catch (error) {
        return '';
      }
    }),
  );

  product.images.forEach(async (image) => {
    if (!data.images.includes(image)) {
      const nameArray = image.split('%2F');
      const filename = nameArray[nameArray.length - 1].split('?')[0];
      const desertRef = ref(
        storage,
        `imagesProducts/${product.slug}/${filename}`,
      );
      await deleteObject(desertRef);
    }
  });

  const types = await Promise.all(
    data.types.map(async (type) => {
      const colors = await Promise.all(
        type.colors.map(async (color) => {
          // console.log('colortypes:', color);
          if (color.url) return color;
          const colorUrl = await ColorService.uploadImage(
            color.file,
            data.slug,
          );
          return {
            name: color.name,
            url: colorUrl,
            price: color.price,
            // dateUpdate: color.dateUpdate,
          };
        }),
      );
      return {
        description: type.description,
        colors,
      };
    }),
  );

  product.types.forEach((productType) =>
    productType.colors.forEach(({ url }) => {
      data.types.forEach(async (dataType) => {
        if (productType.description !== dataType.description) return;

        const dataColors = dataType.colors.map((color) => color.url);
        if (!dataColors.includes(url)) {
          const nameArray = url.split('%2F');
          const filename = nameArray[nameArray.length - 1].split('?')[0];
          const desertRef = ref(storage, `colors/${product.slug}/${filename}`);
          await deleteObject(desertRef);
        }
      });
    }),
  );

  const bodyProduct = {
    ...data,
    images: [...data.images, ...imagesUrl],
    types,
  };

  const docuRef = doc(db, 'CustomProducts', data.id);
  delete bodyProduct.id;
  await updateDoc(docuRef, bodyProduct);
};

const remove = async (id) => await deleteDoc(doc(db, 'CustomProducts', id));

const CustomCurtainsService = {
  getOne,
  getBySlug,
  getAll,
  search,
  create,
  update,
  remove,
};

export default CustomCurtainsService;
