import React, { useEffect, useState } from 'react';
import { Grid, Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { DeleteForever, Edit } from '@mui/icons-material';
import {
  BasicModal,
  CategoryCatalogueForm,
  DialogModal,
  Search,
  Table,
} from 'components';
import { CategoryCatalogueService } from 'services';
import {
  columnsArray,
  initialValues,
  columnsArrayInterior,
  initialValuesInterior,
  columnsArrayExterior,
  initialValuesExterior,
} from './constants';

const CategoriesCataloguex = () => {
  const [search, setSearch] = useState('');
  const [searchInt, setSearchInt] = useState('');
  const [searchExt, setSearchExt] = useState('');

  const [data, setData] = useState([]);
  const [dataInt, setDataInt] = useState([]);
  const [dataExt, setDataExt] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [showFormModal, setShowFormModal] = useState(false);
  const [showFormModalInt, setShowFormModalInt] = useState(false);
  const [showFormModalExt, setShowFormModalExt] = useState(false);

  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showAlertModalInt, setShowAlertModalInt] = useState(false);
  const [showAlertModalExt, setShowAlertModalExt] = useState(false);

  const [category, setCategory] = useState(initialValues);
  const [categoryInt, setCategoryInt] = useState(initialValuesInterior);
  const [categoryExt, setCategoryExt] = useState(initialValuesExterior);

  const getAllCategories = async () => {
    const categories = await CategoryCatalogueService.getAll();
    setData(categories);
  };

  const getAllCategoriesInt = async () => {
    const categories = await CategoryCatalogueService.getAllInt();
    console.log('categories INT:', categories);
    setDataInt(categories);
  };
  const getAllCategoriesExt = async () => {
    const categories = await CategoryCatalogueService.getAllExt();
    console.log('categories EXT:', categories);
    setDataExt(categories);
  };

  useEffect(() => {
    getAllCategories();
    getAllCategoriesInt();
    getAllCategoriesExt();
  }, []);

  const handleSearch = () => {
    if (search) {
      setIsLoading(true);
      CategoryCatalogueService.search(search)
        .then((res) => setData(res))
        .finally(() => setIsLoading(false));
    } else {
      getAllCategories();
    }
  };

  const handleSearchInt = () => {
    if (searchInt) {
      setIsLoading(true);
      CategoryCatalogueService.searchInt(search)
        .then((res) => setDataInt(res))
        .finally(() => setIsLoading(false));
    } else {
      getAllCategoriesInt();
    }
  };

  const handleSearchExt = () => {
    if (search) {
      setIsLoading(true);
      CategoryCatalogueService.searchExt(search)
        .then((res) => setDataExt(res))
        .finally(() => setIsLoading(false));
    } else {
      getAllCategoriesExt();
    }
  };

  const handleSubmit = (data, resetForm) => {
    console.log('handleSubmit: ', data);
    // return;
    if (data.id) {
      if (showFormModalInt) {
        CategoryCatalogueService.updateInt(data).then(() => {
          handleCloseModal();
          resetForm();
          handleSearchInt();
        });
      }
      if (showFormModalExt) {
        CategoryCatalogueService.updateExt(data).then(() => {
          handleCloseModal();
          resetForm();
          handleSearchExt();
        });
      }
    } else {
      if (showFormModalInt) {
        console.log('create interior');
        CategoryCatalogueService.createInt(data).then(() => {
          handleCloseModal();
          resetForm();
          handleSearchInt();
        });
      }
      if (showFormModalExt) {
        console.log('create extterior');
        CategoryCatalogueService.createExt(data).then(() => {
          handleCloseModal();
          resetForm();
          handleSearchExt();
        });
      }
    }
  };

  const handleDelete = () => {
    CategoryCatalogueService.remove(category?.id).finally(() => {
      setCategory(initialValues);
      setShowAlertModal(false);
      getAllCategories();
    });
  };

  const handleDeleteInt = () => {
    console.log('categoryInt:', categoryInt);
    CategoryCatalogueService.removeInt(categoryInt?.id).finally(() => {
      setCategoryInt(initialValuesInterior);
      setShowAlertModalInt(false);
      getAllCategoriesInt();
    });
  };

  const handleDeleteExt = () => {
    console.log('categoryExt:', categoryExt);
    CategoryCatalogueService.removeExt(categoryExt?.id).finally(() => {
      setCategoryExt(initialValuesExterior);
      setShowAlertModalExt(false);
      getAllCategoriesExt();
    });
  };

  const handleCloseModal = () => {
    setShowFormModal(false);
    setShowFormModalInt(false);
    setShowFormModalExt(false);

    setCategory(initialValues);
    setCategoryInt(initialValuesInterior);
    setCategoryExt(initialValuesExterior);
  };

  const handleCloseAlertModal = () => {
    setCategory(initialValues);
    setShowAlertModal(false);
    setShowAlertModalInt(false);
    setShowAlertModalExt(false);
    // setShowAlertModalInt(false);
  };

  const columnsInterior = [
    ...columnsArrayInterior,
    {
      field: 'actions',
      headerName: 'Acciones',
      headerClassName: 'header',
      sortable: false,
      minWidth: 80,
      align: 'center',
      renderCell: (params) => (
        <>
          <GridActionsCellItem
            label="editar"
            onClick={() => {
              console.log('params.row:', params.row);
              setCategoryInt(params.row);
              setShowFormModalInt(true);
            }}
            icon={
              <Tooltip title="Editar categoría">
                <Edit color="primary" />
              </Tooltip>
            }
          />
          <GridActionsCellItem
            label="eliminar"
            onClick={() => {
              console.log('params.row delete:', params.row);
              setCategoryInt(params.row);
              setShowAlertModalInt(true);
            }}
            icon={
              <Tooltip title="Eliminar categoría">
                <DeleteForever color="primary" />
              </Tooltip>
            }
          />
        </>
      ),
    },
  ];

  const columnsExterior = [
    ...columnsArrayExterior,
    {
      field: 'actions',
      headerName: 'Acciones',
      headerClassName: 'header',
      sortable: false,
      minWidth: 80,
      align: 'center',
      renderCell: (params) => (
        <>
          <GridActionsCellItem
            label="editar"
            onClick={() => {
              setCategoryExt(params.row);
              setShowFormModalExt(true);
            }}
            icon={
              <Tooltip title="Editar categoría">
                <Edit color="primary" />
              </Tooltip>
            }
          />
          <GridActionsCellItem
            label="eliminar"
            onClick={() => {
              setCategoryExt(params.row);
              setShowAlertModal(true);
            }}
            icon={
              <Tooltip title="Eliminar categoría">
                <DeleteForever color="primary" />
              </Tooltip>
            }
          />
        </>
      ),
    },
  ];

  return (
    <Grid container direction="column">
      {/* ///////////////////////////INTERIOR/////////////////////////////*/}
      <Grid item>
        {/* <Search
          title="Buscar categoría  de Interior"
          placeholder="Buscar por nombre"
          search={searchInt}
          setSearch={setSearchInt}
          onSubmit={handleSearchInt}
        /> */}
        <Table
          title="Categorias catalogo Interior"
          values={dataInt}
          columns={columnsInterior}
          isLoading={isLoading}
          buttonTitle="Nueva categoría de Interior"
          buttonAction={() => setShowFormModalInt(true)}
        />
      </Grid>
      <BasicModal open={showFormModalInt} onClose={handleCloseModal}>
        <CategoryCatalogueForm
          interior={true}
          exterior={false}
          onSubmit={handleSubmit}
          defaultValues={categoryInt}
        />
      </BasicModal>
      <DialogModal
        open={showAlertModalInt}
        onClose={handleCloseAlertModal}
        onSubmit={handleDeleteInt}
        title="Eliminar categoría de interior"
        description="Esta acción es irreversible, ¿desea continuar?"
        colorButton="error"
      />
      {/* ///////////////////////////EXTERIOR/////////////////////////////*/}
      <Grid item>
        {/* <Search
          title="Buscar categoría  de Exterior"
          placeholder="Buscar por nombre"
          search={searchExt}
          setSearch={setSearchExt}
          onSubmit={handleSearchExt}
        /> */}
        <Table
          title="Categorias catalogo Exterior"
          values={dataExt}
          columns={columnsExterior}
          isLoading={isLoading}
          buttonTitle="Nueva categoría de Exterior"
          buttonAction={() => setShowFormModalExt(true)}
        />
      </Grid>
      <BasicModal open={showFormModalExt} onClose={handleCloseModal}>
        <CategoryCatalogueForm
          interior={false}
          exterior={true}
          onSubmit={handleSubmit}
          defaultValues={categoryExt}
        />
      </BasicModal>
      <DialogModal
        open={showAlertModalExt}
        onClose={handleCloseAlertModal}
        onSubmit={handleDeleteExt}
        title="Eliminar categoría de exterior"
        description="Esta acción es irreversible, ¿desea continuar?"
        colorButton="error"
      />
    </Grid>
  );
};

export default CategoriesCataloguex;
