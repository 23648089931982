import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  deleteDoc,
  addDoc,
} from 'firebase/firestore';
import { db } from 'firebaseApp';

const isRepeated = async (name, id) => {
  const colRef = collection(db, 'categoriesCatalogue');
  const queryByName = query(colRef, where('name', '==', name));
  const categories = (await getDocs(queryByName)).docs;
  const repeatedElements = id
    ? categories.filter((category) => category.id !== id)
    : categories;
  return repeatedElements;
};

const getAll = async () => {
  const categories = await getDocs(collection(db, 'categoriesCatalogue'));
  return categories.docs.map((category) => ({
    ...category.data(),
    id: category.id,
  }));
};

const getOne = async (id) => {
  const docuRef = doc(db, `categoriesCatalogue/${id}`);
  const docu = await getDoc(docuRef);
  const category = { ...docu.data(), id: docu.id };
  return category;
};

const search = async (search) => {
  const colRef = collection(db, 'categoriesCatalogue');
  const queryByName = query(
    colRef,
    where('name', '>=', search),
    where('name', '<=', search + '\uf8ff'),
  );

  const categoryArray = (await getDocs(queryByName)).docs;
  const categories = categoryArray.map((category) => ({
    ...category.data(),
    id: category.id,
  }));
  return categories;
};

const create = async (data) => {
  const repeatedElements = await isRepeated(data.name);
  if (repeatedElements.length)
    return { status: 400, message: 'La categoría ya existe' };
  addDoc(collection(db, 'categoriesCatalogue'), {
    name: data.name,
  });
  return { status: 200, message: 'Categoría creada con éxito' };
};

const update = async (data) => {
  const repeatedElements = await isRepeated(data.name, data.id);
  if (repeatedElements.length)
    return { status: 400, message: 'La categoría ya existe' };
  const docuRef = doc(db, 'categoriesCatalogue', data.id);
  await updateDoc(docuRef, {
    name: data.name,
  });
};

const remove = async (id) =>
  await deleteDoc(doc(db, 'categoriesCatalogue', id));

//////////////////////////////////////////////////////////////////////////////////

const isRepeatedInt = async (name, id) => {
  const colRef = collection(db, 'categoriesCatalogueInt');
  const queryByName = query(colRef, where('name', '==', name));
  const categories = (await getDocs(queryByName)).docs;
  const repeatedElements = id
    ? categories.filter((category) => category.id !== id)
    : categories;
  return repeatedElements;
};

const getAllInt = async () => {
  const categories = await getDocs(collection(db, 'categoriesCatalogueInt'));
  return categories.docs.map((category) => ({
    ...category.data(),
    id: category.id,
  }));
};

const getOneInt = async (id) => {
  const docuRef = doc(db, `categoriesCatalogueInt/${id}`);
  const docu = await getDoc(docuRef);
  const category = { ...docu.data(), id: docu.id };
  return category;
};

const searchInt = async (search) => {
  const colRef = collection(db, 'categoriesCatalogueInt');
  const queryByName = query(
    colRef,
    where('name', '>=', search),
    where('name', '<=', search + '\uf8ff'),
  );

  const categoryArray = (await getDocs(queryByName)).docs;
  const categories = categoryArray.map((category) => ({
    ...category.data(),
    id: category.id,
  }));
  return categories;
};

const createInt = async (data) => {
  const repeatedElements = await isRepeatedInt(data.name);
  if (repeatedElements.length)
    return { status: 400, message: 'La categoría ya existe' };
  addDoc(collection(db, 'categoriesCatalogueInt'), {
    name: data.name,
  });
  return { status: 200, message: 'Categoría creada con éxito' };
};

const updateInt = async (data) => {
  const repeatedElements = await isRepeatedInt(data.name, data.id);
  if (repeatedElements.length)
    return { status: 400, message: 'La categoría ya existe' };
  const docuRef = doc(db, 'categoriesCatalogueInt', data.id);
  await updateDoc(docuRef, {
    name: data.name,
  });
};

const removeInt = async (id) =>
  await deleteDoc(doc(db, 'categoriesCatalogueInt', id));

//////////////////////////////////////////////////////////////////////////////////

const isRepeatedExt = async (name, id) => {
  const colRef = collection(db, 'categoriesCatalogueExt');
  const queryByName = query(colRef, where('name', '==', name));
  const categories = (await getDocs(queryByName)).docs;
  const repeatedElements = id
    ? categories.filter((category) => category.id !== id)
    : categories;
  return repeatedElements;
};

const getAllExt = async () => {
  const categories = await getDocs(collection(db, 'categoriesCatalogueExt'));
  return categories.docs.map((category) => ({
    ...category.data(),
    id: category.id,
  }));
};

const getOneExt = async (id) => {
  const docuRef = doc(db, `categoriesCatalogueExt/${id}`);
  const docu = await getDoc(docuRef);
  const category = { ...docu.data(), id: docu.id };
  return category;
};

const searchExt = async (search) => {
  const colRef = collection(db, 'categoriesCatalogueExt');
  const queryByName = query(
    colRef,
    where('name', '>=', search),
    where('name', '<=', search + '\uf8ff'),
  );

  const categoryArray = (await getDocs(queryByName)).docs;
  const categories = categoryArray.map((category) => ({
    ...category.data(),
    id: category.id,
  }));
  return categories;
};

const createExt = async (data) => {
  const repeatedElements = await isRepeatedExt(data.name);
  if (repeatedElements.length)
    return { status: 400, message: 'La categoría ya existe' };
  addDoc(collection(db, 'categoriesCatalogueExt'), {
    name: data.name,
  });
  return { status: 200, message: 'Categoría creada con éxito' };
};

const updateExt = async (data) => {
  const repeatedElements = await isRepeatedExt(data.name, data.id);
  if (repeatedElements.length)
    return { status: 400, message: 'La categoría ya existe' };
  const docuRef = doc(db, 'categoriesCatalogueExt', data.id);
  await updateDoc(docuRef, {
    name: data.name,
  });
};

const removeExt = async (id) => {
  console.log('id:', id);
  await deleteDoc(doc(db, 'categoriesCatalogueExt', id));
};

const CategoryCatalogueService = {
  getAll,
  getOne,
  search,
  create,
  update,
  remove,
  //
  getAllExt,
  getOneExt,
  searchExt,
  createExt,
  updateExt,
  removeExt,
  //
  getAllInt,
  getOneInt,
  searchInt,
  createInt,
  updateInt,
  removeInt,
};
export default CategoryCatalogueService;
