import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  styled,
  Typography,
  Button,
  ListItemButton,
} from '@mui/material';
import {
  uploadImage,
  getCarouselImages,
  deleteCarouselImage,
} from '../../../services/images';

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import 'swiper/css/autoplay';

// import Swiper from 'react-id-swiper';

//Diaglog
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

//LoadingCircularProgress
import LoadingCircularProgress from '../../../components/LoadingCircularProgress/index';

const Text = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Istok Web',
  fontWeight: props.weight || 700,
  textAlign: props.align || 'center',
  color: props.color || 'black',
}));

const Carousel = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [imagesList, setImagesList] = useState([]);
  const [viewport_width, setViewport_width] = useState([]);
  // dialog upload
  const [openDialogUploadImage, setOpenDialogUploadImage] = useState(false);
  const handleClickOpenDialogUploadImage = () => {
    setOpenDialogUploadImage(true);
  };
  const handleCloseDialogUploadImage = (value) => {
    setFileName(null);
    setFile(null);
    setOpenDialogUploadImage(false);
  };
  // dialog delete
  const [openDialogDeleteImages, setOpenDialogDeleteImages] = useState(false);
  const handleClickOpenDialogDeleteImages = () => {
    setOpenDialogDeleteImages(true);
  };
  const handleCloseDialogDeleteImages = (value) => {
    setOpenDialogDeleteImages(false);
  };

  useEffect(() => {
    getFirebaseImages();
  }, []);

  window.addEventListener('resize', function () {
    setViewport_width(window.innerWidth);
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await uploadImage(file, file.name);

      setFileName(null);
      setFile(null);
      getFirebaseImages();
    } catch (error) {
      setFileName(null);
      setFile(null);
      setIsLoading(false);
      // alert('Fallo al subir el archivo');
    }
  };

  function handleChange(event) {
    setFileName(event.target.files[0].name);
    setFile(event.target.files[0]);
  }

  const getFirebaseImages = async (e) => {
    try {
      const resultList = await getCarouselImages();

      setViewport_width(window.innerWidth);
      setImagesList(resultList);
      setIsLoading(false);
    } catch (error) {
      // alert('Fallo al subir el archivo');
    }
  };

  const handleDeleteCarouselImage = async (fileName) => {
    setIsLoading(true);
    try {
      const result = await deleteCarouselImage(fileName);

      setImagesList(result);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // alert('Fallo al subir el archivo');
    }
  };

  function createSlide(imgUrl) {
    return (
      <SwiperSlide>
        <img
          className="img"
          width={'100%'}
          height={'500px'}
          src={imgUrl}
          alt=""
        />
      </SwiperSlide>
    );
  }

  const DialogDeleteImages = () => {
    return (
      <Dialog open={openDialogDeleteImages}>
        <DialogTitle style={{ backgroundColor: '#185d22', color: 'white' }}>
          {'Eliminar Imagenes'}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialogDeleteImages}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <List
          sx={{ width: '100%', maxWidth: 560, bgcolor: 'background.paper' }}
        >
          {imagesList.length > 0 ? (
            imagesList.map((img, index) => (
              <ListItem alignItems="center" key={index}>
                <ListItemButton>
                  <DeleteIcon
                    onClick={() => handleDeleteCarouselImage(img.image)}
                  />
                </ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    src={img.url}
                    alt={img.image}
                    sx={{ width: 156, height: 56 }}
                    variant="square"
                    style={{ marginRight: '10px', marginLeft: '10px' }}
                  />
                </ListItemAvatar>
                <ListItemText primary={img.image} />
              </ListItem>
            ))
          ) : (
            <ListItem alignItems="center">
              <ListItemText primary={'No hay imagenes que eliminar'} />
            </ListItem>
          )}
        </List>
      </Dialog>
    );
  };

  const DialogUploadImages = () => {
    return (
      <Dialog open={openDialogUploadImage}>
        <DialogTitle style={{ backgroundColor: '#185d22', color: 'white' }}>
          {'Subir Imagenes'}

          <IconButton
            aria-label="close"
            onClick={handleCloseDialogUploadImage}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form>
          <Grid
            container
            direction={'column'}
            alignItems={'center'}
            style={{ padding: '30px' }}
            spacing={2}
          >
            <Grid item>
              {fileName ? (
                <Typography>{fileName}</Typography>
              ) : (
                <>
                  <input
                    name=""
                    onChange={handleChange}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{ backgroundColor: '#185d22' }}
                    >
                      Seleccionar imagen
                    </Button>
                  </label>
                </>
              )}
            </Grid>
            {fileName ? (
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#185d22' }}
                  onClick={handleSubmit}
                >
                  Subir Imagen
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </form>
      </Dialog>
    );
  };

  return (
    <>
      <LoadingCircularProgress open={isLoading} />
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Text
            sx={{
              fontSize: {
                md: 25,
                xs: 12,
              },
            }}
          >
            Carusel de Imagenes - Vista previa
          </Text>
        </Grid>

        <Grid item container>
          {imagesList.length > 0 ? (
            <Swiper
              style={{ width: viewport_width }}
              modules={[Autoplay]}
              slidesPerView={1}
              autoplay={{ delay: 3000 }}
              pagination={{ clickable: false }}
            >
              {imagesList.map((item, index) => {
                return createSlide(item.url);
              })}
            </Swiper>
          ) : null}
        </Grid>

        <Grid
          container
          item
          direction={'row'}
          spacing={2}
          justifyContent={'center'}
        >
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: '#185d22' }}
              onClick={handleClickOpenDialogUploadImage}
            >
              Subir Imagenes
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: '#185d22' }}
              onClick={handleClickOpenDialogDeleteImages}
            >
              Eliminar Imagenes
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DialogDeleteImages />
      <DialogUploadImages />
    </>
  );
};

export default Carousel;
