import React, { useState, useEffect } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import MultipleValues from './MultipleValues';
import UploadImages from './UploadImages';
import { enqueueSnackbar } from 'notistack';
import Types from './Types';

import { CustomCurtainsService } from 'services';
import { variablesCotizadorRoller } from 'services';
import { variablesGanancia } from 'services';
import { variablesCotizadorBandasVerticales } from 'services';
import { variablesGananciaBandasVerticales } from 'services';

import LoadingCircularProgress from 'components/LoadingCircularProgress';
//TABLE
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import MenuItem from '@mui/material/MenuItem';

import Alert from '@mui/material/Alert';
// import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
//TABLE

const CurtainForm = ({ curtain, onClose }) => {
  // console.log('curtain:', curtain);
  const [curtainState, setCurtainState] = useState(curtain);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [textSuccessAlert, setTextSuccessAlert] = useState('');
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [textErrorAlert, setTextErrorAlert] = useState('');

  const [arrayTelas, setArrayTelas] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const [itemToEditVG, setItemToEditVG] = useState({}); // VG => VARIABLES GANANCIA
  const [newValueVG, setNewValueVG] = useState(0);
  const [showModalEditVG, setShowModalEditVG] = useState(false);

  const [itemToEditVC, setItemToEditVC] = useState({}); // VC => VARIABLES COTIZADOR
  const [newPricePesos, setNewPricePesos] = useState(0);
  const [newPriceDolar, setNewPriceDolar] = useState(0);

  const [reload, setReload] = useState(true);

  const [variablesCotizador, setVariablesCotizador] = useState([]);
  const [variablesGanancias, setVariablesGanancias] = useState([]);

  const [variablesBandasVerticales, setVariablesBandasVerticales] = useState(
    [],
  );
  const [
    variablesGananciasBandasVerticales,
    setVariablesGananciasBandasVerticales,
  ] = useState([]);

  const [telaSelected, setTelaSelected] = useState(null);
  const [ancho, setAncho] = useState(0);
  const [alto, setAlto] = useState(0);

  const handleChangeAncho = (e) => {
    setAncho(e.target.value);
  };

  const handleChangeAlto = (e) => {
    setAlto(e.target.value);
  };

  const handleChangeTela = (e) => {
    setTelaSelected(e.target.value);
  };

  function calcularMT2() {
    let altosum = Number(alto) + 0.3;
    let result = ancho * altosum;
    return Number(result.toFixed(2));
  }

  useEffect(() => {
    if (curtain.name == 'Bandas Verticales') {
      getDataVBV(); // VBV => VARIABLES Bandas VERTICALES
      getDataVGBV(); // VGBV => VARIABLES GANANCIA Bandas VERTICALES
    }
    if (curtain.name == 'Sunscreen' || curtain.name == 'Blackout') {
      getDataVC(); // VC => VARIABLES COTIZADOR
      getDataVG(); // VC => VARIABLES GANANCIA
    }
  }, []);

  const getDataVC = async () => {
    let data = await variablesCotizadorRoller.getAll();
    data.sort(function (a, b) {
      return a.orden - b.orden;
    });
    setVariablesCotizador(data);
  };
  const getDataVG = async () => {
    let data = await variablesGanancia.getAll();
    setVariablesGanancias(data);
  };

  const getDataVBV = async () => {
    let data = await variablesCotizadorBandasVerticales.getAll();
    data.sort(function (a, b) {
      return a.orden - b.orden;
    });
    console.log('data:', data);
    setVariablesBandasVerticales(data);
  };

  const getDataVGBV = async () => {
    let data = await variablesGananciaBandasVerticales.getAll();
    data.sort(function (a, b) {
      return a.orden - b.orden;
    });
    console.log('getDataVGBV:', data);
    setVariablesGananciasBandasVerticales(data);
  };

  const handleClickOpenEditVariablesCotizador = (item) => {
    setItemToEditVC(item);
    setNewPriceDolar(item.dolar ? item.dolar : 0);
    setNewPricePesos(item.pesos ? item.pesos : 0);
    setShowModalEdit(true);
  };

  const handleCloseVC = () => {
    setItemToEditVC({});
    setNewPriceDolar(0);
    setNewPricePesos(0);
    setShowModalEdit(false);
  };

  const handleChangeNewPricePesos = (e) => {
    // console.log('e:', e.target.value);
    // if (!e.target.value) {
    //   console.log('if');
    // }
    setNewPricePesos(e.target.value);
  };

  const handleChangeNewPriceDolar = (e) => {
    setNewPriceDolar(e.target.value);
  };

  const updatePesosDolarPrices = async () => {
    let jsonEdit = { ...itemToEditVC };
    jsonEdit.dolar = newPriceDolar;
    jsonEdit.pesos = newPricePesos;
    setIsLoading(true);
    try {
      let result = undefined;
      if (curtain.name == 'Bandas Verticales') {
        // @ts-ignore
        result = await await variablesCotizadorBandasVerticales.updateVariable(
          jsonEdit,
        );
        getDataVBV();
      }
      if (curtain.name == 'Sunscreen' || curtain.name == 'Blackout') {
        result = await variablesCotizadorRoller.updateVariable(jsonEdit);

        getDataVC();
      }

      // console.log('resut:', result);
      handleCloseVC();
      setTextSuccessAlert(result.status);
      setIsLoading(false);
      setOpenSuccessAlert(true);
    } catch (error) {
      setTextErrorAlert(error.status);
      setOpenErrorAlert(true);
      setIsLoading(false);
      // alert('Fallo al subir el archivo');
    }
  };

  const handleClickOpenEditVariablesGanancia = (item) => {
    setItemToEditVG(item);
    setNewValueVG(item.valor ? item.valor : 0);
    setShowModalEditVG(true);
  };

  const handleCloseVG = () => {
    setItemToEditVG({});
    setNewValueVG(0);
    setShowModalEditVG(false);
  };

  const handleChangeNewValueVG = (e) => {
    setNewValueVG(e.target.value);
  };

  const updateValueVG = async () => {
    let jsonEdit = { ...itemToEditVG };
    jsonEdit.valor = newValueVG;
    // console.log('jsonEdit2', jsonEdit);
    setIsLoading(true);
    try {
      let result = undefined;
      if (curtain.name == 'Bandas Verticales') {
        // @ts-ignore
        result = await variablesGananciaBandasVerticales.updateVariable(
          jsonEdit,
        );
        getDataVGBV();
      }
      if (curtain.name == 'Sunscreen' || curtain.name == 'Blackout') {
        result = await variablesGanancia.updateVariable(jsonEdit);
        getDataVG();
      }
      // console.log('resut:', result);
      handleCloseVG();
      setTextSuccessAlert(result.status);
      setIsLoading(false);
      setOpenSuccessAlert(true);
    } catch (error) {
      setTextErrorAlert(error.status);
      setOpenErrorAlert(true);
      setIsLoading(false);
      // alert('Fallo al subir el archivo');
    }
  };

  const handleFormEdit = async (e) => {
    e.preventDefault();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: curtainState,
    mode: 'onChange',
  });
  console.log('curtainState:', curtainState);

  useEffect(() => {
    if (reload) {
      let array = [];
      curtainState.types.forEach((type) => {
        let typeName = type.description;
        type.colors.forEach((color) => {
          // let nameTwo = name + color.name;
          array.push({
            type: typeName,
            color: color.name,
            price: color.price ? color.price : 0,
            // dateUpdate: color.dateUpdate ? color.dateUpdate : '-',
          });
        });
      });
      setArrayTelas(array);

      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    console.log('use effect log');
    const subscription = watch((value, { name }) => {
      if (name === 'name') {
        const newSlug =
          value.name
            ?.trim()
            .replaceAll(' ', '_')
            .replaceAll("'", '')
            .toLocaleLowerCase() || '';
        setValue('slug', newSlug);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const onSubmit = async (formData) => {
    console.log('formData:', formData);
    if (formData.images.length + selectedImages.length < 2) {
      enqueueSnackbar('Se necesitan al menos 2 imágenes', { variant: 'info' });
      return;
    }
    if (formData.id) {
      setIsLoading(true);
      CustomCurtainsService.update(formData, selectedImages)
        .then(() => {
          enqueueSnackbar('Producto actualizado correctamente');
          // onClose();
          setReload(true);
        })
        .catch((e) => {
          // console.log('e:', e);
          enqueueSnackbar(
            'Ocurrió un error actualizando el producto, por favor intente nuevamente',
            { variant: 'error' },
          );
        })
        .finally(() => setIsLoading(false));
    } else {
      console.log('else create formdata:', formData);
      setIsLoading(true);
      CustomCurtainsService.create(formData, selectedImages)
        .then(() => {
          enqueueSnackbar('Producto actualizado correctamente');
          onClose();
        })
        .catch(() =>
          enqueueSnackbar(
            'Ocurrió un error creando el producto, por favor intente nuevamente',
            { variant: 'error' },
          ),
        )
        .finally(() => setIsLoading(false));
    }
  };

  const handleAdd = (name) => {
    const newArray = [...getValues(name)];
    newArray.push({
      id: Math.random().toString(),
      description: '',
      price: 0,
    });
    setValue(name, newArray, { shouldValidate: true });
  };

  const handleRemove = (name, value) =>
    setValue(
      name,
      getValues(name).filter((elem) => elem !== value),
      { shouldValidate: true },
    );

  ////////////////////////////////////////////// BLACKOUT + SUNSCREEN////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////// BLACKOUT + SUNSCREEN////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////// BLACKOUT + SUNSCREEN////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////// BLACKOUT + SUNSCREEN////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////// BLACKOUT + SUNSCREEN////////////////////////////////////////////////////////////////////////////////////

  const returnColumn1 = (row) => {
    if (row.name == 'MECANISMOS 32MM') return Number(row.pesos).toFixed(2);
    if (row.name == 'MECANISMOS 38MM') return '0';
    if (row.name == 'MECANISMOS 41 PLUS') return '0';
    if (row.name == 'CADENA PLASTICA + contrapeso')
      return Number(row.pesos).toFixed(2);
    if (row.name == 'TUBO 32') return Number(row.pesos * ancho).toFixed(2);
    if (row.name == 'TUBO 38') return '0';
    if (row.name == 'TUBO 45 mm PRINTEMS') return '0';
    if (row.name == 'TORNILLOS') return Number(row.pesos);
    if (
      row.name == 'BARRA CONTR.' ||
      row.name == 'CINTAS Y ADHESIVOS' ||
      row.name == 'TRANSPORTE'
    )
      return Number(row.pesos * ancho).toFixed(2);
    else return '-';
  };
  const returnColumn2 = (row) => {
    if (row.name == 'MECANISMOS 32MM') return '0';
    if (row.name == 'MECANISMOS 38MM') return Number(row.pesos).toFixed(2);
    if (row.name == 'MECANISMOS 41 PLUS') return '0';
    if (row.name == 'CADENA PLASTICA + contrapeso')
      return Number(row.pesos).toFixed(2);
    if (row.name == 'TUBO 32') return '0';
    if (row.name == 'TUBO 38') return Number(row.pesos * ancho).toFixed(2);
    if (row.name == 'TUBO 45 mm PRINTEMS') return '0';
    if (row.name == 'TORNILLOS') return Number(row.pesos).toFixed(2);
    if (
      row.name == 'BARRA CONTR.' ||
      row.name == 'CINTAS Y ADHESIVOS' ||
      row.name == 'TRANSPORTE'
    )
      return Number(row.pesos * ancho).toFixed(2);
    else return '-';
  };
  const returnColumn3 = (row) => {
    if (row.name == 'MECANISMOS 32MM') return '0';
    if (row.name == 'MECANISMOS 38MM') return '0';
    if (row.name == 'MECANISMOS 41 PLUS') return Number(row.pesos).toFixed(2);
    if (row.name == 'CADENA PLASTICA + contrapeso')
      return Number(row.pesos).toFixed(2);
    if (row.name == 'TUBO 32') return '0';
    if (row.name == 'TUBO 38') return '0';
    if (row.name == 'TUBO 45 mm PRINTEMS')
      return Number(row.pesos * ancho).toFixed(2);
    if (row.name == 'TORNILLOS') return Number(row.pesos).toFixed(2);
    if (
      row.name == 'BARRA CONTR.' ||
      row.name == 'CINTAS Y ADHESIVOS' ||
      row.name == 'TRANSPORTE'
    )
      return Number(row.pesos * ancho).toFixed(2);
    else return '-';
  };

  const costoMaterial1 = () => {
    let sum = 0;
    if (telaSelected && ancho && alto) {
      sum = Number(telaSelected) * calcularMT2();
      // console.log(
      //   'Number(telaSelected) * calcularMT2():',
      //   Number(telaSelected) * calcularMT2(),
      // );

      const mecanismo = variablesCotizador.find(
        (element) => element.name == 'MECANISMOS 32MM',
      );
      sum = sum + Number(mecanismo?.pesos);
      // console.log('mecanismo: ', mecanismo?.pesos);

      const cadena = variablesCotizador.find(
        (element) => element.name == 'CADENA PLASTICA + contrapeso',
      );
      sum = sum + Number(cadena?.pesos);
      //console.log('cadena: ', cadena?.pesos);

      const tubo = variablesCotizador.find(
        (element) => element.name == 'TUBO 32',
      );
      sum = sum + Number(tubo?.pesos) * Number(ancho);
      // console.log('tubo1: ', (Number(tubo?.pesos) * Number(ancho)).toFixed(2));

      const tornillos = variablesCotizador.find(
        (element) => element.name == 'TORNILLOS',
      );
      sum = sum + Number(tornillos?.pesos);
      // // console.log('tornillos: ', tornillos?.pesos);

      const barra = variablesCotizador.find(
        (element) => element.name == 'BARRA CONTR.',
      );
      sum = sum + Number(barra?.pesos) * Number(ancho);
      // console.log('barra: ', Number(barra?.pesos * Number(ancho)));

      const cintayadhesivos = variablesCotizador.find(
        (element) => element.name == 'CINTAS Y ADHESIVOS',
      );
      sum = sum + Number(cintayadhesivos?.pesos) * Number(ancho);
      // console.log(
      //   'cintayadhesivos: ',
      //   Number(Number(cintayadhesivos?.pesos) * Number(ancho)).toFixed(2),
      // );

      const transporte = variablesCotizador.find(
        (element) => element.name == 'TRANSPORTE',
      );
      sum = sum + Number(transporte?.pesos) * Number(ancho);
      // console.log('transporte: ', Number(transporte?.pesos * Number(ancho)));
    }
    // console.log('sum:', sum);
    return sum;
  };

  const costoMaterial2 = () => {
    let sum = 0;
    if (telaSelected && ancho && alto) {
      sum = Number(telaSelected) * calcularMT2();
      // console.log('sum:', sum);
      const mecanismo = variablesCotizador.find(
        (element) => element.name == 'MECANISMOS 38MM',
      );
      sum = sum + Number(mecanismo?.pesos);
      // console.log('mecanismo: ', Number(mecanismo?.pesos));
      // console.log('sum:', sum);
      const cadena = variablesCotizador.find(
        (element) => element.name == 'CADENA PLASTICA + contrapeso',
      );
      sum = sum + Number(cadena?.pesos);
      // console.log('cadena: ', cadena?.pesos);
      // console.log('sum:', sum);
      const tubo = variablesCotizador.find(
        (element) => element.name == 'TUBO 38',
      );
      sum = sum + Number(tubo?.pesos) * Number(ancho);
      // console.log('tubo: ', Number(tubo?.pesos * Number(ancho)));
      // console.log('sum:', sum);
      const tornillos = variablesCotizador.find(
        (element) => element.name == 'TORNILLOS',
      );
      sum = sum + Number(tornillos?.pesos);
      // console.log('tornillos: ', tornillos?.pesos);
      // console.log('sum:', sum);
      const barra = variablesCotizador.find(
        (element) => element.name == 'BARRA CONTR.',
      );
      sum = sum + Number(barra?.pesos) * Number(ancho);
      // console.log('barra: ', Number(barra?.pesos * Number(ancho)));
      // console.log('sum:', sum);
      const cintayadhesivos = variablesCotizador.find(
        (element) => element.name == 'CINTAS Y ADHESIVOS',
      );
      sum = sum + Number(cintayadhesivos?.pesos) * Number(ancho);
      // console.log(
      //   'cintayadhesivos: ',
      //   Number(cintayadhesivos?.pesos * Number(ancho)),
      // );
      // console.log('sum:', sum);
      const transporte = variablesCotizador.find(
        (element) => element.name == 'TRANSPORTE',
      );
      sum = sum + Number(transporte?.pesos) * Number(ancho);
      // console.log('transporte: ', Number(transporte?.pesos * Number(ancho)));
      // console.log('sum:', sum);
    }
    return sum;
  };

  const costoMaterial3 = () => {
    let sum = 0;
    if (telaSelected && ancho && alto) {
      sum = Number(telaSelected) * calcularMT2();

      const mecanismo = variablesCotizador.find(
        (element) => element.name == 'MECANISMOS 41 PLUS',
      );
      sum = sum + Number(mecanismo?.pesos);
      // console.log('mecanismo: ', mecanismo?.pesos);

      const cadena = variablesCotizador.find(
        (element) => element.name == 'CADENA PLASTICA + contrapeso',
      );
      sum = sum + Number(cadena?.pesos);
      // console.log('cadena: ', cadena?.pesos);

      const tubo = variablesCotizador.find(
        (element) => element.name == 'TUBO 45 mm PRINTEMS',
      );
      sum = sum + Number(tubo?.pesos * Number(ancho));
      // console.log('tubo: ', Number(tubo?.pesos * Number(ancho)));

      const tornillos = variablesCotizador.find(
        (element) => element.name == 'TORNILLOS',
      );
      sum = sum + Number(tornillos?.pesos);
      // console.log('tornillos: ', tornillos?.pesos);

      const barra = variablesCotizador.find(
        (element) => element.name == 'BARRA CONTR.',
      );
      sum = sum + Number(barra?.pesos * Number(ancho));
      // console.log('barra: ', Number(barra?.pesos * Number(ancho)));

      const cintayadhesivos = variablesCotizador.find(
        (element) => element.name == 'CINTAS Y ADHESIVOS',
      );
      sum = sum + Number(cintayadhesivos?.pesos * Number(ancho));
      // console.log(
      //   'cintayadhesivos: ',
      //   Number(cintayadhesivos?.pesos * Number(ancho)),
      // );

      const transporte = variablesCotizador.find(
        (element) => element.name == 'TRANSPORTE',
      );
      sum = sum + Number(transporte?.pesos * Number(ancho));
      // console.log('transporte: ', Number(transporte?.pesos * Number(ancho)));
    }
    return sum;
  };

  const costoGananciaFranquicia1 = () => {
    const CGF = variablesGanancias.find(
      (element) => element.name == 'COSTO + GANANCIA FRANQUICIA',
    );
    return Number(costoMaterial1()) * Number(CGF?.valor);
  };

  const costoGananciaFranquicia2 = () => {
    const CGF = variablesGanancias.find(
      (element) => element.name == 'COSTO + GANANCIA FRANQUICIA',
    );
    return Number(costoMaterial2()) * Number(CGF?.valor);
  };

  const costoGananciaFranquicia3 = () => {
    const CGF = variablesGanancias.find(
      (element) => element.name == 'COSTO + GANANCIA FRANQUICIA',
    );
    return Number(costoMaterial3()) * Number(CGF?.valor);
  };

  const costoGanancia1 = () => {
    const ganancia = variablesGanancias.find(
      (element) => element.name == 'GANANCIA',
    );
    return Number(costoMaterial1()) * Number(ganancia?.valor);
  };

  const costoGanancia2 = () => {
    const ganancia = variablesGanancias.find(
      (element) => element.name == 'GANANCIA',
    );
    // console.log('costoMaterial2(): ', costoMaterial2());
    // console.log('ganancia: ', ganancia?.valor);
    return Number(costoMaterial2()) * Number(ganancia?.valor);
  };

  const costoGanancia3 = () => {
    const ganancia = variablesGanancias.find(
      (element) => element.name == 'GANANCIA',
    );
    return Number(costoMaterial3()) * Number(ganancia?.valor);
  };

  const pvpDtoContado1 = () => {
    if (!telaSelected || !ancho || !alto) return 0;
    const gastosgestion = variablesCotizador.find(
      (element) => element.name == 'GASTOS GESTION',
    );
    console.log("gastosgestion:", gastosgestion);
    console.log("costoGanancia1():",costoGanancia1());
    console.log("Number(gastosgestion?.pesos):",Number(gastosgestion?.pesos));
    console.log("return: ", + Number(costoGanancia1() + Number(gastosgestion?.pesos)));
    return Number(costoGanancia1() + Number(gastosgestion?.pesos));
  };

  const pvpDtoContado2 = () => {
    if (!telaSelected || !ancho || !alto) return 0;
    const gastosgestion = variablesCotizador.find(
      (element) => element.name == 'GASTOS GESTION',
    );
    return Number(costoGanancia2() + Number(gastosgestion?.pesos));
  };

  const pvpDtoContado3 = () => {
    if (!telaSelected || !ancho || !alto) return 0;
    const gastosgestion = variablesCotizador.find(
      (element) => element.name == 'GASTOS GESTION',
    );
    return Number(costoGanancia3() + Number(gastosgestion?.pesos));
  };

  const pvpClientePresupuesto1 = () => {
    const CPP = variablesGanancias.find(
      (element) => element.name == 'P.V.P.CLIENTE PARA PRESUPUESTO 50%',
    );
    return Number(pvpDtoContado1()) * Number(CPP?.valor);

    // return Number(pvpDtoContado1() * Number(2));
  };

  const pvpClientePresupuesto2 = () => {
    const CPP = variablesGanancias.find(
      (element) => element.name == 'P.V.P.CLIENTE PARA PRESUPUESTO 50%',
    );
    return Number(pvpDtoContado2()) * Number(CPP?.valor);
  };

  const pvpClientePresupuesto3 = () => {
    const CPP = variablesGanancias.find(
      (element) => element.name == 'P.V.P.CLIENTE PARA PRESUPUESTO 50%',
    );
    return Number(pvpDtoContado3()) * Number(CPP?.valor);
  };

  const costoGananciaMayorista1 = () => {
    const CGM = variablesGanancias.find(
      (element) => element.name == 'COSTO + GANANCIA MAYORISTA',
    );
    return Number(costoMaterial1()) * Number(CGM?.valor);
  };

  const costoGananciaMayorista2 = () => {
    const CGM = variablesGanancias.find(
      (element) => element.name == 'COSTO + GANANCIA MAYORISTA',
    );
    return Number(costoMaterial2()) * Number(CGM?.valor);
  };

  const costoGananciaMayorista3 = () => {
    const CGM = variablesGanancias.find(
      (element) => element.name == 'COSTO + GANANCIA MAYORISTA',
    );
    return Number(costoMaterial3()) * Number(CGM?.valor);
  };

  const totalPrecioMayoristaDistribuidor1 = () => {
    const gastosgestion = variablesCotizador.find(
      (element) => element.name == 'GASTOS GESTION',
    );
    return Number(costoGananciaMayorista1()) + Number(gastosgestion?.pesos);
  };

  const totalPrecioMayoristaDistribuidor2 = () => {
    const gastosgestion = variablesCotizador.find(
      (element) => element.name == 'GASTOS GESTION',
    );
    return Number(costoGananciaMayorista2()) + Number(gastosgestion?.pesos);
  };

  const totalPrecioMayoristaDistribuidor3 = () => {
    const gastosgestion = variablesCotizador.find(
      (element) => element.name == 'GASTOS GESTION',
    );
    return Number(costoGananciaMayorista3()) + Number(gastosgestion?.pesos);
  };

  ////////////////////////////////////////////// Bandas VERTICALES ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////// Bandas VERTICALES ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////// Bandas VERTICALES ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////// Bandas VERTICALES ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////// Bandas VERTICALES ////////////////////////////////////////////////////////////////////////////////////

  const returnColumnBandasVerticales = (row) => {
    if (row.name == 'RIEL + ACCESORIOS')
      return Number(row.pesos).toFixed(2) * Number(ancho);
    if (row.name == 'CONTRAPESOS CUERDA') return Number(row.pesos).toFixed(2);
    if (row.name == 'CINTAS Y ADHESIVOS')
      return Number(row.pesos).toFixed(2) * Number(ancho);
    if (row.name == 'TRANSPORTE') return Number(row.pesos).toFixed(2);
    if (row.name == 'GASTOS GESTION') return Number(row.pesos).toFixed(2);
    else return '-';
  };

  const costoMaterialBV = () => {
    let sum = 0;
    if (telaSelected && ancho && alto) {
      sum = Number(telaSelected) * calcularMT2();
      console.log('sum:', sum);
      // console.log(
      //   'Number(telaSelected) * calcularMT2():',
      //   Number(telaSelected) * calcularMT2(),
      // );

      const rielAccesorios = variablesBandasVerticales.find(
        (element) => element.name == 'RIEL + ACCESORIOS',
      );

      sum = sum + Number(rielAccesorios?.pesos) * ancho;
      // console.log('prod: ', Number(rielAccesorios?.pesos) * ancho);

      const contraPesoCuerda = variablesBandasVerticales.find(
        (element) => element.name == 'CONTRAPESOS CUERDA',
      );
      sum = sum + Number(contraPesoCuerda?.pesos);

      const cintayadhesivos = variablesBandasVerticales.find(
        (element) => element.name == 'CINTAS Y ADHESIVOS',
      );
      sum = sum + Number(cintayadhesivos?.pesos) * Number(ancho);
      // console.log(
      //   'cintayadhesivos: ',
      //   Number(Number(cintayadhesivos?.pesos) * Number(ancho)).toFixed(2),
      // );

      const transporte = variablesBandasVerticales.find(
        (element) => element.name == 'TRANSPORTE',
      );
      sum = sum + Number(transporte?.pesos) * Number(ancho);
      // console.log('transporte: ', Number(transporte?.pesos * Number(ancho)));
    }
    // console.log('sum:', sum);
    return sum;
  };

  const costoGananciaFranquiciaBV = () => {
    const CGF = variablesGananciasBandasVerticales.find(
      (element) => element.name == 'COSTO + GANANCIA FRANQUICIA',
    );
    return Number(costoMaterialBV()) * Number(CGF?.valor);
  };

  const costoGananciaBV = () => {
    const ganancia = variablesGananciasBandasVerticales.find(
      (element) => element.name == 'GANANCIA',
    );
    return Number(costoMaterialBV()) * Number(ganancia?.valor);
  };

  const pvpDtoContadoBV = () => {
    if (!telaSelected || !ancho || !alto) return 0;
    const gastosgestion = variablesBandasVerticales.find(
      (element) => element.name == 'GASTOS GESTION',
    );
    return Number(costoGananciaBV() + Number(gastosgestion?.pesos));
  };

  const pvpClientePresupuestoBV = () => {
    const CPP = variablesGananciasBandasVerticales.find(
      (element) => element.name == 'P.V.P.CLIENTE PARA PRESUPUESTO',
    );
    return Number(pvpDtoContadoBV()) * Number(CPP?.valor);
  };

  const costoGananciaMayoristaBV = () => {
    const CGM = variablesGananciasBandasVerticales.find(
      (element) => element.name == 'COSTO + GANANCIA MAYORISTA',
    );
    return Number(costoMaterialBV()) * Number(CGM?.valor);
  };

  const totalPrecioMayoristaDistribuidorBV = () => {
    const gastosgestion = variablesBandasVerticales.find(
      (element) => element.name == 'GASTOS GESTION',
    );
    return Number(costoGananciaMayoristaBV()) + Number(gastosgestion?.pesos);
  };

  /////////////////////////////////////////////////////// RETURN ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////// RETURN ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////// RETURN ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////// RETURN ////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////// RETURN ////////////////////////////////////////////////////////////////////////////////////

  return (
    <Grid
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      container
      direction="column"
      spacing={2}
      sx={{ height: '100%', width: '100%' }}
    >
      <LoadingCircularProgress open={isLoading} />
      <Grid item container spacing={2}>
        <Grid item container direction="column" spacing={2} xs={12} sm={6}>
          <Grid item>
            <TextField
              disabled
              name="name"
              label="Nombre"
              required
              fullWidth
              {...register('name', {
                required: 'Este campo es requerido',
                minLength: { value: 2, message: 'Mínimo 2 caracteres' },
              })}
              error={!!errors.name}
              helperText={errors.name?.message.toString()}
            />
          </Grid>
          <Grid item>
            <TextField
              name="description"
              label="Descripción"
              required
              multiline
              rows={5}
              fullWidth
              {...register('description', {
                required: 'Este campo es requerido',
                minLength: { value: 2, message: 'Mínimo 2 caracteres' },
              })}
              error={!!errors.description}
              helperText={errors.description?.message.toString()}
            />
          </Grid>
          {/* <Grid item container spacing={2}>
            <Grid item xs={6}>
              <TextField
                name="price"
                label="Precio"
                required
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
                {...register('price', {
                  required: 'Este campo es requerido',
                  min: { value: 0, message: 'Mínimo valor 0' },
                })}
                error={!!errors.price}
                helperText={errors.price?.message.toString()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="quantity"
                label="Cantidad"
                required
                type="number"
                fullWidth
                {...register('quantity', {
                  required: 'Este campo es requerido',
                  min: { value: 0, message: 'Mínimo valor 0' },
                })}
                error={!!errors.quantity}
                helperText={errors.quantity?.message.toString()}
              />
            </Grid>
          </Grid> */}

          <Types
            field="types"
            getValues={getValues}
            setValue={setValue}
            register={register}
            errors={errors}
          />

          <MultipleValues
            title="Caida de rollo"
            field="rollDropTypes"
            getValues={getValues}
            register={register}
            errors={errors}
            handleAdd={handleAdd}
            handleRemove={handleRemove}
          />
          <MultipleValues
            title="Posición del mando"
            field="positions"
            getValues={getValues}
            register={register}
            errors={errors}
            handleAdd={handleAdd}
            handleRemove={handleRemove}
          />
          <MultipleValues
            title="Tipos de cadenas"
            field="chainTypes"
            getValues={getValues}
            register={register}
            errors={errors}
            handleAdd={handleAdd}
            handleRemove={handleRemove}
          />
        </Grid>
        <Grid item container direction="column" spacing={2} xs={12} sm={6}>
          {/* <Grid item>
            <TextField
              name="slug"
              label="Slug - URL"
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
              {...register('slug', {
                required: 'Este campo es requerido',
                validate: (val) =>
                  val.trim().includes(' ')
                    ? 'No puede tener espacios en blanco'
                    : undefined,
              })}
              error={!!errors.slug}
              helperText={errors.slug?.message.toString()}
            />
          </Grid> */}
          <Grid item>
            {/* <Divider sx={{ my: 2 }} /> */}
            <UploadImages
              selectedImages={selectedImages}
              setSelectedImages={setSelectedImages}
              getValues={getValues}
              setValue={setValue}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item display="flex" justifyContent="flex-end">
        <Button type="submit" variant="contained">
          Confirmar
        </Button>
      </Grid>

      <Grid mt={5}>
        <Typography style={{ fontSize: '24px' }}>Tabla de telas</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography style={{ fontWeight: 900 }}>
                    Tipo de Tela
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography style={{ fontWeight: 900 }}>Color</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography style={{ fontWeight: 900 }}>MT2 $</Typography>
                </TableCell>
                {/* <TableCell align="center">
                  <Typography style={{ fontWeight: 900 }}>
                    Fecha actualizado precio
                  </Typography>
                </TableCell> */}
                {/* <TableCell align="center">
                  <Typography style={{ fontWeight: 900 }}>Editar</Typography>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {arrayTelas.map((row, index) => (
                <TableRow
                  key={'arrayTelas' + index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {row.type}
                  </TableCell>
                  <TableCell align="center">{row.color}</TableCell>
                  <TableCell align="center">{row.price}</TableCell>
                  {/* <TableCell align="center">{row.dateUpdate}</TableCell> */}
                  {/* <TableCell
                    align="center"
                    onClick={() => handleClickOpen(row)}
                  >
                    <EditIcon style={{ cursor: 'pointer' }} />
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid mt={5}>
        <Typography style={{ fontSize: '24px' }}>Cotizador</Typography>
        <Grid>
          <Grid item display={'flex'} alignItems={'center'}>
            <TextField
              id="outlined-select-currency"
              label={'Tela'}
              select
              sx={{ m: 1, width: '25ch' }}
              value={telaSelected}
              onChange={handleChangeTela}
            >
              {arrayTelas.map((option, i) => (
                <MenuItem key={i} value={option.price}>
                  {option.type + ' - ' + option.color}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Ancho"
              id="outlined-start-adornment"
              sx={{ m: 1, width: '25ch' }}
              value={ancho}
              onChange={handleChangeAncho}
            />
            <Typography>x</Typography>
            <TextField
              label="Alto"
              id="outlined-start-adornment"
              sx={{ m: 1, width: '25ch' }}
              value={alto}
              onChange={handleChangeAlto}
            />

            {ancho && alto && (
              <Typography>
                = {ancho} * ({alto} + 0.30 cm) ={' '}
                {calcularMT2() +
                  ' MT2 => $' +
                  telaSelected +
                  ' * ' +
                  calcularMT2() +
                  ' = Costo tela $' +
                  Number(telaSelected) * calcularMT2()}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      {curtain.name == 'Bandas Verticales' ? (
        <>
          <Grid mt={5}>
            <Typography style={{ fontSize: '24px', marginTop: '1em' }}>
              Variables Bandas Verticales
            </Typography>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography style={{ fontWeight: 900 }}>
                        Nombre
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography style={{ fontWeight: 900 }}>Dolar</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography style={{ fontWeight: 900 }}>Pesos</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography style={{ fontWeight: 900 }}>-</Typography>
                    </TableCell>
                    {/* <TableCell align="right">
                  <Typography style={{ fontWeight: 900 }}>2</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography style={{ fontWeight: 900 }}>3</Typography>
                </TableCell> */}
                    <TableCell align="right">
                      <Typography style={{ fontWeight: 900 }}>
                        Editar
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {telaSelected && (
                    <>
                      <TableRow
                        key={'indexCosteMateriaPrima'}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {'COSTE MATERIA PRIMA'}
                        </TableCell>
                        <TableCell align="right"> - </TableCell>
                        <TableCell align="right"> - </TableCell>
                        <TableCell align="right">{Number(ancho)}</TableCell>
                      </TableRow>

                      <TableRow
                        key={'indexTela'}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {'TELA'}
                        </TableCell>
                        <TableCell align="right"> - </TableCell>
                        <TableCell align="right">
                          {' '}
                          {'$ ' + telaSelected}{' '}
                        </TableCell>
                        <TableCell align="right">
                          {'$ ' + Number(telaSelected) * calcularMT2()}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {variablesBandasVerticales.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">
                        {' '}
                        {row.name == 'GANANCIA' ? '$ -' : '$' + row.dolar}{' '}
                      </TableCell>
                      <TableCell align="right">
                        {' '}
                        {row.name == 'GANANCIA'
                          ? row.pesos
                          : '$' + row.pesos}{' '}
                      </TableCell>
                      <TableCell align="right">
                        {'$ '}
                        {returnColumnBandasVerticales(row)}
                      </TableCell>

                      <TableCell
                        align="right"
                        onClick={() =>
                          handleClickOpenEditVariablesCotizador(row)
                        }
                      >
                        <EditIcon style={{ cursor: 'pointer' }} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid mt={5}>
            <Typography style={{ fontSize: '24px' }}>
              Variables ganancia
            </Typography>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Typography style={{ fontWeight: 900 }}>
                        Nombre
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography style={{ fontWeight: 900 }}>Valor</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography style={{ fontWeight: 900 }}>
                        Editar
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {variablesGananciasBandasVerticales.map((row, index) => (
                    <TableRow
                      key={'variablesGanancias' + index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="center">{row.valor}</TableCell>
                      <TableCell
                        align="center"
                        onClick={() =>
                          handleClickOpenEditVariablesGanancia(row)
                        }
                      >
                        <EditIcon style={{ cursor: 'pointer' }} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid mt={5}>
            <Typography style={{ fontSize: '24px' }}>Totales</Typography>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Typography style={{ fontWeight: 900 }}>
                        Nombre
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography style={{ fontWeight: 900 }}>
                        Totales
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={'index1'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {'COSTO + GANCIA FRANQUICIA'}
                    </TableCell>
                    <TableCell align="center">
                      {costoGananciaFranquiciaBV()}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={'index2'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {'COSTO MATERIAL'}
                    </TableCell>
                    <TableCell align="center">{costoMaterialBV()}</TableCell>
                  </TableRow>
                  <TableRow
                    key={'index3'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {'COSTO + GANCIA'}
                    </TableCell>
                    <TableCell align="center">{costoGananciaBV()}</TableCell>
                  </TableRow>
                  <TableRow
                    key={'index4'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {'PVP con dto'}
                    </TableCell>
                    <TableCell align="center">{pvpDtoContadoBV()}</TableCell>
                  </TableRow>
                  <TableRow
                    key={'index5'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {'P.V.P.CLIENTE  PARA PRESUPUESTO 50%'}
                    </TableCell>
                    <TableCell align="center">
                      {pvpClientePresupuestoBV()}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={'index6'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {'COSTO + GANANCIA MAYORISTA'}
                    </TableCell>
                    <TableCell align="center">
                      {costoGananciaMayoristaBV()}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={'index7'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {'TOTAL PRECIO MAYORISTA 65%'}
                    </TableCell>
                    <TableCell align="center">
                      {totalPrecioMayoristaDistribuidorBV()}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Dialog
            onClose={handleCloseVC}
            aria-labelledby="customized-dialog-title"
            open={showModalEdit}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              {itemToEditVC?.name}
            </DialogTitle>

            <DialogContent dividers>
              <Grid container flexDirection={'column'}>
                <Grid item>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Dolar"
                    variant="standard"
                    value={newPriceDolar}
                    onChange={handleChangeNewPriceDolar}
                    type="number"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Pesos"
                    variant="standard"
                    value={newPricePesos}
                    onChange={handleChangeNewPricePesos}
                    type="number"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseVC}>Cancelar</Button>
              <Button onClick={updatePesosDolarPrices}>Guardar</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            onClose={handleCloseVG}
            aria-labelledby="customized-dialog-title"
            open={showModalEditVG}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              {itemToEditVG?.name}
            </DialogTitle>

            <DialogContent dividers>
              <Grid container flexDirection={'column'}>
                <Grid item>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Valor"
                    variant="standard"
                    value={newValueVG}
                    onChange={handleChangeNewValueVG}
                    type="number"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseVG}>Cancelar</Button>
              <Button onClick={updateValueVG}>Guardar</Button>
            </DialogActions>
          </Dialog>
        </>
      ) : null}

      {curtain.name == 'Sunscreen' || curtain.name == 'Blackout' ? (
        <>
          <Grid mt={5}>
            <Typography style={{ fontSize: '24px', marginTop: '1em' }}>
              Variables Roller
            </Typography>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography style={{ fontWeight: 900 }}>
                        Nombre
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography style={{ fontWeight: 900 }}>Dolar</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography style={{ fontWeight: 900 }}>Pesos</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography style={{ fontWeight: 900 }}>1</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography style={{ fontWeight: 900 }}>2</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography style={{ fontWeight: 900 }}>3</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography style={{ fontWeight: 900 }}>
                        Editar
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {telaSelected && (
                    <TableRow
                      key={'indexTela'}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {'TELA'}
                      </TableCell>
                      <TableCell align="right"> - </TableCell>
                      <TableCell align="right">
                        {' '}
                        {'$ ' + telaSelected}{' '}
                      </TableCell>
                      <TableCell align="right">
                        {'$ ' + Number(telaSelected) * calcularMT2()}
                      </TableCell>
                      <TableCell align="right">
                        {' '}
                        {'$ ' + Number(telaSelected) * calcularMT2()}
                      </TableCell>
                      <TableCell align="right">
                        {' '}
                        {'$ ' + Number(telaSelected) * calcularMT2()}
                      </TableCell>
                    </TableRow>
                  )}
                  {variablesCotizador.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">
                        {' '}
                        {row.name == 'GANANCIA' ? '$ -' : '$' + row.dolar}{' '}
                      </TableCell>
                      <TableCell align="right">
                        {' '}
                        {row.name == 'GANANCIA'
                          ? row.pesos
                          : '$' + row.pesos}{' '}
                      </TableCell>
                      <TableCell align="right">
                        {'$ '}
                        {returnColumn1(row)}
                      </TableCell>
                      <TableCell align="right">
                        {'$ '}
                        {returnColumn2(row)}
                      </TableCell>
                      <TableCell align="right">
                        {'$ '}
                        {returnColumn3(row)}
                      </TableCell>
                      {/* <TableCell align="right" onClick={() => handleClickOpen(row)}>
                    <EditIcon style={{ cursor: 'pointer' }} />
                  </TableCell> */}
                      <TableCell
                        align="right"
                        onClick={() =>
                          handleClickOpenEditVariablesCotizador(row)
                        }
                      >
                        <EditIcon style={{ cursor: 'pointer' }} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid mt={5}>
            <Typography style={{ fontSize: '24px' }}>
              Variables ganancia
            </Typography>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Typography style={{ fontWeight: 900 }}>
                        Nombre
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography style={{ fontWeight: 900 }}>Valor</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography style={{ fontWeight: 900 }}>
                        Editar
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {variablesGanancias.map((row, index) => (
                    <TableRow
                      key={'variablesGanancias' + index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="center">{row.valor}</TableCell>
                      <TableCell
                        align="center"
                        onClick={() =>
                          handleClickOpenEditVariablesGanancia(row)
                        }
                      >
                        <EditIcon style={{ cursor: 'pointer' }} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid mt={5}>
            <Typography style={{ fontSize: '24px' }}>Totales</Typography>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Typography style={{ fontWeight: 900 }}>
                        Nombre
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography style={{ fontWeight: 900 }}>1</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography style={{ fontWeight: 900 }}>2</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography style={{ fontWeight: 900 }}>3</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={'index1'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {'COSTO + GANCIA FRANQUICIA'}
                    </TableCell>
                    <TableCell align="center">
                      {costoGananciaFranquicia1()}
                    </TableCell>
                    <TableCell align="center">
                      {costoGananciaFranquicia2()}
                    </TableCell>
                    <TableCell align="center">
                      {costoGananciaFranquicia3()}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={'index2'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {'COSTO MATERIAL'}
                    </TableCell>
                    <TableCell align="center">{costoMaterial1()}</TableCell>
                    <TableCell align="center">{costoMaterial2()}</TableCell>
                    <TableCell align="center">{costoMaterial3()}</TableCell>
                  </TableRow>
                  <TableRow
                    key={'index3'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {'COSTO + GANCIA'}
                    </TableCell>
                    <TableCell align="center">{costoGanancia1()}</TableCell>
                    <TableCell align="center">{costoGanancia2()}</TableCell>
                    <TableCell align="center">{costoGanancia3()}</TableCell>
                  </TableRow>
                  <TableRow
                    key={'index4'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {'PVP con dto- contado'}
                    </TableCell>
                    <TableCell align="center">{pvpDtoContado1()}</TableCell>
                    <TableCell align="center">{pvpDtoContado2()}</TableCell>
                    <TableCell align="center">{pvpDtoContado3()}</TableCell>
                  </TableRow>
                  <TableRow
                    key={'index5'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {'P.V.P.CLIENTE  PARA PRESUPUESTO 50%'}
                    </TableCell>
                    <TableCell align="center">
                      {pvpClientePresupuesto1()}
                    </TableCell>
                    <TableCell align="center">
                      {pvpClientePresupuesto2()}
                    </TableCell>
                    <TableCell align="center">
                      {pvpClientePresupuesto3()}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={'index6'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {'COSTO + GANANCIA MAYORISTA'}
                    </TableCell>
                    <TableCell align="center">
                      {costoGananciaMayorista1()}
                    </TableCell>
                    <TableCell align="center">
                      {costoGananciaMayorista2()}
                    </TableCell>
                    <TableCell align="center">
                      {costoGananciaMayorista3()}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={'index7'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {'TOTAL PRECIO MAYORISTA 65% DISTRIBUIDOR'}
                    </TableCell>
                    <TableCell align="center">
                      {totalPrecioMayoristaDistribuidor1()}
                    </TableCell>
                    <TableCell align="center">
                      {totalPrecioMayoristaDistribuidor2()}
                    </TableCell>
                    <TableCell align="center">
                      {totalPrecioMayoristaDistribuidor3()}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Dialog
            onClose={handleCloseVC}
            aria-labelledby="customized-dialog-title"
            open={showModalEdit}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              {itemToEditVC?.name}
            </DialogTitle>

            <DialogContent dividers>
              <Grid container flexDirection={'column'}>
                <Grid item>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Dolar"
                    variant="standard"
                    value={newPriceDolar}
                    onChange={handleChangeNewPriceDolar}
                    type="number"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Pesos"
                    variant="standard"
                    value={newPricePesos}
                    onChange={handleChangeNewPricePesos}
                    type="number"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseVC}>Cancelar</Button>
              <Button onClick={updatePesosDolarPrices}>Guardar</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            onClose={handleCloseVG}
            aria-labelledby="customized-dialog-title"
            open={showModalEditVG}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              {itemToEditVG?.name}
            </DialogTitle>

            <DialogContent dividers>
              <Grid container flexDirection={'column'}>
                <Grid item>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    label="Valor"
                    variant="standard"
                    value={newValueVG}
                    onChange={handleChangeNewValueVG}
                    type="number"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseVG}>Cancelar</Button>
              <Button onClick={updateValueVG}>Guardar</Button>
            </DialogActions>
          </Dialog>
        </>
      ) : null}

      <Grid
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          top: '500px',
        }}
      >
        <Collapse in={openSuccessAlert}>
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenSuccessAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {textSuccessAlert}
          </Alert>
        </Collapse>
        <Collapse in={openErrorAlert}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenErrorAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {textErrorAlert}
          </Alert>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default CurtainForm;
