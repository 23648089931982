// @ts-nocheck
import React, { useState, useEffect } from 'react';

import { Grid, styled, Typography } from '@mui/material';

import CardItem from '../../components/CardProduct/index';
//LoadingCircularProgress
import LoadingCircularProgress from '../../components/LoadingCircularProgress/index';

import { getCollectionCatalogueProducts } from '../../services/products';

import { CategoryCatalogueService } from 'services';
import { CatalogueFilters } from 'components/CatalogueFilters';

const Text = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: 800,
  textAlign: props.align || 'center',
  color: props.color || 'rgb(24, 93, 34)',
}));

const SubText = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: 500,
  textAlign: props.align || 'center',
  color: props.color || 'rgb(24, 93, 34)',
}));

const CatalogoNew = () => {
  const [products, setProducts] = useState([]);
  const [filterCatalogue, setFilterCatalogue] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [categorie, setCategorie] = useState('All');
  const [categoriesCatalogue, setCategoriesCatalogue] = useState([]);
  const [categoriesCatalogueInt, setCategoriesCatalogueInt] = useState([]);
  const [categoriesCatalogueExt, setCategoriesCatalogueExt] = useState([]);

  const [IntText, setIntExt] = useState('Interior');
  const [NameSelected, setNameSelected] = useState('Blackout');
  const [categorieDescription, setCategoriaDescripcion] = useState(
    'Son telas de alto grado de opacidad. bloquean la entrada de luz desde el exterior, debido asu estructura sólida, además de bajar y regular la temperatura de los ambientes. Tela ignifuga, que permite su instalación en espacios publicos.',
  );

  const [filterCategorieFromDialog, setFilterCategorieFromDialog] =
    useState('');

  const handleChangeCategorie = async (e) => {
    e.preventDefault();
    setCategorie(e.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    getProducts();
    getAllCategoriesCatalogue();
    getAllCategoriesCatalogueInt();
    getAllCategoriesCatalogueExt();
    setIsLoading(false);
  }, []);

  const getProducts = async () => {
    try {
      const result = await getCollectionCatalogueProducts();
      console.log('result1: ', result);
      result.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      console.log('result2: ', result);
      setProducts(result);
    } catch (error) {
      setProducts([]);
      // alert('Fallo al subir el archivo');
    }
  };

  const getAllCategoriesCatalogue = async () => {
    const categories = await CategoryCatalogueService.getAll();
    setCategoriesCatalogue(categories);
  };

  const getAllCategoriesCatalogueExt = async () => {
    const categories = await CategoryCatalogueService.getAllExt();
    setCategoriesCatalogueExt(categories);
  };

  const getAllCategoriesCatalogueInt = async () => {
    const categories = await CategoryCatalogueService.getAllInt();
    setCategoriesCatalogueInt(categories);
  };

  return (
    <Grid container direction="row" id={'IDContainer'} height={'fit-content'}>
      <LoadingCircularProgress open={isLoading} />
      <Grid
        xs={12}
        lg={12}
        container
        item
        margin={2}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Grid item xs={12} lg={12}>
          <Text
            color={'rgb(24, 93, 34)'}
            sx={{
              fontSize: {
                md: 36,
                xs: 12,
              },
            }}
          >
            {IntText == 'Interior' ? 'Linea Interiores' : 'Linea Exterior'}
          </Text>
        </Grid>
        <Grid item xs={12} lg={12}>
          <SubText
            sx={{
              fontSize: {
                md: 18,
                xs: 14,
              },
            }}
            color={'rgb(24, 93, 34)'}
          >
            {IntText == 'Interior'
              ? 'Pensadas para realzar y brindar ese detalle personalizado que buscas para cada parte de tu casa. Diseños minimalistas, delicados y a la vanguardia en diseño de interiores'
              : 'Equipamiento para galerias y espacios a la intemperie o semi intemperie. Desarrollados con telas resistentes al sol y al agua. A la vanguardia en diseño y practicidad'}
          </SubText>
        </Grid>
      </Grid>
      {/* <Grid
        xs={12}
        lg={12}
        container
        item
        margin={2}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Grid item xs={12} lg={12}>
          <Text
            sx={{
              fontSize: {
                md: 35,
                xs: 12,
              },
            }}
          >
            Blackout
          </Text>
        </Grid>
        <Grid item xs={12} lg={12}>
          <SubText
            sx={{
              fontSize: {
                md: 16,
                xs: 12,
              },
            }}
          >
            Pensadas para realzar y brindar ese detalle personalizado que buscas
            para cada parte de tu casa. Diseños minimalistas, delicados y a la
            vanguardia en diseño de interiores
          </SubText>
        </Grid>
      </Grid> */}

      <Grid item container direction="row" marginBottom={'1em'}>
        {/* /FILTROS/ */}
        <Grid pl={'10px'} item lg={2} xs={12}>
          <CatalogueFilters
            IntExt={setIntExt}
            NameSelected={setNameSelected}
            setCategoriaDescripcion={setCategoriaDescripcion}
            catalogue={products}
            categoriesCatalogue={categoriesCatalogue}
            categoriesCatalogueInt={categoriesCatalogueInt}
            categoriesCatalogueExt={categoriesCatalogueExt}
            setFilterCatalogue={setFilterCatalogue}
            filterCategorieFromDialog={filterCategorieFromDialog}
          />
        </Grid>
        {/* /FILTROS/ */}

        {/* /PRODUCTOS/ */}
        <Grid
          container
          item
          lg={10}
          xs={12}
          // pl={'20px'}
          direction={'row'}
          spacing={2}
          display={'flex'}
          justifyContent={'center'}
        >
          {IntText == 'Interior' && (
            <Grid
              xs={10}
              lg={10}
              container
              item
              display={'flex'}
              flexDirection={'column'}
            >
              <Grid item xs={12} lg={12}>
                <Text
                  sx={{
                    fontSize: {
                      md: 25,
                      xs: 12,
                    },
                  }}
                  fontStyle={'italic'}
                  color={'black'}
                >
                  {NameSelected}
                </Text>
              </Grid>
              <Grid item xs={10} lg={10}>
                <SubText
                  sx={{
                    fontSize: {
                      md: 16,
                      xs: 12,
                    },
                  }}
                  fontStyle={'italic'}
                  color={'black'}
                >
                  {categorieDescription}
                </SubText>
              </Grid>
            </Grid>
          )}

          {filterCatalogue.map((element, index) => {
            return (
              <Grid item key={index}>
                <CardItem
                  item={element}
                  // isEdit={false}
                  isPreview={true}
                  // setItemForEdit={(e) => handleSetItemForEdit(e)}
                  // setItemForDelete={(e) => handleSetItemForDelete(e)}
                />
              </Grid>
            );
          })}
        </Grid>

        {/* /PRODUCTOS/ */}
      </Grid>
    </Grid>
  );
};

export default CatalogoNew;
