// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button, Grid, IconButton, Typography, styled } from '@mui/material';
import { FavoriteBorderOutlined } from '@mui/icons-material';
import Filters from './Filters';
import Resume from './Resume';
import useQuery from 'hooks/useQuery';
import { CustomCurtainsService } from 'services';
import { initialForm, initialParameters } from './constants';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Root = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4, '10rem'),
  margin: 0,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 1),
  },
}));

const QuoterPage = () => {
  const query = useQuery();
  const category = query.get('category');
  const [parameters, setParameters] = useState(initialParameters);
  const [formData, setFormData] = useState(initialForm);

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (category) {
      CustomCurtainsService.getBySlug(category).then((res) =>
        setParameters(res),
      );
      setFormData(initialForm);
    }
  }, [category]);

  const resetFormData = () => {
    setFormData(initialForm);
  };

  const openSuccessModal = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const onAddQuantity = () => {
    setFormData({
      ...formData,
      quantity: formData.quantity + 1,
    });
  };

  const onRemoveQuantity = () => {
    if (formData.quantity === 1) return;
    setFormData({
      ...formData,
      quantity: formData.quantity - 1,
    });
  };

  return (
    <Root container spacing={4}>
      <Grid item container xs={12} justifyContent="center" md={6} >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Swiper
            style={{
              width: '100%',
            }}
            modules={[Navigation]}
            slidesPerView={1}
            navigation
            loop
            centeredSlides
          >
            {parameters.images.map((image, index) => (
              <SwiperSlide key={index}>
                <img className="img" width={'100%'} src={image} alt="" />
                
              </SwiperSlide>
            ))}
          </Swiper>
          <Grid item xs>
          <Typography fontFamily={'Inter'} fontWeight={600} fontSize={17}>
            DESCRIPCIÓN
          </Typography>
          <Typography fontFamily={'Inter'} fontWeight={400} fontSize={16}>
            {parameters.description}
          </Typography>
        </Grid>
        </Grid>
        
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="left"
        xs={12}
        md={6}
        spacing={2}
      >
        <Grid item sx={{textAlign:'left'}}>
          <Typography
            variant="h1"
            fontFamily="Inter"
            fontSize={30}
            fontWeight={400}
            
          >
            {parameters.name}
          </Typography>
        </Grid>
        <Grid item container justifyContent="space-around">
          <div
            style={{
              display: 'flex',
              backgroundColor: '#2FB943',
              borderRadius: '5px',
            }}  
          >
            <Button sx={{ color: 'white' }} onClick={onAddQuantity}>
              +
            </Button>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 16,
                paddingRight: 16,
                borderLeft: '1px solid white',
                borderRight: '1px solid white',
              }}
            >
              <Typography color="white">{formData.quantity}</Typography>
            </div>
            <Button sx={{ color: 'white' }} onClick={onRemoveQuantity}>
              -
            </Button>
          </div>
          
        </Grid>
        {/* <Grid item>
          <Typography>Plazo de despacho: 15 días</Typography>
        </Grid> */}
        <Filters
          parameters={parameters}
          formData={formData}
          setFormData={setFormData}
        />
        <Grid item width="100%">
          <Resume
            formData={formData}
            parameters={parameters}
            resetFormData={resetFormData}
            openSuccessModal={openSuccessModal}
          />
        </Grid>
      </Grid>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h1"
            fontSize={24}
            fontWeight={600}
            fontFamily={'Rubik, sans-serif'}
            color={'#185D22'}
          >
            {'¡Gracias por cotizar con Texalia!'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            display={'flex'}
            justifyContent={'center'}
          >
            A la brevedad su consulta sera atentida.
          </DialogContentText>
        </DialogContent>
        <DialogActions display={'flex'} justifyContent={'center'}>
          <Button onClick={handleClose} autoFocus>
            <Typography
              variant="h1"
              fontSize={16}
              fontWeight={500}
              fontFamily={'Rubik, sans-serif'}
              color={'#185D22'}
            >
              {'Cerrar'}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
};

export default QuoterPage;
