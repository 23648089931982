import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { IconButton } from "@mui/material";

const WhatsAppButton = () => {
  const handleClick = () => {
    // @ts-ignore
    window.open("https://wa.me/3513668829", "_blank");
  };

  return (
    <IconButton
      onClick={handleClick}
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        backgroundColor: "#25D366",
        color: "#fff",
        zIndex: 1000,
      }}
    >
      <WhatsAppIcon fontSize="large" />
    </IconButton>
  );
};

export default WhatsAppButton;
