import { RemoveCircleOutline } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import React from 'react';

const MultipleValues = ({
  title,
  field,
  getValues,
  register,
  errors,
  handleAdd,
  handleRemove,
}) => {
  return (
    <Grid item>
      <Accordion>
        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item container direction="column" spacing={2}>
            {getValues(field).map((type, idx) => {
              const fieldName = `${field}.${idx}`;
              return (
                <Grid
                  key={fieldName}
                  item
                  container
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item xs={7}>
                    <TextField
                      name={`${fieldName}.description`}
                      label="Descripción"
                      size="small"
                      fullWidth
                      {...register(`${field}.${idx}.description`, {
                        required: 'Este campo es requerido',
                        minLength: {
                          value: 2,
                          message: 'Mínimo 2 caracteres',
                        },
                      })}
                      error={
                        errors[field]?.length >= idx + 1 &&
                        !!errors[field][idx]?.description
                      }
                      helperText={
                        errors[field]?.length >= idx + 1 &&
                        errors[field][idx]?.description?.message
                      }
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      name={`${fieldName}.price`}
                      label="Precio"
                      type="number"
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      {...register(`${field}.${idx}.price`, {
                        required: 'Campo requerido',
                        min: { value: 0, message: 'Mínimo valor 0' },
                      })}
                      error={
                        errors[field]?.length >= idx + 1 &&
                        !!errors[field][idx]?.price
                      }
                      helperText={
                        errors[field]?.length >= idx + 1 &&
                        errors[field][idx]?.price?.message
                      }
                    />
                  </Grid>
                  {getValues(field).length > 1 && (
                    <Grid item xs={1}>
                      <IconButton onClick={() => handleRemove(field, type)}>
                        <RemoveCircleOutline color="error" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              );
            })}
            <Grid item display="flex" justifyContent="center">
              <Button variant="outlined" onClick={() => handleAdd(field)}>
                Agregar registro +
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default MultipleValues;
