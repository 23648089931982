import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  deleteDoc,
  addDoc,
} from 'firebase/firestore';
import { db } from 'firebaseApp';

const getAll = async () => {
  const materials = await getDocs(collection(db, 'materials'));
  return materials.docs.map((material) => ({
    ...material.data(),
    id: material.id,
  }));
};

const getOne = async (id) => {
  const docuRef = doc(db, `materials/${id}`);
  const docu = await getDoc(docuRef);
  const material = { ...docu.data(), id: docu.id };
  return material;
};

const search = async (search) => {
  const colRef = collection(db, 'materials');
  const queryByName = query(
    colRef,
    where('name', '>=', search.toLowerCase()),
    where('name', '<=', search.toLowerCase() + '\uf8ff'),
  );

  const materialArray = (await getDocs(queryByName)).docs;
  const materials = materialArray.map((material) => ({
    ...material.data(),
    id: material.id,
  }));
  return materials;
};

const create = async (data) => {
  addDoc(collection(db, 'materials'), {
    name: data.name.toLowerCase(),
    colors: data.colors,
  });
  return { status: 200, message: 'Material creado con éxito' };
};

const update = async (data) => {
  const docuRef = doc(db, 'materials', data.id);
  await updateDoc(docuRef, {
    name: data.name.toLowerCase(),
    colors: data.colors,
  });
};

const remove = async (id) => await deleteDoc(doc(db, 'materials', id));

const MaterialService = {
  getAll,
  getOne,
  search,
  create,
  update,
  remove,
};
export default MaterialService;
