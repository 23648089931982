import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { auth } from 'firebaseApp';

const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async () =>
  signInWithPopup(auth, googleProvider);

export const signInWithEmail = async (email, password) =>
  signInWithEmailAndPassword(auth, email, password);

export const logOut = () => auth.signOut();
