import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NotImage from './NotImage.png';
import Img from './img.jpg';
import { styled } from '@mui/material';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';
// @ts-ignore
const TextTitle = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: props.fontWeight || 300,
  color: props.color || '#5B5B5B',
  textAlign: props.textAlign || 'center',
  textTransform: 'none',
  fontSize: props.fontSize || '17px',
}));

const CardStandarProduct = (props) => {
  const navigate = useNavigate();
  const { item, isEdit, isPreview, setItemForEdit, setItemForDelete } = props;

  return (
    <Card sx={{ maxWidth: 345, maxHeight: 425 }}>
      <CardMedia
        component="img"
        height="240"
        // image={Img}
        image={item.imageUrl ? item.imageUrl : NotImage}
        style={{ backgroundColor: 'white', cursor: 'pointer' }}
        onClick={() =>
          navigate('/standardPurchase', {
            state: {
              objeto: item,
            },
          })
        }
      />
      <CardContent
        style={{ backgroundColor: 'white', cursor: 'pointer' }}
        onClick={() =>
          navigate('/standardPurchase', {
            state: {
              objeto: item,
            },
          })
        }
      >
        <TextTitle>{item.name}</TextTitle>
        <TextTitle>
          {item.color} {item.base} x {item.altura}{' '}
        </TextTitle>
        <Grid
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <TextTitle
            color={'red'}
            fontWeight={'600'}
            fontSize={'22px'}
            textAlign={'start'}
          >
            $ {item.price}
          </TextTitle>
          {/* {item.priceOff && (
            <TextTitle
              fontWeight={'400'}
              fontSize={'16px'}
              textAlign={'right'}
              style={{ textDecoration: 'line-through' }}
            >
              $ {item.priceOff}
            </TextTitle>
          )} */}
          {item.priceOff !== 0 && (
            <TextTitle
              fontWeight={'400'}
              fontSize={'16px'}
              textAlign={'right'}
              style={{ textDecoration: 'line-through' }}
            >
              $ {item.priceOff}
            </TextTitle>
          )}
        </Grid>
      </CardContent>
      {item.stock == false && (
        <CardContent style={{ display: 'flex', justifyContent: 'center' }}>
          <NotInterestedIcon style={{ color: 'red', marginRight: '5px' }} />
          <Typography color={'red'}>Sin stock</Typography>
        </CardContent>
      )}
      {!isPreview && isEdit ? (
        <CardActions
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#D9D9D9',
          }}
        >
          {/* <Button size="small">Vista rapida</Button> */}
          {isEdit ? (
            <Button size="small" onClick={() => setItemForEdit(item)}>
              Editar
            </Button>
          ) : null}
          {isEdit ? (
            <Button size="small" onClick={() => setItemForDelete(item)}>
              Eliminar
            </Button>
          ) : null}
        </CardActions>
      ) : null}
    </Card>
  );
};

export default CardStandarProduct;
