import React from 'react';
import { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  display: 'flex',
  width: '60%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const Dropzone = ({ image, loadImage }) => {
  const options = {
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      loadImage(file[0]);
    },
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone(options);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <div>
      <div {...getRootProps({ style })}>
        {image ? (
          <div
            style={{
              border: '1px solid #eaeaea',
              padding: 4,
            }}
            key={image?.name}
          >
            <img
              alt={image?.name}
              src={image?.preview}
              width={50}
              height={50}
              onLoad={() => {
                URL.revokeObjectURL(image?.preview);
              }}
            />
          </div>
        ) : (
          <div>
            <input {...getInputProps()} />
            <p>{`Cargar imagen`}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropzone;
