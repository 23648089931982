import React from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import Dropzone from './Dropzone';

const ColorTable = ({ rows, deleteColor, loadImage }) => {
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{ border: '1px solid #E5E5E5' }}
    >
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              sx={({ palette }) => ({ color: palette.primary.main })}
            >
              Color
            </TableCell>
            <TableCell
              align="left"
              sx={({ palette }) => ({ color: palette.primary.main })}
            >
              Imagen
            </TableCell>
            <TableCell
              align="right"
              sx={({ palette }) => ({ color: palette.primary.main })}
            >
              Acción
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <TableRow key={idx}>
              <TableCell align="left" width={'70%'}>
                <TextField variant="outlined" fullWidth />
              </TableCell>
              <TableCell align="right">
                <Dropzone
                  image={row.image}
                  loadImage={(image) => loadImage(image, idx)}
                />
              </TableCell>
              <TableCell align="right">
                <IconButton onClick={() => deleteColor(idx)}>
                  <DeleteOutline color="error" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ColorTable;
