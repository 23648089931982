import {
  doc,
  getDocs,
  collection,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from 'firebaseApp';

const getAll = async () => {
  const data = await getDocs(collection(db, 'variablesGanancia'));
  return data.docs.map((element) => ({ ...element.data(), id: element.id }));
};

const getCollectionProducts = async () => {
  let productsArray = [];

  try {
    const querySnapshot = await getDocs(collection(db, 'variablesGanancia'));
    querySnapshot.forEach((doc) => {
      let json = JSON.parse(JSON.stringify(doc.data()));
      json.id = doc.id;
      productsArray.push(json);
    });
  } catch (error) {
    return { status: error };
  }

  return productsArray;
};

export const updateVariable = async (bodyProduct) => {
  try {
    const docRef = doc(db, 'variablesGanancia/', bodyProduct.id);
    await updateDoc(docRef, bodyProduct);
    return { status: 'Variable actualizada!' };
  } catch (error) {
    return { status: error };
  }
};

const remove = async (id) => await deleteDoc(doc(db, 'variablesGanancia', id));

const variablesGananciaService = {
  getAll,
  getCollectionProducts,
  updateVariable,
  remove,
};
export default variablesGananciaService;
