import React, { useEffect, useState } from 'react';
import { Grid, Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { DeleteForever, Edit } from '@mui/icons-material';
import {
  Table,
  Search,
  BasicModal,
  DialogModal,
  MaterialForm,
} from 'components';
import { MaterialService } from 'services';

const mockData = [
  {
    colors: [
      {
        id: 'cFIRS4cVjPQfmAKbWQN7',
        name: 'azul',
        image:
          'https://i.pinimg.com/originals/4c/44/b8/4c44b8de8a859a119a84512df8b506b2.jpg',
      },
      {
        id: 'URTGRIjMGztmjggjuIFA',
        name: 'rojo',
        image:
          'https://img.freepik.com/foto-gratis/tarjeta-plantilla-informe-papel-suave_1258-167.jpg?w=1380&t=st=1685838323~exp=1685838923~hmac=95de3e6a8130f79bbd0f63f8123c4a80b4770d24e6d7fda5ae3e5450fca7d2eb',
      },
    ],
    name: 'tela',
    id: 'ClPItuXtRqxO37UVmZyD',
  },
  {
    colors: [
      {
        id: 'URTGRIjMGztmjggjuIFA',
        name: 'verde',
        image:
          'https://i.pinimg.com/originals/4c/44/b8/4c44b8de8a859a119a84512df8b506b2.jpg',
      },
    ],
    name: 'plastico',
    id: 'SyZCpwtuePklyFCfXrpd',
  },
];

const columnsArray = [
  {
    field: 'id',
    headerName: 'ID',
    width: 200,
    headerClassName: 'header',
  },
  {
    field: 'name',
    headerName: 'Material',
    width: 200,
    flex: 2,
    headerClassName: 'header',
  },
  {
    field: 'colors',
    headerName: 'Colores',
    width: 200,
    flex: 2,
    headerClassName: 'header',
    renderCell: ({ row }) => (
      <>
        {row.colors.map((color) => (
          <div
            key={color.id}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: 10,
            }}
          >
            <span>{color.name}</span>
            <img
              src={color.image}
              height="20px"
              width="20px"
              style={{ borderRadius: 10, marginLeft: 5 }}
            />
          </div>
        ))}
      </>
    ),
  },
];

const initialValues = {
  id: '',
  name: '',
  colors: [],
};

const Materials = () => {
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [material, setMaterial] = useState(initialValues);

  const getAllMaterials = async () => {
    const materials = await MaterialService.getAll();
    setData(materials);
  };

  useEffect(() => {
    getAllMaterials();
  }, []);

  const handleSearch = () => {
    if (search) {
      setIsLoading(true);
      MaterialService.search(search)
        .then((res) => setData(res))
        .finally(() => setIsLoading(false));
    } else {
      getAllMaterials();
    }
  };

  const handleSubmit = (data, resetForm) => {
    if (data.id) {
      MaterialService.update(data).then(() => {
        handleCloseModal();
        resetForm();
        handleSearch();
      });
    } else {
      MaterialService.create(data).then(() => {
        handleCloseModal();
        resetForm();
        handleSearch();
      });
    }
  };

  const handleDelete = () => {
    MaterialService.remove(material?.id).finally(() => {
      setMaterial(initialValues);
      setShowAlertModal(false);
      getAllMaterials();
    });
  };

  const handleCloseModal = () => {
    setShowFormModal(false);
    setMaterial(initialValues);
  };

  const handleCloseAlertModal = () => {
    setMaterial(initialValues);
    setShowAlertModal(false);
  };

  const columns = [
    ...columnsArray,
    {
      field: 'actions',
      headerName: 'Acciones',
      headerClassName: 'header',
      sortable: false,
      minWidth: 80,
      align: 'center',
      renderCell: ({ row }) => (
        <>
          <GridActionsCellItem
            label="editar"
            onClick={() => {
              setMaterial(row);
              setShowFormModal(true);
            }}
            icon={
              <Tooltip title="Editar material">
                <Edit color="primary" />
              </Tooltip>
            }
          />
          <GridActionsCellItem
            label="eliminar"
            onClick={() => {
              setMaterial(row);
              setShowAlertModal(true);
            }}
            icon={
              <Tooltip title="Eliminar material">
                <DeleteForever color="primary" />
              </Tooltip>
            }
          />
        </>
      ),
    },
  ];

  return (
    <Grid container direction="column">
      <Grid item>
        <Search
          title="Buscar color"
          placeholder="Buscar por nombre del color"
          search={search}
          setSearch={setSearch}
          onSubmit={handleSearch}
        />
        <Table
          title="Resultados de la búsqueda"
          values={mockData}
          columns={columns}
          isLoading={isLoading}
          buttonTitle="Crear nuevo color"
          buttonAction={() => setShowFormModal(true)}
        />
      </Grid>
      <BasicModal open={showFormModal} onClose={handleCloseModal}>
        <MaterialForm onSubmit={handleSubmit} defaultValues={material} />
      </BasicModal>
      <DialogModal
        open={showAlertModal}
        onClose={handleCloseAlertModal}
        onSubmit={handleDelete}
        title="Eliminar color"
        description="Esta acción es irreversible, ¿desea continuar?"
        colorButton="error"
      />
    </Grid>
  );
};

export default Materials;
