// reducers/index.js
const initialState = {
  pedido: [],
};

function rootReducer(state = initialState, action) {
  // console.log('state', state);
  // console.log('action', action);
  // console.log(state.pedido.filter((item) => item !== action.payload));
  switch (action.type) {
    case 'ADD_ITEM':
      return {
        ...state,
        pedido: [...state.pedido, action.payload],
      };
    case 'DELETE_ITEM':
      return {
        ...state,
        pedido: state.pedido.filter((item) => item !== action.payload),
      };
    default:
      return state;
  }
}

export default rootReducer;
