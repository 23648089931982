import {
  collection,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  getDocs,
  addDoc,
} from 'firebase/firestore';
import { db } from '../firebaseApp';

export const getCollectionStandarProducts = async () => {
  let productsArray = [];

  try {
    const querySnapshot = await getDocs(collection(db, 'StandarProducts'));
    querySnapshot.forEach((doc) => {
      let json = JSON.parse(JSON.stringify(doc.data()));
      json.id = doc.id;
      productsArray.push(json);
    });
  } catch (error) {
    return { status: error };
  }

  return productsArray;
};

export const addStandarProduct = async (bodyProduct) => {
  try {
    const docRef = await addDoc(collection(db, 'StandarProducts'), bodyProduct);
    const docRefForGet = doc(db, 'StandarProducts', docRef.id);
    const docSnap = await getDoc(docRefForGet);
    let product = {};
    if (docSnap.exists()) {
      product = docSnap.data();
      product.id = docRef.id;
    } else {
      return { status: 'Producto no creado!' };
    }
    return { status: 'Producto creado!', product: product };
  } catch (error) {
    return { status: error };
  }
};

export const updateStandarProduct = async (bodyProduct) => {
  try {
    // throw 'myException'; // generates an exception
    const docRef = doc(db, 'StandarProducts/', bodyProduct.id);
    await updateDoc(docRef, bodyProduct);
    return { status: 'Producto actualizado!' };
  } catch (error) {
    return { status: error };
  }
};

export const deleteStandarProduct = async (product) => {
  try {
    await deleteDoc(doc(db, 'StandarProducts', product.id));
    return { status: 'Producto eliminado!' };
  } catch (error) {
    return { status: error };
  }
};
