import React from 'react';
import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';

const Table = ({
  values,
  columns,
  isLoading,
  onRowClick = null,
  title,
  buttonTitle,
  buttonAction,
}) => (
  <div style={{ height: '100%' }}>
    <Paper
      elevation={0}
      style={{ borderRadius: 0, backgroundColor: 'white', padding: 24 }}
    >
      <Grid container direction="column" spacing={2} style={{ height: '100%' }}>
        {title && (
          <Grid item>
            <Typography fontSize={20} color="primary">
              {title}
            </Typography>
          </Grid>
        )}
        <Grid item xs style={{ minHeight: '370px' }}>
          <DataGrid
            rows={values}
            loading={isLoading}
            columns={columns}
            localeText={{
              ...esES.components.MuiDataGrid.defaultProps.localeText,
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No hay datos por mostrar
                </Stack>
              ),
            }}
            onCellClick={onRowClick}
            sx={{
              '.header': {
                color: '#006873',
              },
            }}
          />
        </Grid>
        {buttonAction && (
          <Grid item display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={buttonAction}>
              {buttonTitle}
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  </div>
);

export default Table;
