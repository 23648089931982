import React, { useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import ColorTable from 'pages/BackOffice/Materials/ColorTable';

const initialValues = {
  id: '',
  name: '',
  colors: [],
};

const MaterialForm = ({ onSubmit, defaultValues }) => {
  const [formData, setFormData] = useState(defaultValues);

  const resetFormData = () => setFormData(initialValues);

  const handleSubmit = () => onSubmit(formData, resetFormData);

  const addColor = () => {
    setFormData({
      ...formData,
      colors: [...formData.colors, { name: '', image: null }],
    });
  };

  const deleteColor = (idx) => {
    const newColorList = formData.colors.filter(
      (_elem, index) => index !== idx,
    );
    setFormData({ ...formData, colors: newColorList });
  };

  const onLoadImage = (image, index) => {
    const newColorList = formData.colors.map((elem, idx) => {
      if (index === idx)
        return {
          ...elem,
          image,
        };
      return elem;
    });
    setFormData({ ...formData, colors: newColorList });
  };

  console.log('formdata', formData);

  return (
    <Grid container direction="column" spacing={2} paddingX={'10%'}>
      <Grid item>
        <Typography variant="h5" textAlign="center">
          {defaultValues.id ? 'Editar material' : 'Crear material'}
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          label="Material"
          fullWidth
        />
      </Grid>
      <Grid item container justifyContent="space-between" alignItems="end">
        <Grid item>
          <Typography>Colores</Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={addColor}>
            + Agregar Color
          </Button>
        </Grid>
      </Grid>
      {formData.colors.length ? (
        <Grid item>
          <ColorTable
            rows={formData.colors}
            deleteColor={deleteColor}
            loadImage={onLoadImage}
          />
        </Grid>
      ) : (
        <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>Debe agregar al menos un color</Typography>
        </Grid>
      )}
      <Grid item display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          disabled={!(formData.colors.length && formData.name)}
          onClick={handleSubmit}
        >
          Confirmar
        </Button>
      </Grid>
    </Grid>
  );
};

export default MaterialForm;
