import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { MaterialService } from 'services';

const schema = yup.object({
  name: yup.string().required('El nombre de la categoría es requerido'),
  materials: yup
    .array()
    .min(1, 'Debe seleccionar al menos un material')
    .required('Los materiales son requeridos'),
});

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const CategoryForm = ({ onSubmit, defaultValues }) => {
  const [materials, setMaterials] = useState([]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const submitFn = (data) => onSubmit(data, reset);

  useEffect(() => {
    MaterialService.getAll().then((res) => {
      setMaterials(res);
    });
  }, []);

  return (
    <Grid
      component="form"
      onSubmit={handleSubmit(submitFn)}
      container
      direction="column"
      spacing={2}
      paddingX={'10%'}
    >
      <Grid item>
        <Typography variant="h5" textAlign="center">
          {defaultValues.id ? 'Editar categoría' : 'Crear categoría'}
        </Typography>
      </Grid>
      <Grid item>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Categoría"
              fullWidth
              error={Boolean(errors.name)}
              helperText={errors.name?.message.toString()}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          name="materials"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              multiple
              noOptionsText="No existen coincidencias"
              options={materials}
              disableCloseOnSelect
              value={value}
              getOptionLabel={(option) => option.name}
              onChange={(_e, newValues) => onChange(newValues)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    label="Materiales"
                    placeholder="Agregar material"
                    error={Boolean(errors.materials)}
                    helperText={errors.materials?.message.toString()}
                  />
                </>
              )}
            />
          )}
        />
      </Grid>
      <Grid item display="flex" justifyContent="flex-end">
        <Button type="submit" variant="contained">
          Confirmar
        </Button>
      </Grid>
    </Grid>
  );
};

export default CategoryForm;
