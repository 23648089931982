import React, { useState, useEffect } from 'react';
import { Grid, styled, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const DecoHomeFilters = ({
  catalogue,
  categoriesStandarProducts,
  setFilterCatalogue,
  filterCategorieFromDialog,
}) => {
  // console.log('DecoHomeFilter');

  const [filterBy, setFilterBy] = useState('all');

  useEffect(() => {
    filterProducts(filterBy);
  }, [catalogue]);

  // console.log('filterCategorieFromDialog:', filterCategorieFromDialog);

  useEffect(() => {
    // console.log('ACOMODAR :', categoriesStandarProducts);
    categoriesStandarProducts.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    // console.log('ACOMODAR 2:', categoriesStandarProducts);
  }, []);

  useEffect(() => {
    if (filterCategorieFromDialog.length > 0) {
      setFilterBy(filterCategorieFromDialog);
      filterProducts(filterCategorieFromDialog);
      // setText(filterCategorieFromDialog);
    }
  }, [filterCategorieFromDialog]);

  const handleChangeFilters = (event) => {
    event.preventDefault();

    setFilterBy(event.target.value);
    filterProducts(event.target.value);
  };

  const filterProducts = async (filterId) => {
    if (filterId == 'all') {
      setFilterCatalogue(catalogue);
      return;
    }
    if (catalogue.length > 0) {
      const result = catalogue.filter(
        (product) => product.categorie == filterId,
      );

      setFilterCatalogue(result);
    }
  };

  const TextTitle = styled(Typography)((props) => ({
    position: 'relative',
    fontFamily: 'Inter',
    fontWeight: 300,
    textAlign: 'center',
    color: '#5B5B5B',
    textTransform: 'none',
    fontsize: '12px',
  }));

  return (
    <Grid
      container
      style={{ backgroundColor: '#D9D9D9', padding: '5%', marginLeft: '5px' }}
    >
      <Grid
        item
        container
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <TextTitle
          fontSize={'18px'}
          style={{ color: '#185D22', fontWeight: '300' }}
        >
          Filtrar
        </TextTitle>

        <KeyboardArrowDownIcon style={{ color: '#185D22' }} />
      </Grid>
      <Grid
        item
        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
      >
        <Grid item lg={12} sm={12} xs={12}>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
              key={'all'}
              value={'all'}
              control={
                <Checkbox
                  checked={filterBy == 'all'}
                  name={'all'}
                  onChange={handleChangeFilters}
                />
              }
              label={
                <Typography fontSize={'15px'} fontWeight={500}>
                  {'Todos'}
                </Typography>
              }
              style={{
                position: 'relative',
                fontFamily: 'Inter',
                fontWeight: 300,
                textAlign: 'center',
                color: '#5B5B5B',
                textTransform: 'none',
              }}
            />

            <FormControlLabel
              value={'categorie.id'}
              control={
                <Checkbox
                  name={'categorie.name'}
                  onChange={handleChangeFilters}
                />
              }
              label={
                <Typography fontSize={'15px'} fontWeight={500}>
                  {'Puff Bahamas'}
                </Typography>
              }
              style={{
                position: 'relative',
                fontFamily: 'Inter',
                fontWeight: 300,
                textAlign: 'center',
                color: '#5B5B5B',
                textTransform: 'none',
              }}
            />

            <FormControlLabel
              value={'categorie.id'}
              control={
                <Checkbox
                  name={'Puff Jamaica'}
                  onChange={handleChangeFilters}
                />
              }
              label={
                <Typography fontSize={'15px'} fontWeight={500}>
                  {'Puff Jamaica'}
                </Typography>
              }
              style={{
                position: 'relative',
                fontFamily: 'Inter',
                fontWeight: 300,
                textAlign: 'center',
                color: '#5B5B5B',
                textTransform: 'none',
              }}
            />

            <FormControlLabel
              value={'categorie.id'}
              control={
                <Checkbox
                  name={'categorie.name'}
                  onChange={handleChangeFilters}
                />
              }
              label={
                <Typography fontSize={'15px'} fontWeight={500}>
                  {'Puff Aruba'}
                </Typography>
              }
              style={{
                position: 'relative',
                fontFamily: 'Inter',
                fontWeight: 300,
                textAlign: 'center',
                color: '#5B5B5B',
                textTransform: 'none',
              }}
            />

            <FormControlLabel
              value={'categorie.id'}
              control={
                <Checkbox
                  name={'categorie.name'}
                  onChange={handleChangeFilters}
                />
              }
              label={
                <Typography fontSize={'15px'} fontWeight={500}>
                  {'Colchoneta Ibiza'}
                </Typography>
              }
              style={{
                position: 'relative',
                fontFamily: 'Inter',
                fontWeight: 300,
                textAlign: 'center',
                color: '#5B5B5B',
                textTransform: 'none',
              }}
            />

            {/* {categoriesStandarProducts.length > 0 &&
              categoriesStandarProducts.map((categorie) => (
                <FormControlLabel
                  key={categorie.id}
                  // onClick={() => handleChangeFilters}
                  value={categorie.id}
                  control={
                    <Checkbox
                      checked={filterBy == categorie.id}
                      name={categorie.name}
                      onChange={handleChangeFilters}
                    />
                  }
                  label={
                    <Typography fontSize={'15px'} fontWeight={500}>
                      {categorie.name}
                    </Typography>
                  }
                  style={{
                    position: 'relative',
                    fontFamily: 'Inter',
                    fontWeight: 300,
                    textAlign: 'center',
                    color: '#5B5B5B',
                    textTransform: 'none',
                  }}
                />
              ))} */}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
