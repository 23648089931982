import React, { useEffect, useState } from 'react';
import { Grid, Tooltip } from '@mui/material';
import Search from 'components/Search';
import Table from 'components/Table';
import { columnsArray, initialValues } from './constants';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { DeleteForever, Edit } from '@mui/icons-material';
import {
  getUsers,
  registerUser,
  searchUsers,
  updateUser,
  deleteUser,
} from 'services/users';
import BasicModal from 'components/BasicModal';
import UserForm from 'components/UserForm';
import DialogModal from 'components/DialogModal';

const Users = () => {
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [user, setUser] = useState(initialValues);

  const getAllUsers = () => getUsers().then((res) => setData(res));

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleSearch = () => {
    if (search) {
      setIsLoading(true);
      searchUsers(search)
        .then((res) => setData(res))
        .finally(() => setIsLoading(false));
    } else {
      getAllUsers();
    }
  };

  const handleSubmit = (data, resetForm) => {
    if (data.id) {
      updateUser(data).then(() => {
        handleCloseModal();
        resetForm();
        handleSearch();
      });
    } else {
      registerUser(data).then(() => {
        handleCloseModal();
        resetForm();
        handleSearch();
      });
    }
  };

  const handleDeleteUser = () => {
    deleteUser(user?.id).finally(() => {
      setUser(initialValues);
      setShowAlertModal(false);
      getAllUsers();
    });
  };

  const handleCloseModal = () => {
    setShowFormModal(false);
    setUser(initialValues);
  };

  const handleCloseAlertModal = () => {
    setUser(initialValues);
    setShowAlertModal(false);
  };

  const columns = [
    ...columnsArray,
    {
      field: 'actions',
      headerName: 'Acciones',
      headerClassName: 'header',
      sortable: false,
      minWidth: 80,
      align: 'center',
      renderCell: (params) => (
        <>
          <GridActionsCellItem
            label="editar"
            onClick={() => {
              setUser(params.row);
              setShowFormModal(true);
            }}
            icon={
              <Tooltip title="Editar usuario">
                <Edit color="primary" />
              </Tooltip>
            }
          />
          <GridActionsCellItem
            label="eliminar"
            onClick={() => {
              setUser(params.row);
              setShowAlertModal(true);
            }}
            icon={
              <Tooltip title="Eliminar usuario">
                <DeleteForever color="primary" />
              </Tooltip>
            }
          />
        </>
      ),
    },
  ];

  return (
    <Grid container direction="column">
      <Grid item>
        <Search
          title="Buscar usuario"
          placeholder="Buscar por Apellido, Nombre o Rol"
          search={search}
          setSearch={setSearch}
          onSubmit={handleSearch}
        />
        <Table
          title="Resultados de la búsqueda"
          values={data}
          columns={columns}
          isLoading={isLoading}
          buttonTitle="Crear nuevo usuario"
          buttonAction={() => setShowFormModal(true)}
        />
      </Grid>
      <BasicModal open={showFormModal} onClose={handleCloseModal}>
        <UserForm onSubmit={handleSubmit} defaultValues={user} />
      </BasicModal>
      <DialogModal
        open={showAlertModal}
        onClose={handleCloseAlertModal}
        onSubmit={handleDeleteUser}
        title="Eliminar usuario"
        description="Esta acción es irreversible, ¿desea continuar?"
        colorButton="error"
      />
    </Grid>
  );
};

export default Users;
