import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  deleteDoc,
  addDoc,
} from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { db, storage } from 'firebaseApp';

const getAll = async () => {
  const data = await getDocs(collection(db, 'cortinasCotizadas'));
  return data.docs.map((element) => ({ ...element.data(), id: element.id }));
};

const getCollectionProducts = async () => {
  let productsArray = [];

  try {
    const querySnapshot = await getDocs(collection(db, 'cortinasCotizadas'));
    querySnapshot.forEach((doc) => {
      let json = JSON.parse(JSON.stringify(doc.data()));
      json.id = doc.id;
      productsArray.push(json);
    });
  } catch (error) {
    return { status: error };
  }

  return productsArray;
};

export const updateVariable = async (bodyProduct) => {
  try {
    const docRef = doc(db, 'cortinasCotizadas/', bodyProduct.id);
    await updateDoc(docRef, bodyProduct);
    return { status: 'Variable actualizada!' };
  } catch (error) {
    return { status: error };
  }
};

const remove = async (id) => await deleteDoc(doc(db, 'cortinasCotizadas', id));

const create = async (data) => {
  console.log('create:', data);
  const docRef = await addDoc(collection(db, 'cortinasCotizadas'), {
    type: data.type,
    data: data.cortina,
    date: new Date().toLocaleDateString('en-GB'),
    state: data.state,
  });
  console.log('docRef:', docRef);

  const docRefForGet = doc(db, 'cortinasCotizadas', docRef.id);
  const docSnap = await getDoc(docRefForGet);
  let cortina = {};
  if (docSnap.exists()) {
    cortina = docSnap.data();
    cortina.id = docRef.id;
  } else {
    return { status: 'Producto no creado!' };
  }
  return { status: 'Cortina creada!', cortina: cortina };

  // return { status: 200, message: 'Cortina cotizada creada con éxito' };
};

const search = async (search) => {
  console.log('search:', search.toLowerCase());
  const colRef = collection(db, 'cortinasCotizadas');
  const queryByName = query(
    colRef,
    where('ID', '>=', search.toLowerCase()),
    where('ID', '<=', search.toLowerCase() + '\uf8ff'),
  );
  getDocs(collection(db, 'cortinasCotizadas'));
  const quotedCurtainsArray = (await getDocs(queryByName)).docs;
  console.log('quotedCurtainsArray:', quotedCurtainsArray);
  const quotedCurtains = quotedCurtainsArray.map((product) => ({
    ...product.data(),
    id: product.id,
  }));
  return quotedCurtains;
};

const cortinasCotizadasService = {
  create,
  getAll,
  getCollectionProducts,
  updateVariable,
  remove,
  search,
};
export default cortinasCotizadasService;
