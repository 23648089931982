export const columnsArray = [
  {
    field: 'id',
    headerName: 'ID',
    width: 200,
    headerClassName: 'header',
  },
  {
    field: 'name',
    headerName: 'Categoría',
    width: 200,
    flex: 2,
    headerClassName: 'header',
  },
  {
    field: 'materials',
    headerName: 'Materiales',
    width: 200,
    headerClassName: 'header',
    valueGetter: ({ row }) =>
      row.materials.map((material) => material.name).join(', '),
  },
];

export const initialValues = {
  id: '',
  name: '',
  materials: [],
};
