import React from 'react';
import { Contact } from 'components';
import { Grid } from '@mui/material';

const ContactPage = () => {
  return (
    <Grid item xs sx={{ marginX: 8 }}>
      <Contact />
    </Grid>
  );
};

export default ContactPage;
