import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, styled, Typography, Button } from '@mui/material';

//Imagenes
import dealersImg1 from './assets/dealersImg1.jpg';

// @ts-ignore
const Text = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: 400,
  textAlign: 'center',
  color: 'black',
  textTransform: 'none',
}));

const TextBtn = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Rubik',
  fontWeight: 500,
  textAlign: 'center',
  color: 'white',
  textTransform: 'none',
}));

const Btn = styled(Button)(({ theme }) => ({
  marginTop: '25px',
  backgroundColor: '#185D22',
  borderRadius: '9px',
  color: 'white',
  fontSize: '12px',
  width: '10rem',
  '&:hover': {
    backgroundColor: '#185D22',
    boxShadow: 'none',
  },
  [theme.breakpoints.down('md')]: {
    width: 100,
    height: 60,
  },
}));

const Dealers = () => {
  const navigate = useNavigate();

  return (
    <Grid container direction="column">
      <Grid item>
        <img
          className="img"
          width={'100%'}
          height={'400px'}
          src={dealersImg1}
          alt=""
        />
      </Grid>

      <Grid item marginBottom={'20px'}>
        <Text mt={10} marginLeft={'20%'} marginRight={'20%'}>
          Si querés integrar nuestra red de distribuidores a nivel nacional
          accediendo
        </Text>
        <Text marginLeft={'20%'} marginRight={'20%'}>
          a un producto de excelente calidad y versatilidad, con garantía y
          entrega en
        </Text>
        <Text marginLeft={'20%'} marginRight={'20%'}>
          todo el país, completá el formulario o contactanos por whatsapp.
        </Text>
      </Grid>

      <Grid item display={'flex'} justifyContent={'center'}>
        <Btn onClick={() => navigate('/contact')}>
          <TextBtn>Contacto</TextBtn>
        </Btn>
      </Grid>
    </Grid>
  );
};

export default Dealers;
