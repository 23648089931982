import {
  collection,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  getDocs,
  addDoc,
} from 'firebase/firestore';
import { db } from '../firebaseApp';

export const getCollectionProducts = async () => {
  let productsArray = [];

  try {
    const querySnapshot = await getDocs(collection(db, 'Products'));
    querySnapshot.forEach((doc) => {
      let json = JSON.parse(JSON.stringify(doc.data()));
      json.id = doc.id;
      productsArray.push(json);
    });
  } catch (error) {
    return { status: error };
  }

  return productsArray;
};

export const getCollectionCatalogueProducts = async () => {
  let productsArray = [];

  try {
    const querySnapshot = await getDocs(collection(db, 'CatalogueProducts'));
    querySnapshot.forEach((doc) => {
      let json = JSON.parse(JSON.stringify(doc.data()));
      json.id = doc.id;
      productsArray.push(json);
    });
  } catch (error) {
    return { status: error };
  }

  return productsArray;
};

export const addProduct = async (bodyProduct) => {
  try {
    const docRef = await addDoc(collection(db, 'Products'), bodyProduct);
    const docRefForGet = doc(db, 'Products', docRef.id);
    const docSnap = await getDoc(docRefForGet);
    let product = {};
    if (docSnap.exists()) {
      product = docSnap.data();
      product.id = docRef.id;
    } else {
      return { status: 'Producto no creado!' };
    }
    return { status: 'Producto creado!', product: product };
  } catch (error) {
    return { status: error };
  }
};

export const addCatalogueProduct = async (bodyProduct) => {
  try {
    const docRef = await addDoc(
      collection(db, 'CatalogueProducts'),
      bodyProduct,
    );
    const docRefForGet = doc(db, 'CatalogueProducts', docRef.id);
    const docSnap = await getDoc(docRefForGet);
    let product = {};
    if (docSnap.exists()) {
      product = docSnap.data();
      product.id = docRef.id;
    } else {
      return { status: 'Producto no creado!' };
    }
    return { status: 'Producto creado!', product: product };
  } catch (error) {
    return { status: error };
  }
};

export const updateProduct = async (bodyProduct) => {
  try {
    const docRef = doc(db, 'Products/', bodyProduct.id);
    await updateDoc(docRef, bodyProduct);
    return { status: 'Producto actualizado!' };
  } catch (error) {
    return { status: error };
  }
};

export const updateCatalogueProduct = async (bodyProduct) => {
  try {
    // throw 'myException'; // generates an exception
    const docRef = doc(db, 'CatalogueProducts/', bodyProduct.id);
    await updateDoc(docRef, bodyProduct);
    return { status: 'Producto actualizado!' };
  } catch (error) {
    return { status: error };
  }
};

export const deleteProduct = async (product) => {
  try {
    await deleteDoc(doc(db, 'Products', product.id));
    return { status: 'Producto eliminado!' };
  } catch (error) {
    return { status: error };
  }
};

export const deleteCatalogueProduct = async (product) => {
  try {
    await deleteDoc(doc(db, 'CatalogueProducts', product.id));
    return { status: 'Producto eliminado!' };
  } catch (error) {
    return { status: error };
  }
};

/////////////////////////

export const addProductNew = async (bodyProduct) => {
  try {
    const docRef = await addDoc(collection(db, 'ProductsNew'), bodyProduct);
    const docRefForGet = doc(db, 'ProductsNew', docRef.id);
    const docSnap = await getDoc(docRefForGet);
    let product = {};
    if (docSnap.exists()) {
      product = docSnap.data();
      product.id = docRef.id;
    } else {
      return { status: 'Producto no creado!' };
    }
    return { status: 'Producto creado!', product: product };
  } catch (error) {
    return { status: error };
  }
};
