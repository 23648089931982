import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  Switch
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { db } from '../../firebaseApp';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';

const DescuentosPage = () => {
  const [descuentos, setDescuentos] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentDescuento, setCurrentDescuento] = useState({ id: null, codigo: '', cantidad: 0, activo: true });
  const [alert, setAlert] = useState({ open: false, severity: '', message: '' });

  useEffect(() => {
    const fetchDescuentos = async () => {
      const querySnapshot = await getDocs(collection(db, 'CodigosDescuentos'));
      const descuentosData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDescuentos(descuentosData);
    };

    fetchDescuentos();
  }, []);

  const handleOpen = () => {
    setCurrentDescuento({ id: null, codigo: '', cantidad: 0, activo: true });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveDescuento = async () => {
    try {
      if (currentDescuento.id) {
        // Update existing discount
        const descuentoRef = doc(db, 'CodigosDescuentos', currentDescuento.id);
        await updateDoc(descuentoRef, {
          codigo: currentDescuento.codigo,
          cantidad: currentDescuento.cantidad,
          activo: currentDescuento.activo
        });
        setAlert({ open: true, severity: 'success', message: 'Descuento actualizado con éxito' });
      } else {
        // Add new discount
        await addDoc(collection(db, 'CodigosDescuentos'), {
          codigo: currentDescuento.codigo,
          cantidad: currentDescuento.cantidad,
          activo: currentDescuento.activo
        });
        setAlert({ open: true, severity: 'success', message: 'Descuento agregado con éxito' });
      }
      setOpen(false);
      window.location.reload(); // Refresh page to show updated discounts
    } catch (error) {
      setAlert({ open: true, severity: 'error', message: 'Error al guardar el descuento' });
    }
  };

  const handleEditDescuento = (descuento) => {
    setCurrentDescuento(descuento);
    setOpen(true);
  };

  const handleToggleDescuento = async (descuentoId, currentState) => {
    try {
      const descuentoRef = doc(db, 'CodigosDescuentos', descuentoId);
      await updateDoc(descuentoRef, { activo: !currentState });
      setAlert({ open: true, severity: 'success', message: 'Estado del descuento actualizado' });
      window.location.reload(); // Refresh page to show updated discounts
    } catch (error) {
      setAlert({ open: true, severity: 'error', message: 'Error al actualizar el estado del descuento' });
    }
  };

  const handleDeleteDescuento = async (descuentoId) => {
    try {
      const descuentoRef = doc(db, 'CodigosDescuentos', descuentoId);
      await deleteDoc(descuentoRef);
      setAlert({ open: true, severity: 'success', message: 'Descuento eliminado con éxito' });
      window.location.reload(); // Refresh page to show updated discounts
    } catch (error) {
      setAlert({ open: true, severity: 'error', message: 'Error al eliminar el descuento' });
    }
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Códigos de Descuento
      </Typography>

      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Código</TableCell>
              <TableCell>Porcentaje de Descuento (%)</TableCell>
              <TableCell>Activo</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {descuentos.map((descuento) => (
              <TableRow key={descuento.id}>
                <TableCell>{descuento.id}</TableCell>
                <TableCell>{descuento.codigo}</TableCell>
                <TableCell>{descuento.cantidad}%</TableCell>
                <TableCell>
                  <Switch
                    checked={descuento.activo}
                    onChange={() => handleToggleDescuento(descuento.id, descuento.activo)}
                    color="primary"
                  />
                </TableCell>
                <TableCell align="right">
                  <IconButton color="primary" onClick={() => handleEditDescuento(descuento)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDeleteDescuento(descuento.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleOpen}
        sx={{ marginTop: '1em' }}
      >
        Agregar Código de Descuento
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentDescuento.id ? 'Editar Código de Descuento' : 'Agregar Código de Descuento'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Código"
            type="text"
            fullWidth
            variant="outlined"
            value={currentDescuento.codigo}
            onChange={(e) => setCurrentDescuento({ ...currentDescuento, codigo: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Porcentaje de Descuento"
            type="number"
            fullWidth
            variant="outlined"
            value={currentDescuento.cantidad}
            onChange={(e) => setCurrentDescuento({ ...currentDescuento, cantidad: Number(e.target.value) })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSaveDescuento} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DescuentosPage;