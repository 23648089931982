import React, { useState, useEffect } from 'react';
// import * as yup from 'yup';
import { Grid, styled, Typography, Button } from '@mui/material';

import IconButton from '@mui/material/IconButton';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CardStandarProduct from '../../../../components/CardStandarProduct/index';

//LoadingCircularProgress
import LoadingCircularProgress from '../../../../components/LoadingCircularProgress/index';
//Dialog
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';

import Alert from '@mui/material/Alert';
// import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import {
  // getCollectionProducts,
  addStandarProduct,
  updateStandarProduct,
} from '../../../../services/standarproducts';
import { uploadImageProduct } from '../../../../services/images';

// @ts-ignore
const Text = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: props.weight || 700,
  textAlign: props.align || 'center',
  color: props.color || 'black',
}));

// const validationSchema = yup.object({
//   name: yup.string().required('El nombre del producto es requerido'),
// });

const DialogProducts = (props) => {
  const {
    openDialog,
    closeDialog,
    itemForEditParam,
    setItemForEditParam,
    categoriesStandar,
    setFilterCategorieFromDialog,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [textSuccessAlert, setTextSuccessAlert] = useState('');
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [textErrorAlert, setTextErrorAlert] = useState('');
  const [idProduct, setIdProdcut] = useState('');

  const [fileName, setFileName] = useState(null);
  // const [itemToEdit, setItemToEdit] = useState();

  const [isEditPage, setIsEditPage] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  // item data
  const [categorie, setCategorie] = useState(categoriesStandar[0].id);
  const [name, setName] = useState('');
  const [color, setColor] = useState('');
  const [base, setBase] = useState(0);
  const [altura, setAltura] = useState(0);
  const [price, setPrice] = useState();
  const [file, setFile] = useState(null);
  const [isStock, setIsStock] = useState(true);

  const [priceOff, setPriceOff] = useState();

  const stock = [
    {
      value: true,
      label: 'En stock',
    },
    {
      value: false,
      label: 'Sin stock',
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    loadData();
    setIsLoading(false);
  }, [itemForEditParam]);

  const loadData = async (createdProduct) => {
    if (createdProduct) {
      setName(createdProduct.name);
      setCategorie(createdProduct.categorie);
      setColor(createdProduct.color);
      setBase(createdProduct.base);
      setAltura(createdProduct.altura);
      setPrice(createdProduct.price);
      setPriceOff(createdProduct.price);
      setIsStock(createdProduct.stock);

      setIdProdcut(createdProduct.id);
      setIsEditPage(true);
    } else {
      if (itemForEditParam) {
        setName(itemForEditParam.name);
        setCategorie(itemForEditParam.categorie);
        setColor(itemForEditParam.color);
        setBase(itemForEditParam.base);
        setAltura(itemForEditParam.altura);
        setPrice(itemForEditParam.price);
        setIsStock(itemForEditParam.stock);
        setPriceOff(itemForEditParam.priceOff);

        setIdProdcut(itemForEditParam.id);
        setImageUrl(itemForEditParam.imageUrl);
        setIsEditPage(true);
      } else {
        setIsEditPage(false);
      }
    }
  };

  const handleChangeName = async (event) => {
    event.preventDefault();
    setName(event.target.value);
  };

  const handleChangeColor = async (event) => {
    event.preventDefault();
    setColor(event.target.value);
  };

  const handleChangeBase = async (event) => {
    event.preventDefault();
    setBase(event.target.value);
  };

  const handleChangeAltura = async (event) => {
    event.preventDefault();
    setAltura(event.target.value);
  };

  const handleChangePrice = async (event) => {
    event.preventDefault();
    setPrice(event.target.value);
  };

  const handleChangePriceOff = async (event) => {
    event.preventDefault();
    setPriceOff(event.target.value);
  };

  const handleChangeStock = async (e) => {
    e.preventDefault();
    setIsStock(e.target.value);
  };
  const handleChangeCategorie = async (e) => {
    e.preventDefault();
    setCategorie(e.target.value);
    setFilterCategorieFromDialog(e.target.value);
  };
  const handleCloseDialog = () => {
    setIsLoading(false);
    setItemForEditParam({});
    setName('');
    setCategorie(null);
    setColor(null);
    setBase(null);
    setAltura(null);
    setPrice(null);
    setPriceOff(null);
    setIsStock(null);
    setFileName(null);
    setFile(null);
    closeDialog(false);
  };

  const handleChange = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    try {
      const result = await uploadImageProduct(
        event.target.files[0],
        event.target.files[0].name,
      );

      setImageUrl(result.imageUrl);
      setIsLoading(false);
      setFileName(null);
      setFile(null);
      //   getFirebaseImages();
    } catch (error) {
      setFileName(null);
      setFile(null);
      setIsLoading(false);
      // alert('Fallo al subir el archivo');
    }

    return;

    // setFileName(event.target.files[0].name);
    // setFile(event.target.files[0]);
  };

  const handleFormAdd = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let result = await addStandarProduct({
        categorie: categorie,
        name: name,
        color: color,
        base: base,
        altura: altura,
        price: price,
        priceOff: priceOff ? priceOff : 0,
        stock: isStock,
        imageUrl: '',
      });
      loadData(result.product);
      setTextSuccessAlert(result.status);
      setIsLoading(false);
      setOpenSuccessAlert(true);
    } catch (error) {
      console.log('error:', error);
      setTextErrorAlert(error.status);
      setOpenErrorAlert(true);
      setIsLoading(false);
      // alert('Fallo al subir el archivo');
    }
  };

  const handleFormEdit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      console.log('try', priceOff);
      let result = await updateStandarProduct({
        id: idProduct,
        name: name,
        categorie: categorie,
        color: color,
        base: base,
        altura: altura,
        price: price,
        priceOff: priceOff ? priceOff : 0,
        stock: isStock,
        imageUrl: imageUrl,
      });
      console.log('result:', result);
      setTextSuccessAlert(result.status);
      setIsLoading(false);
      setOpenSuccessAlert(true);
    } catch (error) {
      setTextErrorAlert(error.status);
      setOpenErrorAlert(true);
      setIsLoading(false);
      // alert('Fallo al subir el archivo');
    }
  };

  return (
    <>
      <Dialog fullScreen open={openDialog}>
        <LoadingCircularProgress open={isLoading} />
        <DialogTitle
          style={{
            backgroundColor: '#185d22',
            color: 'white',
            fontFamily: 'Inter',
          }}
        >
          {isEditPage ? 'Editar Producto' : 'Nuevo Producto'}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Grid
          container
          direction="row"
          id={'division'}
          style={{ display: 'flex', flexWrap: 'nowrap' }}
        >
          <Grid
            item
            container
            direction="column"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Grid
              item
              margin={2}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Text
                sx={{
                  fontSize: {
                    md: 20,
                    xs: 12,
                  },
                }}
              >
                Vista previa
              </Text>
            </Grid>
            <Grid item margin={2}>
              <CardStandarProduct
                item={{
                  name: name,
                  categorie: categorie,
                  color: color,
                  base: base,
                  altura: altura,
                  price: price,
                  priceOff: priceOff,
                  image: file,
                  stock: isStock,
                  imageUrl: imageUrl,
                }}
                isPreview={true}
              />
            </Grid>

            {isEditPage ? (
              <>
                <Grid
                  item
                  margin={2}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Text
                    sx={{
                      fontSize: {
                        md: 20,
                        xs: 12,
                      },
                    }}
                  >
                    Imagen
                  </Text>
                </Grid>

                <Grid item>
                  {fileName ? (
                    <Grid>
                      <Typography>{fileName}</Typography>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: '#185d22' }}
                        onClick={() => {
                          setFileName(null);
                          setFile(null);
                        }}
                      >
                        Cambiar Imagen
                      </Button>
                    </Grid>
                  ) : (
                    <>
                      <input
                        name=""
                        onChange={handleChange}
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          style={{ backgroundColor: '#185d22' }}
                        >
                          Subir imagen
                        </Button>
                      </label>
                    </>
                  )}
                </Grid>
              </>
            ) : null}
          </Grid>
          <Grid item container direction="column">
            <Grid
              item
              margin={2}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Text
                sx={{
                  fontSize: {
                    md: 20,
                    xs: 12,
                  },
                }}
              >
                Formulario
              </Text>
            </Grid>
            <Grid item margin={2} id={'box'}>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <TextField
                  select
                  label="Categoria"
                  value={categorie}
                  variant="standard"
                  // helperText="Seleccionar una categoria"
                  onChange={handleChangeCategorie}
                >
                  {categoriesStandar.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  value={name}
                  type="text"
                  label="Nombre"
                  variant="standard"
                  onChange={handleChangeName}
                  // helperText="Ingresar un nombre"
                />

                <TextField
                  value={color}
                  type="text"
                  label="Color"
                  variant="standard"
                  onChange={handleChangeColor}
                  // helperText="Ingresar un nombre"
                />
                <Grid
                  item
                  container
                  id="medidas"
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Grid item>
                    <TextField
                      value={base}
                      type="number"
                      label="Base"
                      variant="standard"
                      onChange={handleChangeBase}
                      // helperText="Ingresar un nombre"
                    />
                  </Grid>
                  <Grid item mt={'20px'}>
                    <Typography>X</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      value={altura}
                      type="number"
                      label="Altura"
                      variant="standard"
                      onChange={handleChangeAltura}
                      // helperText="Ingresar un nombre"
                    />
                  </Grid>
                </Grid>

                <TextField
                  value={price}
                  type="numbre"
                  label="Precio"
                  variant="standard"
                  onChange={handleChangePrice}
                  // helperText="Ingresar un nombre"
                />

                <TextField
                  value={priceOff}
                  type="numbre"
                  label="Precio anterior (descuento)"
                  variant="standard"
                  onChange={handleChangePriceOff}
                  // helperText="Ingresar un nombre"
                />

                <TextField
                  select
                  label="Stock"
                  value={isStock}
                  variant="standard"
                  // helperText="Seleccionar una categoria"
                  onChange={handleChangeStock}
                >
                  {stock.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                {isEditPage ? (
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#185d22' }}
                    onClick={handleFormEdit}
                  >
                    Guardar cambios
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#185d22' }}
                    onClick={handleFormAdd}
                  >
                    Crear Producto
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          display={'flex'}
          justifyContent={'center'}
          mt={5}
          id="successs"
        >
          <Box sx={{ width: '100%' }}>
            <Collapse in={openSuccessAlert}>
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenSuccessAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {textSuccessAlert}
              </Alert>
            </Collapse>
            <Collapse in={openErrorAlert}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenErrorAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {textErrorAlert}
              </Alert>
            </Collapse>
          </Box>
        </Grid>
      </Dialog>
    </>
  );
};

export default DialogProducts;
