import React, { useEffect, useState } from 'react';
import { Grid, Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { DeleteForever, Edit } from '@mui/icons-material';
import { BasicModal, DialogModal, Search, Table } from 'components';
import CurtainForm from './CurtainForm';
import { CustomCurtainsService } from 'services';
import { columnsArray, initialValues } from './constants';

const CustomCurtains = () => {
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [curtain, setCurtain] = useState(initialValues);

  const getAllCurtains = async () => {
    const curtains = await CustomCurtainsService.getAll();
    setData(curtains);
  };

  useEffect(() => {
    getAllCurtains();
  }, []);

  const handleSearch = () => {
    if (search) {
      setIsLoading(true);
      CustomCurtainsService.search(search)
        .then((res) => setData(res))
        .finally(() => setIsLoading(false));
    } else {
      getAllCurtains();
    }
  };

  const handleDelete = () => {
    CustomCurtainsService.remove(curtain.id).finally(() => {
      setCurtain(initialValues);
      setShowAlertModal(false);
      getAllCurtains();
    });
  };

  const handleCloseModal = () => {
    setShowFormModal(false);
    setCurtain(initialValues);
    getAllCurtains();
  };

  const handleCloseAlertModal = () => {
    setCurtain(initialValues);
    setShowAlertModal(false);
  };

  const columns = [
    ...columnsArray,
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Acciones',
      headerClassName: 'header',
      sortable: false,
      minWidth: 80,
      align: 'center',
      getActions: ({ row }) => [
        <GridActionsCellItem
          key={1}
          label="editar"
          onClick={() => {
            setCurtain(row);
            setShowFormModal(true);
          }}
          icon={
            <Tooltip title="Editar">
              <Edit color="primary" />
            </Tooltip>
          }
        />,
        <GridActionsCellItem
          key={2}
          label="eliminar"
          onClick={() => {
            setCurtain(row);
            setShowAlertModal(true);
          }}
          icon={
            <Tooltip title="Eliminar color">
              <DeleteForever color="primary" />
            </Tooltip>
          }
        />,
      ],
    },
  ];

  return (
    <Grid container direction="column">
      <Grid item>
        {/* <Search
          title="Buscar cortina"
          placeholder="Buscar por nombre de la cortina"
          search={search}
          setSearch={setSearch}
          onSubmit={handleSearch}
        /> */}
        <Table
          title="Cortinas a medida"
          values={data}
          columns={columns}
          isLoading={isLoading}
          // buttonTitle="Crear nueva cortina"
          // buttonAction={() => setShowFormModal(true)}
        />
      </Grid>
      <BasicModal open={showFormModal} onClose={handleCloseModal}>
        <CurtainForm curtain={curtain} onClose={handleCloseModal} />
      </BasicModal>
      <DialogModal
        open={showAlertModal}
        onClose={handleCloseAlertModal}
        onSubmit={handleDelete}
        title="Eliminar cortina"
        description="Esta acción es irreversible, ¿desea continuar?"
        colorButton="error"
      />
    </Grid>
  );
};

export default CustomCurtains;
