import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { CircularProgressWithLabel } from 'components';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useDispatch, useSelector } from 'react-redux';

const Resume = ({ item, formData, resetFormData, openSuccessModal }) => {

  
  const dispatch = useDispatch();
  const pedido = useSelector((state) => state.pedido);

  const progress = () => {
    let completedPoints = 0;
    const dataToValidate = { ...formData };
    delete dataToValidate.quantity;
    delete dataToValidate.color;
    delete dataToValidate.height;
    delete dataToValidate.placement;
    delete dataToValidate.type;
    delete dataToValidate.width;

    for (const key in dataToValidate) {
      const element = dataToValidate[key];
      if (element) completedPoints++;
    }

    const result = (completedPoints * 100) / Object.keys(dataToValidate).length;

    return result;
  };

  const handleSubmit = () => {
    let precioTotal = returnPrecioTotal();
    let itemAux = { ...item };
    // console.log("formData:",formData);
    delete itemAux.colores;
    itemAux.color = formData.color.nombre;
    itemAux.quantity = formData.quantity;
    itemAux.precioTotal = precioTotal;
    itemAux.chainType = formData.chainType;
    itemAux.rollDrop = formData.rollDrop;
    itemAux.position = formData.position;
    itemAux.productType = 'Estandar',
    // console.log('itemAux:', itemAux);

    addItem(itemAux);
  };

  const addItem = (item) => {
    dispatch({ type: 'ADD_ITEM', payload: item });
  };

  function returnPrecioTotal() {
    if (formData.chainType === 'MOTORIZADA') {
      let precio = parseFloat(item.precio);

      const total = Number(formData.quantity) * (precio + 10.0);
      return total;
    } else {
      const total = Number(formData.quantity) * parseFloat(item.precio);
      return total;
    }
  }

  return (
    <Grid
      item
      container
      sx={{ p: 4, border: '1px solid #000000', borderRadius: '10px', mt: 2 }}
    >
      <Grid item display="block" xs={6} pl={2}>
        <Typography fontFamily="Inter" fontSize={18} fontWeight={400} mb={2}>
          Tu pedido
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={600}>
          {item.titulo}
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={500}>
          1. Color: {formData.color?.nombre}
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={500}>
          {/* 2. Medidas: // {item.base} x {item.altura} */}
          2. Medidas: {formData.medidas}
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={500}>
          3. Caída del rollo: {formData.rollDrop}
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={500}>
          4. Posición del mando: {formData.position}
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={500}>
          5. Cadena:
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={500}>
          {formData.chainType === 'MOTORIZADA'
            ? formData.chainType + ' + (10.000$)'
            : formData.chainType}
        </Typography>
        <Divider sx={{ mt: 1, mb: 1, width: '100%' }} />
        <Typography fontFamily="Inter" fontSize={16} fontWeight={600}>
          Cantidad: {Number(formData.quantity)}
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={600}>
          {'Total: $ ' + returnPrecioTotal()}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ p: { xs: 1, md: 4 } }}>
        <CircularProgressWithLabel value={progress()} />
      </Grid>
      <Grid item xs={12} mt={4}>
        <Button
          disabled={progress() !== 100}
          variant="contained"
          fullWidth
          color="success"
          sx={{ height: 55, borderRadius: 10,bgcolor: '#2FB943', ":hover": {backgroundColor: '#2FB943'}}}
          onClick={() => handleSubmit()}
        >
          <ShoppingCartIcon style={{ marginRight: '5px' }} />
          Agregar al carrito
        </Button>
      </Grid>
    </Grid>
  );
};

export default Resume;
