// @ts-nocheck
import React, { useState, useEffect } from 'react';

import { Grid, styled, Typography, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import CardStandarProduct from '../../components/CardStandarProduct/index';
//LoadingCircularProgress
import LoadingCircularProgress from '../../components/LoadingCircularProgress/index';

import {
  getCollectionStandarProducts,
  deleteStandarProduct,
} from '../../services/standarproducts';

import { CategoryStandarPoductsService } from 'services';
import { StandarProductsFilter } from 'components/StandarProductsFilter';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

const Text = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: 700,
  textAlign: props.align || 'center',
  color: 'black',
}));

const Standar = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [filterCatalogue, setFilterCatalogue] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [itemEdit, setItemEdit] = useState();
  const [itemDelete, setItemDelete] = useState();

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [textSuccessAlert, setTextSuccessAlert] = useState('');

  const [categorie, setCategorie] = useState('All');
  const [categoriesStandar, setCategoriesStandar] = useState([]);

  const [filterCategorieFromDialog, setFilterCategorieFromDialog] =
    useState('');

  const handleChangeCategorie = async (e) => {
    e.preventDefault();
    setCategorie(e.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    getProducts();
    getAllCategoriesStandarProducts();
    setIsLoading(false);
  }, [openDialog]);

  const getProducts = async () => {
    try {
      const result = await getCollectionStandarProducts();
      console.log('result1: ', result);
      result.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      console.log('result2: ', result);
      setFilterCatalogue(result);
      setProducts(result);
    } catch (error) {
      setProducts([]);
      // alert('Fallo al subir el archivo');
    }
  };

  const getAllCategoriesStandarProducts = async () => {
    const categories = await CategoryStandarPoductsService.getAll();
    setCategoriesStandar(categories);
  };

  const deleteProductSelected = async () => {
    try {
      const result = await deleteStandarProduct(itemDelete);
      setTextSuccessAlert(result.status);
      setOpenDialogDelete(false);
      getProducts();
      setOpenSuccessAlert(true);
    } catch (error) {
      // alert('Fallo al subir el archivo');
    }
  };

  const handleOpenDialog = () => {
    setItemEdit(undefined);
    setOpenDialog(true);
  };

  const handleSetItemForEdit = async (product) => {
    setItemEdit(product);
    setOpenDialog(true);
  };

  const handleSetItemForDelete = async (product) => {
    setItemDelete(product);
    setOpenDialogDelete(true);
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  return (
    <>
      {' '}
      <LoadingCircularProgress open={isLoading} />
      <Grid container direction="row" mt={5}>
        {/* /FILTROS/ */}

        <Grid item xs={2}>
          <StandarProductsFilter
            catalogue={products}
            categoriesStandarProducts={categoriesStandar}
            setFilterCatalogue={setFilterCatalogue}
            filterCategorieFromDialog={filterCategorieFromDialog}
          />
        </Grid>
        {/* <Grid item xs={9} pl={'20px'} direction={'row'} spacing={2}> */}
        {/* <Grid item xs={8} display={'flex'} justifyContent={'space-around'}> */}
        <Grid
          container
          item
          lg={10}
          xs={12}
          pl={'20px'}
          direction={'row'}
          spacing={2}
        >
          {filterCatalogue.map((element, index) => {
            return (
              <Grid item key={index}>
                <CardStandarProduct
                  item={element}
                  isEdit={false}
                  setItemForEdit={(e) => handleSetItemForEdit(e)}
                  setItemForDelete={(e) => handleSetItemForDelete(e)}
                />
              </Grid>
            );
          })}
         
        </Grid>

        {/* /FILTROS/ */}

        {/* /PRODUCTOS/ */}
        {/* /PRODUCTOS/ */}
      </Grid>
    </>
  );
};

export default Standar;
