import React, { useEffect, useState } from 'react';
import { Grid, Tooltip, Button, Typography } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Search, Table } from 'components';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { cortinasCotizadas } from 'services';
// import { columnsArray } from './constants';

const columnsArray = [
  // {
  //   field: 'slug',
  //   headerName: 'Slug',
  //   width: 200,
  //   headerClassName: 'header',
  // },
  {
    field: 'id',
    headerName: 'Código',
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'date',
    headerName: 'Día',
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'type',
    headerName: 'Tipo',
    flex: 1,
    headerClassName: 'header',
  },
  {
    field: 'state',
    headerName: 'Estado',
    flex: 1,
    headerClassName: 'header',
  },
  // {
  //   field: 'price',
  //   headerName: 'Precio',
  //   width: 200,
  //   headerClassName: 'header',
  // },
];

const QuotedCurtains = () => {
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [curtain, setCurtain] = useState(null);

  console.log('curtain:', curtain);

  const getAllCurtains = async () => {
    const curtains = await cortinasCotizadas.getAll();
    console.log('curtains:', curtains);

    // curtains.sort(function (a, b) {
    //   return a.date < b.date;
    // });

    curtains.sort((a, b) => new Date(b.date) - new Date(a.date));

    console.log('curtains sort:', curtains);

    setData(curtains);
  };

  useEffect(() => {
    getAllCurtains();
  }, []);

  const handleSearch = () => {
    if (search) {
      setIsLoading(true);
      cortinasCotizadas
        .search(search)
        .then((res) => setData(res))
        .finally(() => setIsLoading(false));
    } else {
      getAllCurtains();
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  // const handleCloseAlertModal = () => {
  //   setCurtain(initialValues);
  //   setShowAlertModal(false);
  // };

  const columns = [
    ...columnsArray,
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ver',
      headerClassName: 'header',
      sortable: false,
      minWidth: 80,
      align: 'center',
      getActions: ({ row }) => [
        <GridActionsCellItem
          key={1}
          label="Ver"
          onClick={() => {
            setCurtain(row);
            setOpenModal(true);
          }}
          icon={
            <Tooltip title="Ver">
              <RemoveRedEyeIcon color="primary" />
            </Tooltip>
          }
        />,
      ],
    },
  ];

  return (
    <Grid container direction="column">
      <Grid item>
        {/* <Search
          title="Buscar cortina"
          placeholder="Buscar por código de la cortina"
          search={search}
          setSearch={setSearch}
          onSubmit={handleSearch}
        /> */}
        <Table
          title="Cortinas cotizadas"
          values={data}
          columns={columns}
          isLoading={isLoading}
        />
      </Grid>
      {/* <BasicModal open={openModal} onClose={handleCloseModal}>
        asd
      </BasicModal> */}
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h1"
            fontSize={24}
            fontWeight={600}
            fontFamily={'Rubik, sans-serif'}
          >
            {'Cortina: ' + curtain?.type}
          </Typography>
          <Typography
            variant="h1"
            fontSize={24}
            fontWeight={600}
            fontFamily={'Rubik, sans-serif'}
          >
            {'Código: ' + curtain?.id}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
          >
            <Typography fontSize={18}>{'Día: ' + curtain?.date}</Typography>
            <Typography fontSize={18}>
              {'Tipo de tela: ' + curtain?.data[0].type.description}
            </Typography>
            <Typography fontSize={18}>
              {'Color: ' + curtain?.data[0].color?.name}
            </Typography>
            <Typography fontSize={18}>
              {'Precio de tela: ' + curtain?.data[0].color?.price}
            </Typography>

            <Typography fontSize={18}>
              {'Medidas: ' +
                curtain?.data[0].width +
                ' cm X ' +
                curtain?.data[0].height +
                'cm'}
            </Typography>
            <Typography fontSize={18}>
              {'Caída del rollo:: ' + curtain?.data[0].rollDrop.description}
            </Typography>
            <Typography fontSize={18}>
              {'Posición del mando: ' + curtain?.data[0].position.description}
            </Typography>
            <Typography fontSize={18}>
              {'Cadena: ' + curtain?.data[0].chainType.description}
            </Typography>

            <Typography fontSize={18}>
              {'Precio total: $' + curtain?.data[0].totalPrice}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions display={'flex'} justifyContent={'center'}>
          <Button onClick={handleClose} autoFocus>
            <Typography
              variant="h1"
              fontSize={14}
              fontWeight={500}
              fontFamily={'Rubik, sans-serif'}
            >
              {'Cerrar'}
            </Typography>
          </Button>
          <Button onClick={handleClose} autoFocus>
            <Typography
              variant="h1"
              fontSize={14}
              fontWeight={500}
              fontFamily={'Rubik, sans-serif'}
            >
              {'Guardar estado'}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default QuotedCurtains;
