// @ts-nocheck
import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Drawer,
  CssBaseline,
  AppBar as MuiAppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import {
  Menu,
  ChevronLeft,
  ChevronRight,
  AddShoppingCart,
  ShoppingCart,
  ViewCarousel,
  People,
  KeyboardReturn,
  ColorLens,
  Category,
  Texture,
} from '@mui/icons-material';

import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAuthState } from 'context/AuthContext';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
// import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RollerShadesIcon from '@mui/icons-material/RollerShades';
import InventoryIcon from '@mui/icons-material/Inventory';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
const drawerWidth = 250;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function BackOfficeLayout() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const { user } = useAuthState();

  if (!user || user.rol !== 'admin') {
    return <Navigate to="/" replace />;
  } else {
    // console.log("else !user || user.rol !== 'admin'")
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar style={{ backgroundColor: '#299A30' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Texalia - BackOffice
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>

        <ListItemButton onClick={() => navigate('/BO-CategoriasCatalogo')}>
            <ListItemIcon>{<BookmarkAddIcon />}</ListItemIcon>
            <ListItemText primary={'Categorias Catálogo'} />
          </ListItemButton>


        {/* <ListItemButton onClick={() => navigate('/categoriescataloguex')}>
            <ListItemIcon>{<BookmarkAddIcon />}</ListItemIcon>
            <ListItemText primary={'Categorias Catálogo'} />
          </ListItemButton> */}

          {/* <ListItemButton onClick={() => navigate('/products')}>
            <ListItemIcon>{<BookmarksIcon />}</ListItemIcon>
            <ListItemText primary={'Catálogo'} />
          </ListItemButton> */}
          {/* <ListItemButton onClick={() => navigate('/categoriescatalogue')}>
            <ListItemIcon>{<BookmarkAddIcon />}</ListItemIcon>
            <ListItemText primary={'Categorias Catalogo'} />
          </ListItemButton> */}


          {/* <ListItemButton onClick={() => navigate('/standarproducts')}>
            <ListItemIcon>{<ShoppingCart />}</ListItemIcon>
            <ListItemText primary={'Producto Estandar'} />
          </ListItemButton> */}

          <ListItemButton
            onClick={() => navigate('/categoriesstandarproducts')}
          >
            <ListItemIcon>{<AddShoppingCart />}</ListItemIcon>
            <ListItemText primary={'Categorias Estandar'} />
          </ListItemButton>

          <ListItemButton onClick={() => navigate('/BO-ProductosEstandar')}>
            <ListItemIcon>{<ShoppingCart />}</ListItemIcon>
            <ListItemText primary={'Productos Estandar'} />
          </ListItemButton>

          <ListItemButton
            onClick={() => navigate('/BO-CategoriasDecoHome')}
          >
            <ListItemIcon>{<AddShoppingCart />}</ListItemIcon>
            <ListItemText primary={'Categorias Deco Home'} />
          </ListItemButton>

          <ListItemButton onClick={() => navigate('/BO-DecoHomeList')}>
            <ListItemIcon>{<ShoppingCart />}</ListItemIcon>
            <ListItemText primary={'Productos Deco Home'} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate('/BO-PedidosPage')}>
            <ListItemIcon>{<InventoryIcon />}</ListItemIcon>
            <ListItemText primary={'Pedidos'} />
          </ListItemButton>

          <ListItemButton onClick={() => navigate('/BO-DescuentosPage')}>
            <ListItemIcon>{<CardGiftcardIcon />}</ListItemIcon>
            <ListItemText primary={'Cupones de descuento'} />
          </ListItemButton>


          <ListItemButton onClick={() => navigate('/images')}>
            <ListItemIcon>{<ViewCarousel />}</ListItemIcon>
            <ListItemText primary={'Carusel de Imagenes'} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate('/users')}>
            <ListItemIcon>{<People />}</ListItemIcon>
            <ListItemText primary={'Usuarios'} />
          </ListItemButton>
          {/* <ListItemButton onClick={() => navigate('/colors')}>
            <ListItemIcon>{<ColorLens />}</ListItemIcon>
            <ListItemText primary={'Colores'} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate('/categories')}>
            <ListItemIcon>{<Category />}</ListItemIcon>
            <ListItemText primary={'Categorías'} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate('/materials')}>
            <ListItemIcon>{<Texture />}</ListItemIcon>
            <ListItemText primary={'Materiales'} />
          </ListItemButton> */}
          <ListItemButton onClick={() => navigate('/customCurtains')}>
            <ListItemIcon>{<Texture />}</ListItemIcon>
            <ListItemText primary={'Cortinas a medida'} />
          </ListItemButton>
          <ListItemButton onClick={() => navigate('/quotedCurtains')}>
            <ListItemIcon>{<RollerShadesIcon />}</ListItemIcon>
            <ListItemText primary={'Cortinas Cotizadas'} />
          </ListItemButton>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => navigate('/')}>
            <ListItemIcon>{<KeyboardReturn />}</ListItemIcon>
            <ListItemText primary={'Volver a Texalia'} />
          </ListItem>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
}
