export const columnsArray = [
  {
    field: 'id',
    headerName: 'ID',
    width: 200,
    headerClassName: 'header',
  },
  {
    field: 'name',
    headerName: 'Nombre',
    width: 200,
    flex: 2,
    headerClassName: 'header',
  },
  {
    field: 'lastname',
    headerName: 'Apellido',
    width: 200,
    headerClassName: 'header',
  },
  {
    field: 'email',
    headerName: 'Correo',
    width: 200,
    flex: 2,
    headerClassName: 'header',
  },
  {
    field: 'rol',
    headerName: 'Rol',
    width: 200,
    flex: 2,
    headerClassName: 'header',
  },
];

export const initialValues = {
  id: '',
  name: '',
  lastname: '',
  email: '',
  dni: '',
  address: '',
  rol: '',
};
