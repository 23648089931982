import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Card,
  CardMedia,
  CardActions,

} from '@mui/material';
import { collection, doc, setDoc, getDocs } from 'firebase/firestore';
import { addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, storage } from '../../firebaseApp';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const CatalogeCategories = () => {
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({
    id: null,
    name: 'Interior',
    types: [],
  });
  const [alert, setAlert] = useState({
    open: false,
    severity: '',
    message: '',
  });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      const querySnapshot = await getDocs(
        collection(db, 'CategoriasDeCatalogo'),
      );
      const categoriesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesData);
    };

    fetchCategories();
  }, []);

  const handleOpen = () => {
    setCurrentCategory({ id: null, name: 'Interior', types: [] });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchCategories = async () => {
    const querySnapshot = await getDocs(collection(db, 'CategoriasDeCatalogo'));
    const categoriesData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setCategories(categoriesData);
  };

  const handleRemoveColor = (typeIndex, colorIndex) => {
    const updatedTypes = [...currentCategory.types];
    const updatedColors = updatedTypes[typeIndex].colors.filter(
      (_, idx) => idx !== colorIndex,
    );
    updatedTypes[typeIndex].colors = updatedColors;
    setCurrentCategory({ ...currentCategory, types: updatedTypes });

    // Guardar los cambios en Firebase
    saveChangesToFirebase();
  };

  const saveChangesToFirebase = async () => {
    try {
      const categoryRef = doc(db, 'CategoriasDeCatalogo', currentCategory.id);
      await setDoc(
        categoryRef,
        {
          name: currentCategory.name,
          types: currentCategory.types.map((type) => ({
            name: type.name,
            colors: type.colors.map((color) => ({
              name: color.name,
              photoUrl: color.photoUrl,
            })),
          })),
        },
        { merge: true },
      );

      setAlert({
        open: true,
        severity: 'success',
        message: 'Cambios guardados con éxito!',
      });
      await fetchCategories(); // Recargar las categorías
    } catch (error) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Error al guardar cambios: ' + error.message,
      });
    }
  };

  const handleSaveCategory = async () => {
    try {
      const categoryRef = currentCategory.id
        ? doc(db, 'CategoriasDeCatalogo', currentCategory.id)
        : doc(collection(db, 'CategoriasDeCatalogo'));

      await setDoc(
        categoryRef,
        {
          name: currentCategory.name,
          types: currentCategory.types.map((type) => ({
            name: type.name,
            colors: type.colors.map((color) => ({
              name: color.name,
              photoUrl: color.photoUrl,
            })),
          })),
        },
        { merge: true },
      );

      setAlert({
        open: true,
        severity: 'success',
        message: `Categoría ${
          currentCategory.id ? 'actualizada' : 'agregada'
        } con éxito!`,
      });
      setOpen(false);
      await fetchCategories(); // Recarga las categorías para ver los cambios
    } catch (error) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Error al guardar la categoría: ' + error.message,
      });
    }
  };

  const handleEditCategory = (category) => {
    setCurrentCategory(category);
    setOpen(true);
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      const categoryRef = doc(db, 'CategoriasDeCatalogo', categoryId);
      await deleteDoc(categoryRef);
      setAlert({
        open: true,
        severity: 'success',
        message: 'Categoría eliminada con éxito',
      });
      setCategories(categories.filter((cat) => cat.id !== categoryId)); // Actualiza la lista sin recargar
    } catch (error) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Error al eliminar la categoría',
      });
    }
  };

  const handleAddType = () => {
    setCurrentCategory({
      ...currentCategory,
      types: [...currentCategory.types, { name: '', colors: [] }],
    });
  };

  const handleTypeChange = (index, e) => {
    const updatedTypes = [...currentCategory.types];
    updatedTypes[index].name = e.target.value;
    setCurrentCategory({ ...currentCategory, types: updatedTypes });
  };

  const handleAddColor = (typeIndex) => {
    const updatedTypes = [...currentCategory.types];
    updatedTypes[typeIndex].colors.push({ name: '', photoUrl: '' });
    setCurrentCategory({ ...currentCategory, types: updatedTypes });
  };

  const handleColorChange = (typeIndex, colorIndex, field, value) => {
    const updatedTypes = [...currentCategory.types];
    updatedTypes[typeIndex].colors[colorIndex][field] = value;
    setCurrentCategory({ ...currentCategory, types: updatedTypes });
  };

  const handleUploadColorPhoto = async (typeIndex, colorIndex, file) => {
    const photoRef = ref(storage, `categoriasDeCatalogo/${file.name}`);
    await uploadBytes(photoRef, file);
    const photoUrl = await getDownloadURL(photoRef);
    handleColorChange(typeIndex, colorIndex, 'photoUrl', photoUrl);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        CRUD Categorías de Catálogo
      </Typography>

      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nombre de la Categoría</TableCell>
              <TableCell>Tipos y Colores</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category) => (
              <TableRow key={category.id}>
                <TableCell>{category.id}</TableCell>
                <TableCell sx={{ fontSize: 16, fontWeight: 600 }}>
                  {category.name}
                </TableCell>
                <TableCell>
                  {category.types.map((type, typeIndex) => (
                    <Box key={typeIndex}>
                      <Typography
                        variant="subtitle1"
                        fontSize={16}
                        fontWeight={600}
                      >
                        {'* ' + type.name}
                      </Typography>
                      <Grid container spacing={1}>
                        {type.colors.map((color, colorIndex) => (
                          <Grid item key={colorIndex} xs={4}>
                            <Card sx={{ maxWidth: 100 }}>
                              <CardMedia
                                component="img"
                                height="80"
                                image={
                                  color.photoUrl || '/placeholder-image.png'
                                }
                                alt={`Color ${color.name}`}
                              />
                              <Typography variant="body2" align="center">
                                {color.name}
                              </Typography>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  ))}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    color="primary"
                    onClick={() => handleEditCategory(category)}
                  >
                    <EditIcon />
                  </IconButton>
                  {/* <IconButton
                    color="error"
                    onClick={() => handleDeleteCategory(category.id)}
                  >
                    <DeleteIcon />
                  </IconButton> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleOpen}
        sx={{ marginTop: '1em' }}
      >
        Agregar Categoría
      </Button> */}

      {/* Modal para agregar/editar categoría */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {currentCategory.id ? 'Editar Categoría' : 'Agregar Categoría'}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ marginTop: '1rem' }}>
            <InputLabel> Categoría Principal</InputLabel>
            <Select
              sx={{ marginTop: '1rem' }}
              value={currentCategory.name}
              onChange={(e) =>
                setCurrentCategory({ ...currentCategory, name: e.target.value })
              }
            >
              <MenuItem value="Interior">Interior</MenuItem>
              <MenuItem value="Exterior">Exterior</MenuItem>
            </Select>
          </FormControl>

          {currentCategory.types.map((type, typeIndex) => (
            <Box key={typeIndex} sx={{ marginTop: 2}}>
              <TextField
                label={`Tipo ${typeIndex + 1}`}
                value={type.name}
                onChange={(e) => handleTypeChange(typeIndex, e)}
                fullWidth
              />
              {type.colors.map((color, colorIndex) => (
                <Grid
                  container
                  spacing={2}
                  key={colorIndex}
                  sx={{ marginTop: 1 }}
                >
                  <Grid item xs={12}>
                    <TextField
                      label={`Color ${colorIndex + 1}`}
                      value={color.name}
                      onChange={(e) =>
                        handleColorChange(
                          typeIndex,
                          colorIndex,
                          'name',
                          e.target.value,
                        )
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} display={'flex'} >
                    <Grid item xs={6} display={'flex'} alignItems={'center'} flexDirection={'column'}>
                      <Button
                        variant="contained"
                        component="label"
                        sx={{ marginTop: '1em' }}
                      >
                        Subir Foto
                        <input
                          type="file"
                          hidden
                          onChange={(e) =>
                            handleUploadColorPhoto(
                              typeIndex,
                              colorIndex,
                              e.target.files[0],
                            )
                          }
                        />
                      </Button>

                      {color.photoUrl && (
                        <Card sx={{ width: 100, marginTop: 2 }}>
                          <CardMedia
                            component="img"
                            height="100"
                            image={color.photoUrl}
                            alt={`Color ${colorIndex + 1}`}
                          />
                        </Card>
                      )}
                    </Grid>
                    <Grid item xs={6} display={'flex'} alignItems={'center'}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleRemoveColor(typeIndex, colorIndex)}
                      >
                        Eliminar Color
                      </Button>
                    </Grid>
                  </Grid>
                  
                </Grid>
              ))}
              <Grid item xs={12} display={'flex'} justifyContent={'center'} >
              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: '1em' }}
                onClick={() => handleAddColor(typeIndex)}
              >
                Agregar Color
              </Button>
                
              </Grid>
              <Grid item xs={12} display={'flex'} justifyContent={'center'}  sx={{borderBottom:'2px solid #2FB943' , marginTop:'1rem'}}></Grid>
            </Box>
          ))}

          <Button
            variant="contained"
            color="secondary"
            sx={{ marginTop: '1em' }}
            onClick={handleAddType}
          >
            Agregar Tipo
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSaveCategory} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ open: false })}
      >
        <Alert severity={alert.severity}>{alert.message}</Alert>
      </Snackbar>
    </Box>
  );
};

export default CatalogeCategories;
