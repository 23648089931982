import React, { useEffect, useState } from 'react';

import { Button, Grid, IconButton, Typography, styled } from '@mui/material';
import { blackout } from 'assets/cortinas-a-medida';
import { FavoriteBorderOutlined } from '@mui/icons-material';
import Filters from './Filters';
import Resume from './Resume';

import { useLocation } from 'react-router-dom';

const Root = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4, '10rem'),
  margin: 0,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 1),
  },
}));

const initialParameters = {
  name: '',
  description: '',
  images: [],
  types: [],
  positions: [],
  chainTypes: [],
  rollDropTypes: [],
};

const initialForm = {
  quantity: 1,
  type: null,
  color: null,
  width: '',
  height: '',
  rollDrop: null,
  position: null,
  chainType: null,
  placement: '',
};

const DecoHomePurchasePage = () => {
  const location = useLocation();

  const product = location?.state?.objeto;
  console.log("product:",product);
  

  // const query = useQuery();
  // const category = query.get('category');
  const [parameters, setParameters] = useState(initialParameters);
  const [formData, setFormData] = useState(initialForm);

  const [openModal, setOpenModal] = useState(false);

  // useEffect(() => {
  //   if (category) {
  //     CustomCurtainsService.getBySlug(category).then((res) =>
  //       setParameters(res),
  //     );
  //     setFormData(initialForm);
  //   }
  // }, [category]);

  const resetFormData = () => {
    setFormData(initialForm);
  };

  const openSuccessModal = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const onAddQuantity = () => {
    if (formData.quantity >= product.stock) return;
    setFormData({
      ...formData,
      quantity: formData.quantity + 1,
    });
  };

  const onRemoveQuantity = () => {
    if (formData.quantity === 1) return;
    setFormData({
      ...formData,
      quantity: formData.quantity - 1,
    });
  };

  return (
    <Root container spacing={4}>
      <Grid item container justifyContent="center" xs={12} md={6}>
        <Grid item>
          <img
            src={product.fotoPrincipal || blackout}
            height={450}
            
          />
          <Typography fontFamily={'Inter'} fontWeight={600} fontSize={17}>
            DESCRIPCIÓN
          </Typography>
          <Typography fontFamily={'Inter'} fontWeight={400} fontSize={16}>
            {product.descripcion}
          </Typography>
        </Grid>
        
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="left"
        xs={12}
        md={6}
        spacing={2}
      >
        <Grid item sx={{textAlign:'left'}}>
          <Typography
            variant="h1"
            fontFamily="Inter"
            fontSize={30}
            fontWeight={400}
            pt={4}
          >
            {product.titulo}
          </Typography>
          <Typography
            variant="h1"
            fontFamily="Inter"
            fontSize={30}
            fontWeight={400}
          >
            {product.categoria}
          </Typography>
        </Grid>
        <Grid item container justifyContent="space-around">
          <div
            style={{
              display: 'flex',
              backgroundColor: '#2FB943',
              borderRadius: '5px',
            }}
          >
            <Button sx={{ color: 'white' }} onClick={() => onAddQuantity()}>
              +
            </Button>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 16,
                paddingRight: 16,
                borderLeft: '1px solid white',
                borderRight: '1px solid white',
              }}
            >
              <Typography color="white">{formData.quantity}</Typography>
            </div>
            <Button sx={{ color: 'white' }} onClick={() => onRemoveQuantity()}>
              -
            </Button>
          </div>
          {/* <Grid item>
            <Button
              variant="contained"
              sx={{
                height: '100%',
                bgcolor: '#185D22',
                color: 'white',
                textTransform: 'none',
              }}
            >
              Agregar al carrito
            </Button>
          </Grid> */}
          {/* <Grid item>
            <IconButton size="large">
              <FavoriteBorderOutlined />
            </IconButton>
          </Grid> */}
        </Grid>
        {/* <Grid item>
          <Typography>Plazo de despacho: 15 días</Typography>
        </Grid> */}
        <Filters
          // parameters={parameters}
          product={product}
          formData={formData}
          setFormData={setFormData}
        />
        <Grid item width="100%">
          <Resume
            item={location?.state?.objeto}
            formData={formData}
            // parameters={parameters}
            resetFormData={resetFormData}
            openSuccessModal={openSuccessModal}
          />
        </Grid>
      </Grid>
    </Root>
  );
};

export default DecoHomePurchasePage;
