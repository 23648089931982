import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { blackout, doble, sunscreen } from 'assets/cortinas-a-medida';

// const Filters = () => {

const Option = styled('div')(({ color }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  border: `1px solid ${color}`,
  color: 'black',
  minWidth: '100px',
  padding: '5px 15px',
  '&:hover': {
    cursor: 'pointer',
  },
}));

const Filters = ({ formData, setFormData, product }) => {
  useEffect(() => {
    setFormData({
      ...formData,
      color: null,
    });
  }, [formData.type]);

  const handleChangeOptions = (name, value) => {
    console.log('formData:', formData);

    setFormData({
      ...formData,
      [name]: value,
    });
  };
console.log("product:",product);

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore sx={{color:'white'}} />}
            sx={{ bgcolor: '#2FB943', borderRadius: '10px' }}
          >
            <Typography sx={{color:'white'}} >1. Color</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justifyContent="space-around">
              {product.colores &&
                product.colores.map((color, idx) => (
                  <Grid
                    item
                    display="block"
                    key={idx}
                    px={2}
                    sx={{
                      textAlign: 'center',
                      ':hover': {
                        cursor: 'pointer',
                      },
                    }}
                    onClick={() => handleChangeOptions('color', color)}
                  >
                    <Typography >{color.nombre}</Typography>
                    <img
                      src={color.imagen}
                      height={45}
                      width={45}
                      style={{
                        borderRadius: '50%',
                        border:
                          color.nombre === formData.color?.nombre &&
                          '3px solid #2FB943',
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore  sx={{color:'white'}}/>}
            sx={{ bgcolor: '#2FB943', borderRadius: '10px' }}
          >
            <Typography sx={{color:'white'}}>2. Medidas</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justifyContent="space-around">
              {product.medidas &&
                product.medidas.map((medida, idx) => (
                  <Grid
                    item
                    display="block"
                    key={idx}
                    px={2}
                    sx={{
                      textAlign: 'center',
                      ':hover': {
                        cursor: 'pointer',
                      },
                    }}
                    onClick={() => handleChangeOptions('medidas', medida)}
                  >
                    <Option
                      color={
                        // formData.rollDrop?.id === 'rollDrop?.id'
                        //   ? '#012AFF'
                        //   : '#9A9A9A'

                        formData.medidas === medida
                          ? 'green'
                          : '#9A9A9A'
                        
                      }
                      sx={{ border: formData.medidas === medida ? '3px solid #2FB943' : null}}
                      onClick={() =>
                        handleChangeOptions('medidas', product.medidas)
                      }
                    >
                      <Typography fontSize={'14px'}>
                        {medida}
                      </Typography>
                    </Option>
                  </Grid>
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default Filters;
