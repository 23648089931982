import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NotImage from './NotImage.png';
import { styled } from '@mui/material';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

const TextTitle = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: 400,
  textAlign: 'center',
  color: 'white',
  textTransform: 'none',
  fontSize: '14px',
}));

const CardProduct = (props) => {
  const { item, isEdit, isPreview, setItemForEdit, setItemForDelete } = props;

  return (
    <Card sx={{ maxWidth: 345, maxHeight: 425 }}>
      <CardContent style={{ backgroundColor: '#5B5B5B' }}>
        <TextTitle color={'white'}>{item.name}</TextTitle>
      </CardContent>
      <CardMedia
        component="img"
        height="240"
        image={item.imageUrl ? item.imageUrl : NotImage}
      />
      {item.stock == false && (
        <CardContent style={{ display: 'flex', justifyContent: 'center' }}>
          <NotInterestedIcon style={{ color: 'red', marginRight: '5px' }} />
          <Typography color={'red'}>Sin stock</Typography>
        </CardContent>
      )}
      {!isPreview && isEdit ? (
        <CardActions
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#D9D9D9',
          }}
        >
          {/* <Button size="small">Vista rapida</Button> */}
          {isEdit ? (
            <Button size="small" onClick={() => setItemForEdit(item)}>
              Editar
            </Button>
          ) : null}
          {isEdit ? (
            <Button size="small" onClick={() => setItemForDelete(item)}>
              Eliminar
            </Button>
          ) : null}
        </CardActions>
      ) : null}
    </Card>
  );
};

export default CardProduct;
