import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  deleteDoc,
  addDoc,
} from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { db, storage } from 'firebaseApp';

const getAll = async () => {
  const data = await getDocs(
    collection(db, 'variablesCotizadorVandasVerticales'),
  );
  return data.docs.map((element) => ({ ...element.data(), id: element.id }));
};

const getCollectionProducts = async () => {
  let productsArray = [];

  try {
    const querySnapshot = await getDocs(
      collection(db, 'variablesCotizadorVandasVerticales'),
    );
    querySnapshot.forEach((doc) => {
      let json = JSON.parse(JSON.stringify(doc.data()));
      json.id = doc.id;
      productsArray.push(json);
    });
  } catch (error) {
    return { status: error };
  }

  return productsArray;
};

export const updateVariable = async (bodyProduct) => {
  try {
    const docRef = doc(
      db,
      'variablesCotizadorVandasVerticales/',
      bodyProduct.id,
    );
    await updateDoc(docRef, bodyProduct);
    return { status: 'Variable actualizada!' };
  } catch (error) {
    return { status: error };
  }
};

const remove = async (id) =>
  await deleteDoc(doc(db, 'variablesCotizadorVandasVerticales', id));

const variablesCotizadorBandasVerticalesService = {
  getAll,
  getCollectionProducts,
  updateVariable,
  remove,
};
export default variablesCotizadorBandasVerticalesService;
