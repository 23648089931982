import React from 'react';
import { DeleteOutline, RemoveCircleOutline } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';

const Types = ({ field, getValues, setValue, register, errors }) => {
  const onImagesSelected = async ({ target }, field) => {
    if (!target.files || target.files.length === 0) return;
    const files = getValues(`${field}.colors`) || [];
    for (const file of target.files) {
      files.push({ file, name: '', price: 0 });
    }
    setValue(`${field}.colors`, files, { shouldValidate: true });
  };

  const handleAdd = (name) => {
    const newArray = [...getValues(name)];
    newArray.push({
      id: Math.random().toString(),
      description: '',
      colors: [],
    });
    setValue(name, newArray, { shouldValidate: true });
  };

  const handleRemove = (name, value) =>
    setValue(
      name,
      getValues(name).filter((elem) => elem !== value),
      { shouldValidate: true },
    );

  const onDeleteColor = (image, field) => {
    const newArray = getValues(`${field}.colors`).filter(
      (img) => img !== image,
    );
    setValue(`${field}.colors`, newArray.length ? newArray : null, {
      shouldValidate: true,
    });
  };

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item>
        <Accordion>
          <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
            <Typography>Tipos de tela</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {getValues(field).map((type, idx) => {
              const fieldName = `${field}.${idx}`;
              return (
                <Grid
                  key={fieldName}
                  item
                  container
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item xs>
                    <TextField
                      name={`${fieldName}.description`}
                      label="Descripción"
                      size="small"
                      fullWidth
                      {...register(`${field}.${idx}.description`, {
                        required: 'Este campo es requerido',
                        minLength: { value: 2, message: 'Mínimo 2 caracteres' },
                      })}
                      error={
                        errors[field]?.length >= idx + 1 &&
                        !!errors[field][idx]?.description
                      }
                      helperText={
                        errors[field]?.length >= idx + 1 &&
                        errors[field][idx]?.description?.message
                      }
                    />
                  </Grid>
                  {getValues(field).length > 1 && (
                    <Grid item xs={1}>
                      <IconButton onClick={() => handleRemove(field, type)}>
                        <RemoveCircleOutline color="error" />
                      </IconButton>
                    </Grid>
                  )}
                  <Grid item container spacing={2} mb={2}>
                    {/* {!type.colors?.length ? (
                      
                    ) : ( */}
                    <>
                      <Grid item>
                        <label
                          htmlFor={`upload-images-${idx}`}
                          style={{
                            cursor: 'pointer',
                            padding: 5,
                            borderRadius: 10,
                            border: '1px solid #1976d2',
                          }}
                        >
                          Cargar coloress
                        </label>
                        <input
                          id={`upload-images-${idx}`}
                          type="file"
                          multiple
                          style={{ display: 'none' }}
                          accept="image/png, image/gif, image/jpeg"
                          onChange={(event) =>
                            onImagesSelected(event, fieldName)
                          }
                        />
                      </Grid>
                      {type.colors?.map((color, index) => {
                        return (
                          <Grid
                            item
                            container
                            direction="column"
                            alignItems="center"
                            xs={3}
                            key={`${index}_${color.name}`}
                          >
                            <Grid item>
                              <img
                                style={{
                                  border: '1px solid black',
                                  borderRadius: '50%',
                                }}
                                alt={color.name}
                                width={50}
                                height={50}
                                src={
                                  color.url || URL.createObjectURL(color.file)
                                }
                              />
                            </Grid>
                            <Grid item display="flex" flexDirection={'column'}>
                              <Grid item mb={1}>
                                <TextField
                                  variant="standard"
                                  // placeholder="Color..."
                                  label="Nombre"
                                  size="small"
                                  inputProps={{ style: { fontSize: 14 } }}
                                  sx={{ width: '100px', fontSize: '10px' }}
                                  {...register(
                                    `${fieldName}.colors.${index}.name`,
                                  )}
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  variant="standard"
                                  label="Precio"
                                  size="small"
                                  inputProps={{ style: { fontSize: 14 } }}
                                  sx={{ width: '100px', fontSize: '10px' }}
                                  {...register(
                                    `${fieldName}.colors.${index}.price`,
                                  )}
                                />
                              </Grid>
                              <IconButton
                                onClick={() => onDeleteColor(color, fieldName)}
                                size="small"
                              >
                                <DeleteOutline color="error" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        );
                      })}
                      {/* <Grid item>
                          <label
                            htmlFor="upload-images"
                            style={{
                              cursor: 'pointer',
                              padding: 5,
                              borderRadius: 10,
                              border: '1px solid #1976d2',
                            }}
                          >
                            Cargar colores
                          </label>
                          <input
                            id="upload-images"
                            type="file"
                            multiple
                            style={{ display: 'none' }}
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(event) =>
                              onImagesSelected(event, fieldName)
                            }
                          />
                        </Grid> */}
                    </>
                    {/* )} */}
                  </Grid>
                </Grid>
              );
            })}
            <Grid item display="flex" justifyContent="center">
              <Button variant="outlined" onClick={() => handleAdd(field)}>
                Agregar tipo +
              </Button>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default Types;
