import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  deleteDoc,
  addDoc,
} from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { db, storage } from 'firebaseApp';
import { returnUrlImage } from './images';

const isRepeated = async (name, id) => {
  const colRef = collection(db, 'colors');
  const queryByName = query(colRef, where('name', '==', name.toLowerCase()));
  const colors = (await getDocs(queryByName)).docs;
  const repeatedElements = id
    ? colors.filter((color) => color.id !== id)
    : colors;
  return repeatedElements;
};

const getAll = async () => {
  const colors = await getDocs(collection(db, 'colors'));
  return colors.docs.map((color) => ({ ...color.data(), id: color.id }));
};

const getOne = async (id) => {
  const docuRef = doc(db, `colors/${id}`);
  const docu = await getDoc(docuRef);
  const color = { ...docu.data(), id: docu.id };
  return color;
};

const search = async (search) => {
  const colRef = collection(db, 'colors');
  const queryByColor = query(
    colRef,
    where('name', '>=', search.toLowerCase()),
    where('name', '<=', search.toLowerCase() + '\uf8ff'),
  );

  const colorArray = (await getDocs(queryByColor)).docs;
  const colors = colorArray.map((color) => ({
    ...color.data(),
    id: color.id,
  }));
  return colors;
};

const create = async (data) => {
  const repeatedElements = await isRepeated(data.name);
  if (repeatedElements.length)
    return { status: 400, message: 'El color ya existe' };
  addDoc(collection(db, 'colors'), { name: data.name.toLowerCase() });
  return { status: 200, message: 'Color creado con éxito' };
};

const update = async (data) => {
  console.log('update colors: line 63');
  const repeatedElements = await isRepeated(data.name, data.id);
  if (repeatedElements.length)
    return { status: 400, message: 'El color ya existe' };
  const docuRef = doc(db, 'colors', data.id);
  await updateDoc(docuRef, {
    name: data.name.toLowerCase(),
    price: data.price,
    dateUpdate: data.dateUpdate,
  });
};

const remove = async (id) => await deleteDoc(doc(db, 'colors', id));

const uploadImage = async (file, productSlug) => {
  try {
    const storageRef = ref(storage, `colors/${productSlug}/${file.name}`);
    const result = await uploadBytes(storageRef, file);
    if (result) {
      return await returnUrlImage(`colors/${productSlug}/${file.name}`);
    }
  } catch (error) {
    return error;
  }
};

const ColorService = {
  getAll,
  getOne,
  search,
  create,
  update,
  remove,
  uploadImage,
};
export default ColorService;
