import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { Grid, TextField, Button, InputAdornment } from '@mui/material';
import { registerUser } from 'services/users';
import { useAuthState } from 'context/AuthContext';
import { initialValues } from 'pages/BackOffice/Users/constants';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const schema = yup.object({
  name: yup.string().required('El nombre es requerido'),
  lastname: yup.string().required('El apellido es requerido'),
  email: yup
    .string()
    .email('El formato del mail no es válido')
    .required('El mail es requerido'),
  rol: yup.string().required('El rol es requerido'),
  password: yup
    .string()
    .required('La contraseña es requerida')
    .min(6, 'Min. 6 caracteres'),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden'),
});

const Register = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { user } = useAuthState();

  useEffect(() => {
    // if (user) navigate('/');
  }, []);

  const handleRegister = async (data) => {
    registerUser(data, data.password).then(() => navigate('/'));
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...initialValues,
      rol: 'user',
      password: '',
      repeatPassword: '',
    },
    resolver: yupResolver(schema),
  });

  return (
    <Grid
      component="form"
      onSubmit={handleSubmit(handleRegister)}
      container
      direction="column"
      spacing={2}
      paddingX={'10%'}
    >
      <Grid item>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Nombre"
              fullWidth
              required
              error={Boolean(errors.name)}
              helperText={errors.name?.message.toString()}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          name="lastname"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Apellido"
              fullWidth
              required
              error={Boolean(errors.lastname)}
              helperText={errors.lastname?.message.toString()}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          name="dni"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Documento"
              fullWidth
              error={Boolean(errors.dni)}
              helperText={errors.dni?.message.toString()}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Correo"
              fullWidth
              required
              error={Boolean(errors.email)}
              helperText={errors.email?.message.toString()}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Dirección"
              fullWidth
              error={Boolean(errors.address)}
              helperText={errors.address?.message.toString()}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Contraseña"
              fullWidth
              required
              type={showPassword ? 'text' : 'password'}
              error={Boolean(errors.password)}
              helperText={errors.password?.message.toString()}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="start"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          name="repeatPassword"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Repetir contraseña"
              fullWidth
              required
              type={showPassword ? 'text' : 'password'}
              error={Boolean(errors.repeatPassword)}
              helperText={errors.repeatPassword?.message.toString()}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="start"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item display="flex" justifyContent="flex-end">
        <Button type="submit" variant="contained">
          Registrar
        </Button>
      </Grid>
      Ya tienes cuenta? <Link to="/login">Logueate!</Link>
    </Grid>
  );
};

export default Register;
