import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, styled, Typography, Button } from '@mui/material';
import Contact from '../../components/Contact';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Text = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: props.weight || 700,
  textAlign: props.align || 'center',
  color: props.color || 'black',
  textIndent: '5px',
}));

const Btn = styled(Button)(({ theme }) => ({
  marginTop: '25px',
  backgroundColor: '#185D22',
  borderRadius: '9px',
  color: 'white',
  fontSize: '12px',
  '&:hover': {
    backgroundColor: '#185D22',
    boxShadow: 'none',
  },
  [theme.breakpoints.down('md')]: {
    width: 100,
    height: 60,
  },
}));

const Faqs = () => {
  const navigate = useNavigate();

  const [viewport_width, setViewport_width] = useState([]);

  window.addEventListener('resize', function () {
    setViewport_width(window.innerWidth);
  });

  useEffect(() => {}, []);
  const arcordionsJson = [
    {
      id: 1,
      title: '¿Queres ser distribuidor Texalia? ',
      text: 'Si querés integrar nuestra red de distribuidores a nivel nacional  accediendo a un producto de excelente calidad y versatilidad, con garantía y entrega en todo el país, completá el formulario y nos contactaremos a la brevedad. Click Aquí. ',
    },
    {
      id: 2,
      title: '¿Cual es el tiempo de fabricación de nuestros productos?  ',
      text: 'Confeccionamos las cortinas en un plazo de 15 días. Disponemos también de artículos en stock. Consultá stock y disponibilidad.',
    },
    {
      id: 3,
      title: '¿Pueden variar los tonos de las telas? ',
      text: 'Sí, la tonalidad y tramas de nuestras telas pueden variar dependiendo de cada partida, por eso te recomendamos realizar un único pedido por la totalidad de las cortinas para un mismo ambiente. Tené en cuenta que no podemos garantizarte que se entregue la misma partida de tela para dos pedidos distintos.',
    },
    {
      id: 4,
      title: '¿Pueden variar los mecanismos?',
      text: ' Si, ya que al tratarse de piezas importadas los modelos pueden variar en las diferentes compras. Esto no afecta en lo absoluto en el buen funcionamiento de las cortinas.',
    },
    {
      id: 5,
      title:
        '¿Puedo colocar mis cortinas sin la necesidad de contactar un especialista?',
      text: 'Sí, claro, en el pack de tus cortinas, también  recibirás todas las fijaciones necesarias para una colocación práctica y sin inconvenientes. Para aprender a colocarlas, podés ver nuestro video haciendo click aquí.',
    },
    {
      id: 6,
      title: '¿Cómo puedo tomar correctamente las medidas para mis cortinas? ',
      text: 'Es muy sencillo, podés ver nuestro video haciendo click aquí o bien leerlas descargando este instructivo (descargar)',
    },
  ];

  const acordionComponent = (id, text, title) => {
    return (
      <Grid
        item
        display={'flex'}
        justifyContent={'center'}
        marginBottom={'30px'}
        key={id}
        id={'ACORDION' + id}
        xs={6}
        lg={6}
      >
        <Accordion>
          <AccordionSummary
            style={{ backgroundColor: '#185D22', color: 'white' }}
            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
            aria-controls={'panel1a-content' + id}
            id={'panel1a-header' + id}
          >
            <Text color={'white'} weight={600}>
              {title}
            </Text>
          </AccordionSummary>
          <AccordionDetails>
            <Text weight={400}>{text}</Text>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item marginBottom={'20px'} display={'flex'} marginLeft={'20%'}>
        <Text mt={5} fontSize={'28px'} weight={600}>
          Preguntas
        </Text>
        <Text mt={5} fontSize={'28px'} weight={400}>
          frecuentes
        </Text>
      </Grid>

      <Grid
        item
        container
        direction="column"
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-start'}
      >
        <Grid
          item
          container
          xs={12}
          lg={12}
          display={'flex'}
          flexDirection={'column'}
          flexWrap={'nowrap'}
          alignItems={'center'}
        >
          {arcordionsJson.map((element) => {
            return acordionComponent(element.id, element.text, element.title);
          })}
        </Grid>
      </Grid>
      <Grid
        item
        display={'flex'}
        justifyContent={'center'}
        width={'100%'}
        marginLeft={'15%'}
        marginRight={'15%'}
      >
        <Contact />
      </Grid>
    </Grid>
  );
};

export default Faqs;
