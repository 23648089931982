import { storage } from 'firebaseApp';
import {
  ref,
  deleteObject,
  uploadBytes,
  getDownloadURL,
  listAll,
  getStorage,
} from 'firebase/storage';

export async function getCarouselImages() {
  const listRef = ref(storage, 'imagesCarousel/');
  const pathsArray = [];
  let finalResponse = [];
  await listAll(listRef)
    .then(async (res) => {
      for (const file of res.items) {
        pathsArray.push(file._location.path);
      }
      finalResponse = await getUrls(pathsArray);
    })
    .catch((error) => {
      return { status: error };
    });

  return finalResponse;
}

export async function uploadImage(file, name) {
  const storageRef = ref(storage, 'imagesCarousel/' + name);
  return await uploadBytes(storageRef, file);
}

export async function pageTokenExample() {
  const listRef = ref(storage, 'imagesCarousel/');
  const pathsArray = [];
  let finalResponse = [];
  await listAll(listRef)
    .then(async (res) => {
      for (const file of res.items) {
        pathsArray.push(file.fullPath);
      }
      finalResponse = await getUrls(pathsArray);
    })
    .catch((error) => {
      return { status: error };
    });

  return finalResponse;
}

async function getUrls(arrayPaths) {
  let arrayResult = [];
  for (const file of arrayPaths) {
    let storageRef = ref(storage, file);
    let url = await getDownloadURL(storageRef);

    arrayResult.push({
      image: file,
      url: url,
    });
  }

  return arrayResult;
}

export async function deleteCarouselImage(fileName) {
  // Create a reference to the file to delete
  const desertRef = ref(storage, fileName);
  // Delete the file
  let newListImages = [];
  await deleteObject(desertRef)
    .then(async () => {
      // File deleted successfully
      try {
        newListImages = await getCarouselImages();
      } catch (error) {
        return { status: error };
        // alert('Fallo al subir el archivo');
      }
      //
    })
    .catch((error) => {
      return { status: error };

      // Uh-oh, an error occurred!
    });

  return newListImages;
}

export async function uploadImageProduct(file, name) {
  let imageUrl;
  try {
    const storageRef = ref(storage, 'imagesProducts/' + name);
    let result = await uploadBytes(storageRef, file);
    if (result) {
      imageUrl = await returnUrlImage('imagesProducts/' + name);
    }
    return { status: 'Imagen creada!', imageUrl };
  } catch (error) {
    return { status: error };
  }
}

export async function returnUrlImage(imageLocation) {
  const storage = getStorage();
  let urlImage = await getDownloadURL(ref(storage, imageLocation))
    .then((url) => {
      return url;
    })
    .catch((error) => {
      return { status: error };
      // Handle any errors
    });

  return urlImage;
}
