import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, styled, Typography, Button } from '@mui/material';

import img from './assets/img.jpg';

// @ts-ignore
const Text = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: 600,
  color: props.color ? props.color : 'black',
  textTransform: 'none',
  textIndent: '5px',
}));

// @ts-ignore
const TextSub = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: 400,
  color: props.color ? props.color : 'black',
  textTransform: 'none',
  textIndent: '5px',
}));

const TextBtn = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Rubik',
  // @ts-ignore
  fontWeight: props.Weight || 600,
  textAlign: 'center',
  color: 'white',
  textTransform: 'none',
  textIndent: '5px',
  fontSize: '14px',
}));

const Btn = styled(Button)(({ theme }) => ({
  marginTop: '25px',
  backgroundColor: '#185D22',
  borderRadius: '9px',
  color: 'white',
  fontSize: '12px',
  width: '10rem',
  '&:hover': {
    backgroundColor: '#185D22',
    boxShadow: 'none',
  },
  [theme.breakpoints.down('md')]: {
    width: 100,
    height: 60,
  },
}));

const Size = () => {
  const navigate = useNavigate();
  const [viewport_width, setViewport_width] = useState([]);

  window.addEventListener('resize', function () {
    // @ts-ignore
    setViewport_width(window.innerWidth);
  });

  return (
    <Grid container direction="column">
      <Grid item marginBottom={'20px'} display={'flex'} marginLeft={'20%'}>
        <TextSub mt={5} fontSize={'28px'}>
          Como
        </TextSub>
        <Text mt={5} fontSize={'28px'}>
          medir
        </Text>
      </Grid>
      <Grid
        item
        marginBottom={'20px'}
        display={'flex'}
        marginLeft={'20%'}
        flexDirection={'column'}
      >
        <TextSub>
          Nuestras cortinas estándar están diseñadas para ser prácticas de
          principio a fin.
        </TextSub>
        <TextSub>
          Acá te mostramos cómo instalarlas fácilmente con este instructivo
          detallado.
        </TextSub>
      </Grid>

      <Grid
        item
        container
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-end'}
        marginBottom={'20px'}
      >
        <Btn
          onClick={() => navigate('/comoinstalar')}
          style={{ backgroundColor: '#2FB943', marginRight: '30%' }}
        >
          <TextBtn
            // @ts-ignore
            Weight={300}
          >
            {'Cómo'}
          </TextBtn>
          <TextBtn>{'INSTALAR'}</TextBtn>
        </Btn>
      </Grid>
      <Grid
        item
        display={'flex'}
        justifyContent={'center'}
        marginBottom={'100px'}
      >
        <video controls>
          <source
            src={
              'https://firebasestorage.googleapis.com/v0/b/texalia-bd037.appspot.com/o/Videos%2FComo%20tomar%20medidas%20para%20encargar%20una%20cortina%20Roller_.mp4?alt=media&token=9db75bf4-999a-4e5d-90a2-50d0b49d2845'
            }
            type="video/mp4"
          />
        </video>
      </Grid>
      <Grid
        item
        style={{
          backgroundImage: `url(${img})`,
          // @ts-ignore
          width: viewport_width,
          height: '400px',
        }}
        display={'flex'}
        flexDirection={'column'}
        //alignItems={'flex-end'}
        justifyContent={'space-around'}
        //marginTop={'20px'}
      >
        <Grid
          item
          id={'text'}
          display={'flex'}
          alignItems={'flex-end'}
          flexDirection={'column'}
        >
          <Text marginRight={'10%'} color={'white'} fontSize={'40px'}>
            Contamos con area
          </Text>
          <Text marginRight={'10%'} color={'white'} fontSize={'40px'}>
            de especialistas{' '}
          </Text>
        </Grid>

        <Grid item>
          <Btn style={{ marginLeft: '10%' }}>
            <TextBtn
              // @ts-ignore
              Weight={700}
            >
              {'PRESUPUESTAR'}
            </TextBtn>
          </Btn>
        </Grid>
        <Grid
          item
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-end'}
        >
          <TextSub marginRight={'10%'} color={'white'} fontSize={'18px'}>
            Preparados para brindarte una atención de primer
          </TextSub>
          <TextSub marginRight={'10%'} color={'white'} fontSize={'18px'}>
            nivel, respondiendo a todas tus consultas
          </TextSub>
          <TextSub marginRight={'10%'} color={'white'} fontSize={'18px'}>
            y asesorarte de la mejor manera
          </TextSub>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Size;
