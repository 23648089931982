import React, { useEffect, useState } from 'react';
import { Grid, Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { DeleteForever, Edit } from '@mui/icons-material';
import { Table, Search, ColorForm, BasicModal, DialogModal } from 'components';
import { columnsArray, initialValues } from './constants';
import { ColorService } from 'services';

const Colors = () => {
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [color, setColor] = useState(initialValues);

  const getAllColors = async () => {
    const colors = await ColorService.getAll();
    setData(colors);
  };

  useEffect(() => {
    getAllColors();
  }, []);

  const handleSearch = () => {
    if (search) {
      setIsLoading(true);
      ColorService.search(search)
        .then((res) => setData(res))
        .finally(() => setIsLoading(false));
    } else {
      getAllColors();
    }
  };

  const handleSubmit = (data, resetForm) => {
    if (data.id) {
      ColorService.update(data).then(() => {
        handleCloseModal();
        resetForm();
        handleSearch();
      });
    } else {
      ColorService.create(data).then(() => {
        handleCloseModal();
        resetForm();
        handleSearch();
      });
    }
  };

  const handleDelete = () => {
    ColorService.remove(color?.id).finally(() => {
      setColor(initialValues);
      setShowAlertModal(false);
      getAllColors();
    });
  };

  const handleCloseModal = () => {
    setShowFormModal(false);
    setColor(initialValues);
  };

  const handleCloseAlertModal = () => {
    setColor(initialValues);
    setShowAlertModal(false);
  };

  const columns = [
    ...columnsArray,
    {
      field: 'actions',
      headerName: 'Acciones',
      headerClassName: 'header',
      sortable: false,
      minWidth: 80,
      align: 'center',
      renderCell: ({ row }) => (
        <>
          <GridActionsCellItem
            label="editar"
            onClick={() => {
              setColor(row);
              setShowFormModal(true);
            }}
            icon={
              <Tooltip title="Editar color">
                <Edit color="primary" />
              </Tooltip>
            }
          />
          <GridActionsCellItem
            label="eliminar"
            onClick={() => {
              setColor(row);
              setShowAlertModal(true);
            }}
            icon={
              <Tooltip title="Eliminar color">
                <DeleteForever color="primary" />
              </Tooltip>
            }
          />
        </>
      ),
    },
  ];

  return (
    <Grid container direction="column">
      <Grid item>
        <Search
          title="Buscar color"
          placeholder="Buscar por nombre del color"
          search={search}
          setSearch={setSearch}
          onSubmit={handleSearch}
        />
        <Table
          title="Resultados de la búsqueda"
          values={data}
          columns={columns}
          isLoading={isLoading}
          buttonTitle="Crear nuevo color"
          buttonAction={() => setShowFormModal(true)}
        />
      </Grid>
      <BasicModal open={showFormModal} onClose={handleCloseModal}>
        <ColorForm onSubmit={handleSubmit} defaultValues={color} />
      </BasicModal>
      <DialogModal
        open={showAlertModal}
        onClose={handleCloseAlertModal}
        onSubmit={handleDelete}
        title="Eliminar color"
        description="Esta acción es irreversible, ¿desea continuar?"
        colorButton="error"
      />
    </Grid>
  );
};

export default Colors;
