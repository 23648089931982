export const columnsArray = [
  {
    field: 'id',
    headerName: 'ID',
    width: 200,
    headerClassName: 'header',
  },
  {
    field: 'name',
    headerName: 'Color',
    width: 200,
    flex: 2,
    headerClassName: 'header',
  },
];

export const initialValues = {
  id: '',
  name: '',
};
