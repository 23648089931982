import React from 'react';
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { roles } from 'utils/consants';

const schema = yup.object({
  name: yup.string().required('El nombre es requerido'),
  lastname: yup.string().required('El apellido es requerido'),
  email: yup
    .string()
    .email('El formato del mail no es válido')
    .required('El mail es requerido'),
  rol: yup.string().required('El rol es requerido'),
});

const UserForm = ({ onSubmit, defaultValues }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const submitFn = (data) => {
    onSubmit(data, reset);
  };

  return (
    <Grid
      component="form"
      onSubmit={handleSubmit(submitFn)}
      container
      direction="column"
      spacing={2}
      paddingX={'10%'}
    >
      <Grid item>
        <Typography variant="h5" textAlign="center">
          {defaultValues.id ? 'Editar usuario' : 'Crear usuario'}
        </Typography>
      </Grid>
      <Grid item>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Nombre"
              fullWidth
              error={Boolean(errors.name)}
              helperText={errors.name?.message.toString()}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          name="lastname"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Apellido"
              fullWidth
              error={Boolean(errors.lastname)}
              helperText={errors.lastname?.message.toString()}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          name="dni"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Documento"
              fullWidth
              error={Boolean(errors.dni)}
              helperText={errors.dni?.message.toString()}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Correo"
              fullWidth
              error={Boolean(errors.email)}
              helperText={errors.email?.message.toString()}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Dirección"
              fullWidth
              error={Boolean(errors.address)}
              helperText={errors.address?.message.toString()}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          name="rol"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Rol"
              fullWidth
              select
              defaultValue={'user'}
              error={Boolean(errors.rol)}
              helperText={errors.rol?.message.toString()}
            >
              {roles.map((rol) => (
                <MenuItem key={rol} value={rol}>
                  {rol}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item display="flex" justifyContent="flex-end">
        <Button type="submit" variant="contained">
          Confirmar
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserForm;
