import React from 'react';
import { Grid, styled, Typography} from '@mui/material';
import packageJson from '../../../package.json';

import logoTexalia from '../DecoHome/assets/logoTexalia.jpg';

const Proximamente = () => {
  const TextTitle = styled(Typography)((props) => ({
    position: 'relative',
    fontFamily: 'Inter',
    fontWeight: 600,
    textAlign: 'center',
    color: 'black',
    textTransform: 'none',
  }));
  // window.addEventListener('resize', function () {
  //   console.log('window.innerWidth:', window.innerWidth);
  //   console.log('window :', window.innerHeight);
  // });
  return (
    <Grid container direction="column" style={{ height: window.innerHeight }}>
      <Grid
        item
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        style={{ height: window.innerHeight }}
      >
        <TextTitle
          style={{ fontSize: 25 }}
          marginLeft={'20%'}
          marginRight={'20%'}
        >
          Proximamente
        </TextTitle>
        <img width={'250'} height={'75'} src={logoTexalia} />
        <Typography variant="body2" color="textSecondary">
          {packageJson.version}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Proximamente;
