import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, TextField, Typography } from '@mui/material';

const schema = yup.object({
  name: yup.string().required('El nombre de la categoría es requerido'),
});

const CategoryStandarProductsForm = ({ onSubmit, defaultValues }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const submitFn = (data) => onSubmit(data, reset);

  return (
    <Grid
      component="form"
      onSubmit={handleSubmit(submitFn)}
      container
      direction="column"
      spacing={2}
      paddingX={'10%'}
    >
      <Grid item>
        <Typography variant="h5" textAlign="center">
          {defaultValues.id
            ? 'Editar categoría estandar'
            : 'Crear categoría estandar'}
        </Typography>
      </Grid>
      <Grid item>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Categoría estandar"
              fullWidth
              error={Boolean(errors.name)}
              helperText={errors.name?.message.toString()}
            />
          )}
        />
      </Grid>

      <Grid item display="flex" justifyContent="flex-end">
        <Button type="submit" variant="contained">
          Confirmar
        </Button>
      </Grid>
    </Grid>
  );
};

export default CategoryStandarProductsForm;
